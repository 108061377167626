import { Button, Drawer, Divider, notification, Form, Switch, Input, Select, Checkbox } from 'antd';
import Axios from 'axios';
import React, { useState } from 'react';
import { scrollToTop } from '../helpers';

const FunctionaryAndOwnershipDrawer = ({ onClose, open, type, data, SERVER_URL, user }) => {
  const [form] = Form.useForm();
  const [isYes, setIsYes] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOwnershipMembers, setSelectedOwnershipMembers] = useState(
    data?.ownershipStructure?.map((owner) => owner.ownerName) || [],
  );

  let cardTitle = 'Statement of Functionary';
  let title = 'Here you can create Statement of Individual for Determining Functionary Status';
  if (type === 'ownership') {
    cardTitle = 'Statement of Ownership Structure';
    title = 'Here you can create Statement of Ownership Structure';
  }

  if (type === 'questionnarie') {
    cardTitle = 'Client Questionnaire';
    title = `Here you can create a PDF printable version of the\n Client Questionnaire (SPNFT -1).`;
  }

  const disabled =
    type !== 'questionnarie' && (type === 'ownership' ? selectedOwnershipMembers.length < 1 : !selectedOwner);

  const handleSwitchChange = (checked) => {
    setIsYes(checked);
  };

  const handleOwnerChange = (value) => {
    setSelectedOwner(value);
  };

  const handleOwnershipMembersChange = (checkedValues) => {
    setSelectedOwnershipMembers(checkedValues);
  };

  const closeDrawer = (isClose) => {
    if (!isClose) return;
    onClose();
  };

  const resetFormData = () => {
    form.resetFields();
    setIsYes(false);
    scrollToTop('.company-owner-details .ant-drawer-body');
  };

  const onFinish = async (values) => {
    const { _id, name } = data;
    Object.assign(values, {
      company: _id,
      companyName: name,
      type: type,
      question1: isYes,
      ownershipMembers: selectedOwnershipMembers,
    });

    if (type === 'functionary') {
      Object.assign(values, { owner: selectedOwner });
    }

    setIsLoading(true);
    try {
      const response = await Axios.post(`${SERVER_URL}/functionary-ownership-docs`, values, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));

      let fileName = `functionary_docs_${data.name}.zip`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      const isClose = values.type !== 'functionary';
      closeDrawer(isClose);
      resetFormData();
      setIsLoading(false);
    } catch (err) {
      console.error('Error in creating functionary document:', err);
      let errorMessage = 'An error occurred';
      if (err.response && err.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result);
            const { error, fields } = json;

            errorMessage = fields ? `${error}: ${fields.join(', ')}` : error;

            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          } catch (parseError) {
            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          }
        };
        reader.readAsText(err.response.data);
      } else {
        notification.error({
          message: errorMessage,
          placement: 'bottomRight',
          duration: 0,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={cardTitle}
      width={520}
      closable={false}
      onClose={closeDrawer}
      open={open}
      afterOpenChange={(visible) => {
        if (visible) {
          document.body.querySelector('.functionary-ownership-drawer .ant-drawer-body').scrollTo(0, 0);
        }
      }}
      className='functionary-ownership-drawer'
    >
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>{title}</p>
        <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />

        <Form form={form} layout='vertical' onFinish={onFinish} style={{ maxWidth: '600px', margin: '0 auto' }}>
          {type === 'ownership' && data.ownershipStructure.length > 0 ? (
            <Form.Item label='Select the ownership members included in this statement. Founder included will be considered as the founder of the legal entity.'>
              <Checkbox.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  paddingLeft: '8px',
                }}
                value={selectedOwnershipMembers}
                onChange={handleOwnershipMembersChange}
              >
                {data?.ownershipStructure
                  ?.filter((owner) => owner.type !== 'authorized')
                  ?.map((owner) => (
                    <Checkbox
                      key={owner.name}
                      value={owner.name}
                      style={{
                        alignItems: 'center',
                        marginLeft: 0,
                      }}
                    >
                      {owner.name} - {owner.type}
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>
          ) : type !== 'functionary' && type !== 'questionnarie' ? (
            <p>You have to create owners structure of the company first!</p>
          ) : null}

          {type === 'functionary' && (
            <>
              <Form.Item name='ownerSelect' label='Select an owner' style={{ marginBottom: '20px' }}>
                <Select placeholder='Select an owner' onChange={handleOwnerChange}>
                  {data?.ownershipStructure
                    ?.filter((owner) => owner.type !== 'authorized')
                    ?.map((owner) => (
                      <Select.Option key={owner._id} value={owner.name}>
                        {owner.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='yesOrNo'
                className='custom-label'
                label='Is the owner an individual who currently holds or has held any of the positions or is he/she a close associate of a functionary?'
                valuePropName='checked'
                style={{ marginBottom: '20px' }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Switch checked={isYes} checkedChildren='YES' unCheckedChildren='NO' onChange={handleSwitchChange} />
                </div>
              </Form.Item>

              {isYes && (
                <>
                  <Form.Item
                    name='question2'
                    label='Please provide the type of public function you currently hold or have held in the last four years, along with the period of holding the mentioned public function – the start date and year, and the end date and year if the function has ceased; along with the title of the function, make sure to include the name of the institution where you currently hold or held the function:'
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    name='question3'
                    label='In case you are a functionary of another state or an international organization, please specify the name of the country or the name of the international organization:'
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>
                  <Form.Item
                    name='question4'
                    label='If you are a close associate of a functionary, please provide information about the name and surname of the functionary, their position, as well as the nature and mode of business collaboration with the functionary:'
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    name='question5'
                    label='If you are a member of the immediate family of a functionary, please provide the name and surname of the functionary, their position, and specify your relationship with the functionary:'
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    name='question6'
                    label='If you are a functionary, a close associate of a functionary, or a member of the immediate family of a functionary, please provide, in accordance with Article 38, paragraph 2, point 2 of the Law, information about all assets you possess:'
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </>
              )}
            </>
          )}

          {type === 'questionnarie' && (
            <>
              <p>
                All the data for this document are created in <strong>Questionnarie</strong> section.
              </p>
              <p>Please review the current status of the data and the date of the last update first!</p>
            </>
          )}

          <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
          <Form.Item>
            <Button
              block
              type='primary'
              loading={isLoading}
              htmlType={!isLoading ? 'submit' : 'button'}
              className={!isLoading ? '' : 'opacity-50'}
              style={{ marginTop: '20px' }}
              disabled={disabled}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default FunctionaryAndOwnershipDrawer;
