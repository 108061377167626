import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Calendar,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  TimePicker,
  Tooltip,
  TreeSelect,
} from 'antd';
import moment from 'moment';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { DownloadOutlined, MenuFoldOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import RequestModal from '../RequestModal';
import CustomDrawer from '../CustomDrawer';
import EmployeeSalaryDrawer from './EmployeeSalaryDrawer';
import { holidaysArray } from '../../constants/holidays';
import TerminateEmployeeModal from './modals/TerminateEmployeeModal';
import { useTimesheetContext } from '../../context/TimesheetContext';
import ExtendEmploymentModal from './modals/ExtendEmploymentModal';

const { TreeNode } = TreeSelect;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const formInit = {
  employee: undefined,
  overTime: undefined,
  overTime2: undefined,
  workHours: undefined,
  workType: undefined,
  hotMeal: 0,
  regres: 0,
  bonus: 0,
  otherIncome: 0,
  suspension: 0,
  otherExpenses: 0,
  travelExpenses: 0,
  otherExpensesValue: 0,
  otherIncomeValue: 0,
  suspensionValue: 0,
  bonusValue: 0,
  workFromHome: 0,
  halfWorkTime: false,
  comment: undefined,
  numberOfHolidayDays: 0,
  numberOfHolidayDaysByEndOfContract: 0,
};

const SalaryForm = ({
  salaryFormFixRef,
  onSubmit,
  salaries,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  display,
  windowWidth,
  setWindowWidth,
  showCalendar,
  selectedDate,
  setSelectedDate,
  selectedEmployee,
  employee,
  chosenCompany,
  findedSalary,
  singleMonth,
  setSingleMonth,
  unsetEmployee,
  employeesInSalary,
  isNew,
  setIsNew,
  onXIcon,
  setOnXIcon,
  currentuser,
  resetFormFieldValues,
  fileList,
  setFileList,
  customDay,
  setCustomDay,
  isSelected,
  setIsSelected,
  employeeFormData,
  setEmployeeFormData,
  employeeReq,
  updateDateEmployee,
  updateDateEmployeeSubmit,
  setSubmitTimesheetDisabled,
  submitTimesheetDisabled,
  setSubmitClicked,
  submitClicked,
}) => {
  const [form] = Form.useForm();
  const [deletedDays, setDeletedDays] = useState([]);
  const [halfWorkTime, setHalfWorkTime] = useState(undefined);
  const [clickedHalfTime, setClickedHalfTime] = useState(false);
  const [openedWeekendForm, setOpenWeekendForm] = useState(false);
  const [changedMonth, setChangedMonth] = useState(false);
  const [changedData, setChangedData] = useState(false);
  const [changedYear, setChangedYear] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [files, setFiles] = useState([]);
  const [hrDocumentsList, setHrDocumentsList] = useState([]);
  const [multimarking, setMultimarking] = useState(false);
  const [workHours, setWorkHours] = useState(multimarking && '08:00');
  const [workType, setWorkType] = useState();
  const [overTime, setOverTime] = useState();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentField, setCommentField] = useState();
  const [customHours, setCustomHours] = useState(0);
  const [documents, setDocuments] = useState();
  const [randomKeyCalendar, setRandomKeyCalendar] = useState(undefined);
  const [randomKeyForm, setRandomKeyForm] = useState(undefined);
  const [weekendElements, setWeekendElements] = useState(document.getElementsByClassName('weekend'));
  const [timePickerKey, setTimePickerKey] = useState(0);
  const [timePickerKey2, setTimePickerKey2] = useState(0);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [requestModalData, setRequestModalData] = useState([]);
  const [selectedWorkTypeForModal, setSelectedWorkTypeForModal] = useState('');
  const [selectedDateLocalForModal, setSelectedDateLocalForModal] = useState(undefined);
  const [customDayDrawer, setCustomDayDrawer] = useState(false);
  const [customChildrenDrawer, setCustomChildrenDrawer] = useState(false);
  const [customSingleDayChildrenDrawer, setCustomSingleDayChildrenDrawer] = useState(false);
  const [isEmployeeDrawerOpen, setIsEmployeeDrawerOpen] = useState(false);
  const [isTerminateEmployeeModalOpen, setIsTerminateEmployeeModalOpen] = useState(false);
  const [isExtendEmploymentModalOpen, setIsExtendEmploymentModalOpen] = useState(false);
  const [workType1FromDrawer, setWorkType1FromDrawer] = useState(undefined);
  const [workType2FromDrawer, setWorkType2FromDrawer] = useState(undefined);
  const [custom1HoursFromDrawer, setCustom1HoursFromDrawer] = useState(0);
  const [custom2HoursFromDrawer, setCustom2HoursFromDrawer] = useState(0);
  const [multiMarkingCustomMode, setMultiMarkingCustomMode] = useState(false);
  const [timeRangeCalendarDisabled, setTimeRangeCalendarDisabled] = useState(true);
  const [rangeCalendarVisible, setRangeCalendarVisible] = useState(false);
  const [datesForDisabling, setDatesForDisabling] = useState(undefined);
  const [holidays] = useState(
    datesForDisabling && datesForDisabling.length
      ? holidaysArray.concat(datesForDisabling.map((item) => moment(item, 'DD.MM.YYYY.')))
      : holidaysArray,
  );
  const [numOfDays, setNumOfDays] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [initialEndDate, setInitialEndDate] = useState('');
  const [dateRangeSubmitted, setDateRangeSubmitted] = useState(false);
  const [showUploadList, setShowUploadList] = useState({
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
  });
  const [localStorageData, setLocalStorageData] = useState([]);
  const [workingHours, setWorkingHours] = useState(undefined);
  const [dateOfCreation, setDateOfCreation] = useState(undefined);
  const [editedReasonOfSuspension, setEditedReasonOfSuspension] = useState('');
  const [editedReasonOfSuspensionEng, setEditedReasonOfSuspensionEng] = useState('');
  const [suspensionAmount, setSuspensionAmount] = useState('');
  const [suspensionCurrency, setSuspensionCurrency] = useState('');

  const { terminateEmploymentFormData, extendEmploymentFormData } = useTimesheetContext();

  useEffect(() => {
    const calendarData = localStorage.getItem('calendarData');
    localStorage.setItem('calendarDataBackup', calendarData);

    return () => {
      localStorage.removeItem('calendarDataBackup');
    };
  }, [employee]);

  const formatDate = (date) => moment(date).startOf('day').format('YYYY-MM-DD');

  const handleSelect = (value) => {
    setIsSelected(true);
    const formattedDate = formatDate(value);
    setSelectedDate(formattedDate);
    setCustomDay(false);
    if (multimarking && !multiMarkingCustomMode && !customDay) {
      changeDayData(formattedDate);
    } else if (!multimarking && !multiMarkingCustomMode && !customDay) {
      selectCalendar(formattedDate);
    } else {
      changeDayDataCustom(formattedDate, !!customDay);
    }
  };

  // this sets initial values for form in suspension sub drawer
  useEffect(() => {
    const employeeSuspensionSetup = employee?.data?.items[0]?.suspensionDrawer;
    const salarySuspensionSetup = findedSalary[0]?.suspensionDrawer;

    if (employeeSuspensionSetup) {
      setEditedReasonOfSuspension(employeeSuspensionSetup.editedReasonOfSuspension || '');
      setEditedReasonOfSuspensionEng(employeeSuspensionSetup.editedReasonOfSuspensionEng || '');
      setSuspensionAmount(employeeSuspensionSetup.suspensionAmount?.toString() || '0');
    }

    if (salarySuspensionSetup) {
      salarySuspensionSetup.editedReasonOfSuspension &&
        setEditedReasonOfSuspension(salarySuspensionSetup.editedReasonOfSuspension || '');
      salarySuspensionSetup.editedReasonOfSuspensionEng &&
        setEditedReasonOfSuspensionEng(salarySuspensionSetup.editedReasonOfSuspensionEng || '');
      salarySuspensionSetup.suspensionAmount &&
        setSuspensionAmount(salarySuspensionSetup.suspensionAmount?.toString() || '0');
    }

    setSuspensionCurrency(chosenCompany[0]?.suspensionCurrency || 'RSD');
  }, [chosenCompany[0], findedSalary[0], employee]);

  useEffect(() => {
    if (!!Object.keys(terminateEmploymentFormData).length && employee.data.items.length) {
      const selectedEmployee = employee.data.items[0];
      const employeeStartDateEmployment = moment(selectedEmployee.startDate);
      const selectedEndDate = moment(terminateEmploymentFormData.endDate);

      const calendarData = localStorage.getItem('calendarData');
      const formattedJsonCalendarDataArray = JSON.parse(calendarData);

      const calendarDataBackup = localStorage.getItem('calendarDataBackup');
      const formattedJsonCalendarDataBackupArray = JSON.parse(calendarDataBackup);

      const formatedCalendarData = formattedJsonCalendarDataArray.map((calendarDayObj) => {
        const momentDayFromCalendar = moment(calendarDayObj.selectedDate);

        // Condition to keep days before the start date as "Unemployed"
        if (momentDayFromCalendar.isBefore(employeeStartDateEmployment)) {
          return calendarDayObj;
        }

        // Condition to change "Unemployed" days between start date and end date to "Regular work"
        if (
          momentDayFromCalendar.isBetween(employeeStartDateEmployment, selectedEndDate) &&
          calendarDayObj.workType === 'Unemployed'
        ) {
          const backupDayObj = formattedJsonCalendarDataBackupArray.find((backupObj) =>
            moment(backupObj.selectedDate).isSame(momentDayFromCalendar, 'day'),
          );

          if (backupDayObj && backupDayObj.workType !== 'Unemployed') {
            return {
              ...calendarDayObj,
              workType: backupDayObj.workType,
            };
          } else {
            return {
              ...calendarDayObj,
              workType: 'Regular work',
            };
          }
        }

        // Condition to set days after the end date to "Unemployed"
        if (momentDayFromCalendar.isAfter(selectedEndDate)) {
          return {
            ...calendarDayObj,
            workType: 'Unemployed',
            workType2: '',
            hours2: '00:00',
            overTime: '00:00',
            overTime2: '00:00',
          };
        }

        // Return unchanged for other cases
        return calendarDayObj;
      });
      const localHostCalendarData = JSON.stringify(formatedCalendarData);
      localStorage.setItem('calendarData', localHostCalendarData);
    }

    if (!!Object.keys(extendEmploymentFormData).length && employee.data.items.length) {
      const selectedEmployee = employee.data.items[0];
      const employeeStartDateEmployment = moment(selectedEmployee.startDate);
      const selectedEndDate = moment(extendEmploymentFormData.endDate);
      const employmentType = extendEmploymentFormData.employmentType;

      const calendarData = localStorage.getItem('calendarData');
      const formattedJsonCalendarDataArray = JSON.parse(calendarData);

      const calendarDataBackup = localStorage.getItem('calendarDataBackup');
      const formattedJsonCalendarDataBackupArray = JSON.parse(calendarDataBackup);

      const formatedCalendarData = formattedJsonCalendarDataArray.map((calendarDayObj) => {
        const momentDayFromCalendar = moment(calendarDayObj.selectedDate);

        // Condition to keep days before the start date as "Unemployed"
        if (momentDayFromCalendar.isBefore(employeeStartDateEmployment)) {
          return calendarDayObj;
        }

        // Condition to change "Unemployed" days between start date and end date to "Regular work"
        if (
          employmentType === 'limited' &&
          momentDayFromCalendar.isBetween(employeeStartDateEmployment, selectedEndDate) &&
          calendarDayObj.workType === 'Unemployed'
        ) {
          const backupDayObj = formattedJsonCalendarDataBackupArray.find((backupObj) =>
            moment(backupObj.selectedDate).isSame(momentDayFromCalendar, 'day'),
          );

          if (backupDayObj && backupDayObj.workType !== 'Unemployed') {
            return {
              ...calendarDayObj,
              workType: backupDayObj.workType,
            };
          } else {
            return {
              ...calendarDayObj,
              workType: 'Regular work',
            };
          }
        }

        // Condition to set days after the end date to "Unemployed"
        if (employmentType === 'limited' && momentDayFromCalendar.isAfter(selectedEndDate)) {
          return {
            ...calendarDayObj,
            workType: 'Unemployed',
            workType2: '',
            hours2: '00:00',
            overTime: '00:00',
            overTime2: '00:00',
          };
        }

        if (employmentType === 'unlimited' && calendarDayObj.workType === 'Unemployed') {
          const backupDayObj = formattedJsonCalendarDataBackupArray.find((backupObj) =>
            moment(backupObj.selectedDate).isSame(momentDayFromCalendar, 'day'),
          );

          if (backupDayObj && backupDayObj.workType !== 'Unemployed') {
            return {
              ...calendarDayObj,
              workType: backupDayObj.workType,
            };
          } else {
            return {
              ...calendarDayObj,
              workType: 'Regular work',
            };
          }
        }

        // Return unchanged for other cases
        return calendarDayObj;
      });
      const localHostCalendarData = JSON.stringify(formatedCalendarData);
      localStorage.setItem('calendarData', localHostCalendarData);
    }
  }, [terminateEmploymentFormData, extendEmploymentFormData, employee]);

  const months = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  let halfTimePerEmployee = [];

  const executeScroll = () => {
    if (salaryFormFixRef && salaryFormFixRef.current && salaryFormFixRef.current.scrollIntoView) {
      const element = salaryFormFixRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  };

  // reset localStorage data upon page opening
  useEffect(() => {
    localStorage.removeItem('calendarData');
    return () => {};
  }, []);

  // reset localStorage data for calendar after employee switch
  useEffect(() => {
    if (employee) {
      localStorage.removeItem('calendarData');
    }
    return () => {};
  }, [showCalendar, selectedEmployee, display, employee]);

  useEffect(() => {
    if (employee && employee.data && employee.data.items) {
      executeScroll();
    }
    return () => {};
  }, [showCalendar, selectedEmployee, display, employee]);

  useEffect(() => {
    for (let i = 0; i < weekendElements?.length; i++) {
      setWeekendElements(weekendElements[i]?.parentNode?.parentNode?.parentNode?.classList.add('weekends'));
    }
  }, []);

  // this sets custom working hours (if not 100% or half-time)
  useEffect(() => {
    if (employee && employee?.data?.items?.length && employee?.data?.items[0]) {
      if (employee.data.items[0].workTimeCustomType > 0) {
        const minutes = 8 * ((employee?.data?.items[0]?.workTimeCustomType / 100) * 60);
        const hours = Math.floor(minutes / 60);
        const minutesRemaining = minutes % 60;
        const hoursString = hours < 10 ? '0' + hours : hours;
        const minutesString = minutesRemaining < 10 ? '0' + minutesRemaining : minutesRemaining;
        setCustomHours(hoursString + ':' + minutesString);
        const dataForCalendar = [];
        const obj = {
          employee: employee.data.items[0]._id,
          hours: hoursString + ':' + minutesString,
          hours2: '00:00',
          overTime: '00:00',
          workType: 'Regular work',
          workType2: '',
        };
        dataForCalendar.push(obj);
        localStorage.setItem('calendarData', JSON.stringify(dataForCalendar));
      } else {
        setCustomHours(null);
        if (employee.data.items[0].workTimeType === 'fullTime') {
          setWorkingHours('08:00');
        }
        if (employee.data.items[0].workTimeType === 'halfTime') {
          setWorkingHours('04:00');
        }
      }
    }

    return () => {};
  }, [employee, employee.data.items]);

  useEffect(() => {
    if (unsetEmployee) {
      form.setFieldsValue({
        employee: undefined,
      });
    }

    return () => {};
  }, [unsetEmployee]);

  const resetRangeCalendar = () => {
    setStartDate('');
    setEndDate('');
    setInitialEndDate('');
    setRangeCalendarVisible(false);
    form.setFieldValue('timeRange', false);
    setDateRangeSubmitted(false);
  };

  const handleRefetchEmployeeData = useCallback(async () => {}, []);

  const handleCloseTerminateModal = () => {
    form.setFieldValue('terminateEmployee', false);
    setIsTerminateEmployeeModalOpen(false);
  };

  const handleCloseExtendEmploymentModal = () => {
    form.setFieldValue('extendEmployment', false);
    setIsExtendEmploymentModalOpen(false);
  };

  const getAllHrDocumentsByEmployee = async () => {
    if (employee && employee.data && employee.data.items) {
      try {
        return await Axios.get(`${SERVER_URL}/hr-documents-s3?employee=${employee?.data?.items[0]?._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } catch (error) {
        console.error('error', error.message);
      }
    }
  };

  useEffect(() => {
    getAllHrDocumentsByEmployee().then((r) => setDocuments(r));

    return () => {};
  }, [employee]);

  useEffect(() => {
    if (!selectedMonth) {
      if (
        parseInt(moment().format('DD')) > 15 &&
        moment().isSameOrAfter(moment().startOf('month').set('date', 15), 'days') &&
        moment().isSameOrBefore(moment().add(1, 'months').startOf('month').set('date', 15), 'days')
      ) {
        setSelectedMonth(moment().format('MMMM'));
      } else if (
        parseInt(moment().format('DD')) <= 15 &&
        moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
        moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days')
      ) {
        setSelectedMonth(moment().subtract(1, 'months').format('MMMM'));
      }
    }
    if (!selectedDate) {
      if (
        (parseInt(moment().format('DD')) > 15 &&
          moment().isSameOrAfter(moment().startOf('month').set('date', 15), 'days') &&
          moment().isSameOrBefore(moment().add(1, 'months').startOf('month').set('date', 15), 'days')) ||
        (parseInt(moment().format('DD')) <= 15 &&
          moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
          moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days') &&
          moment().subtract(1, 'months').startOf('month').set('date', 15).format('YYYY') === moment().format('YYYY'))
      ) {
        setSelectedDate(moment().set('years', moment().format('YYYY')));
      } else if (
        parseInt(moment().format('DD')) <= 15 &&
        moment().isSameOrAfter(moment().subtract(1, 'months').startOf('month').set('date', 15), 'days') &&
        moment().isSameOrBefore(moment().startOf('month').set('date', 15), 'days') &&
        moment().subtract(1, 'months').startOf('month').set('date', 15).format('YYYY') < moment().format('YYYY')
      ) {
        setSelectedDate(moment().set('years', moment().subtract(1, 'years').format('YYYY')));
      }
    }

    return () => {};
  }, [selectedMonth, selectedDate]);

  let initialValues = { ...formInit, ...employee.data.items[0] };
  const selectCalendar = (value) => {
    let isFound = false;
    form.setFieldsValue({
      customDayMode: false,
    });
    setCustomDay(false);
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(value), 'days')) {
        isFound = true;
        break;
      }
    }

    if (!isFound && (moment(value).day() === 0 || moment(value).day() === 6)) {
      if (customDay) {
        setCustomDay(false);
        form.setFieldsValue({
          customDayMode: false,
        });
      }
      form.setFieldsValue({
        workHours: undefined,
        workHours2: undefined,
        workType: undefined,
        overTime: undefined,
        overTime2: undefined,
      });
      let allCalendarData = localStorage.getItem('calendarData')
        ? JSON.parse(localStorage.getItem('calendarData'))
        : [];

      let data = {
        employee: selectedEmployee,
        selectedDate: moment(value).startOf('day').format('YYYY-MM-DD'),
        hours: (moment(value).day() === 0 || moment(value).day() === 6) ? '00:00' : customHours ? moment(customHours, 'HH:mm') : workingHours,
        hours2: '00:00',
        workType: '',
        workType2: '',
        overTime: '00:00',
        overTime2: '00:00',
      };
      allCalendarData.push(data);
      localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
    }
    if (isFound) {
      let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
      for (let data of calendarData) {
        if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(value), 'days')) {
          form.setFieldsValue({
            selectedDate: moment(value).startOf('day').format('YYYY-MM-DD'),
            workHours: (moment(value).day() === 0 || moment(value).day() === 6) && data.workType !== '' ? moment('00:00', 'HH:mm') : customHours ? moment(customHours, 'HH:mm') : moment(data.hours, 'HH:mm'),
            workHours2: moment(data.hours2 ? data.hours2 : moment('00:00', 'HH:mm'), 'HH:mm'),
            workType: data.workType,
            workType2: data.workType2,
            overTime: moment(data.overTime, 'HH:mm'),
            overTime2: data.overTime2 ? moment(data.overTime2, 'HH:mm') : undefined,
          });
          break;
        } else {
          form.setFieldsValue({
            workHours: '08:00',
            workType: 'Regular work',
            overTime: moment('00:00', 'HH:mm'),
            overTime2: moment('00:00', 'HH:mm'),
          });
        }
      }
    }
  };


  function disabledDate(current) {
    return (
      moment(current).isAfter(moment(calendarValue).endOf('month')) ||
      moment(current).isBefore(moment(calendarValue).startOf('month'))
    );
  }

  const disabledDateRangePicker = (current, month) => {
    const isWeekend = moment(current).isoWeekday() >= 6;
    const isHoliday = holidays.some((holiday) => moment(holiday).isSame(current, 'day'));

    const selectedMonthMoment = moment().month(month).startOf('month');
    const isBeforeSelectedMonth = current.isBefore(selectedMonthMoment, 'month');

    return isWeekend || isHoliday || isBeforeSelectedMonth;
  };

  let days = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = (values) => {
    setSubmitClicked(true);
    setLocalStorageData(JSON.parse(localStorage.getItem('calendarData')));
    try {
      updateDateEmployeeSubmit();
      setSubmitTimesheetDisabled(true);
      values.workFromHome = form.getFieldValue('workFromHome') ? form.getFieldValue('workFromHome') : 0;
      values.comment = form.getFieldValue('comment');
      values.employee = selectedEmployee;
      values.hotMeal = form.getFieldValue('hotMeal') ? form.getFieldValue('hotMeal') : 0;
      values.regres = form.getFieldValue('regres') ? form.getFieldValue('regres') : 0;
      values.travelExpenses = form.getFieldValue('travelExpenses') ? form.getFieldValue('travelExpenses') : 0;
      values.bonus = form.getFieldValue('bonus') ? form.getFieldValue('bonus') : 0;
      values.totalWork = 0;
      values.workHours = 0;
      values.overTime = 0;
      values.overTime2 = 0;
      values.month = selectedMonth === singleMonth || !singleMonth ? selectedMonth : singleMonth;
      values.year = localStorage.getItem('selectedYear')
        ? JSON.parse(localStorage.getItem('selectedYear'))
        : moment(selectedDate).format('YYYY');
      values.stoppedRegularWork = 0;
      values.suspensionDrawer = {
        editedReasonOfSuspension,
        editedReasonOfSuspensionEng,
        suspensionAmount,
        dateOfCreation: dateOfCreation ? dateOfCreation : moment().format('DD-MM-YYYY'),
        currencySuspension: chosenCompany[0].suspensionCurrency || 'RSD',
      };
      values.suspension = suspensionAmount;
      let attachments = [];
      let workByType = [];
      let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
      let allData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
      let index;
      let mealPerDay = 0;
      let holidayPerDay = 0;
      let travelPerDay = 0;
      let sickLeaveUnemployedHours = 0;
      let regularWorkCalc = 0;
      let otherHoursCalc = 0;
      let totalWorkCalc = 0;
      let workHoursCalc = 0;
      for (let data of calendarData) {
        if (data.employee === selectedEmployee && moment(data.selectedDate).format('MMMM') === values.month) {
          if (moment(data.selectedDate).day() !== 0 && moment(data.selectedDate).day() !== 6) {
            totalWorkCalc += 8;
            if (data.workType === 'Regular work') {
              regularWorkCalc += 8;
            } else if (data.workType !== 'Regular work' && data.workType !== 'Unemployed') {
              otherHoursCalc += 8;
            }
          }
          let workTimeParts = data.hours.split(':');
          let workTimeParts2 = data.hours2 && data.hours2.split(':');
          if (workTimeParts2 && data.workType !== '' && data.workType2 !== '') {
            values.workHours +=
              Number(workTimeParts[0]) * 60 +
              Number(workTimeParts[1]) +
              (Number(workTimeParts2[0]) * 60 + Number(workTimeParts2[1]));
            values.totalWork +=
              Number(workTimeParts[0]) * 60 +
              Number(workTimeParts[1]) +
              (Number(workTimeParts2[0]) * 60 + Number(workTimeParts2[1]));
          } else if (data.workType !== '') {
            values.workHours += Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
            values.totalWork += Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
          }
          let overTimeParts = data.overTime.split(':');
          let overTimeParts2 = data.overTime2 ? data.overTime2.split(':') : undefined;
          values.overTime += Number(overTimeParts[0]) * 60 + Number(overTimeParts[1]);
          if (data.overTime2 && overTimeParts2) {
            values.overTime2 += Number(overTimeParts2[0]) * 60 + Number(overTimeParts2[1]);
          }

          let isExist = false;
          if (workByType.length === 0) {
            workByType.push({
              workType: data.workType,
              hours: Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]),
            });
          } else {
            for (let work of workByType) {
              if (data.workType === work.workType) {
                isExist = true;
                break;
              }
            }
            for (let singleWork of workByType) {
              if (isExist === true) {
                if (singleWork.workType === data.workType && data.workType === 'Regular work') {
                  singleWork.hours += Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
                  if (Number(workTimeParts[0]) < 8 && !halfWorkTime) {
                    values.stoppedRegularWork += (8 - Number(workTimeParts[0])) * 60;
                  }
                  break;
                } else if (data.workType !== 'Regular work' && singleWork.workType === data.workType) {
                  singleWork.hours += Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
                }
              } else if (data.workType !== '') {
                workByType.push({
                  workType: data.workType,
                  hours: Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]),
                });
                break;
              }
            }
          }
          isExist = false;
          for (let work of workByType) {
            if (data.workType2 === work.workType) {
              isExist = true;
              break;
            }
          }
          for (let singleWork of workByType) {
            if (isExist === true) {
              if (
                data.workType2 !== '' &&
                singleWork.workType === data.workType2 &&
                data.workType2 === 'Regular work' &&
                workTimeParts2
              ) {
                singleWork.hours += Number(workTimeParts2[0]) * 60 + Number(workTimeParts2[1]);
                if (Number(workTimeParts[0]) < 8 && !halfWorkTime) {
                  //values.stoppedRegularWork += (8 - Number(workTimeParts2[0])) * 60;
                }
                break;
              } else if (
                data.workType2 !== '' &&
                data.workType2 !== 'Regular work' &&
                singleWork.workType === data.workType2 &&
                workTimeParts2
              ) {
                singleWork.hours += Number(workTimeParts2[0]) * 60 + Number(workTimeParts2[1]);
              }
            } else if (data.workType2 && data.workType2 !== '' && workTimeParts2) {
              workByType.push({
                workType: data.workType2,
                hours: Number(workTimeParts2[0]) * 60 + Number(workTimeParts2[1]),
              });
              break;
            }
          }
          values.hoursByType = workByType;
          if (data.workType === 'Regular work') {
            mealPerDay += parseFloat(values.hotMeal);
          }
          if (data.workType === 'Holiday leave') {
            holidayPerDay += parseFloat(values.regres);
          }
          if (data.workType === 'Regular work') {
            travelPerDay += parseFloat(values.travelExpenses);
          }
          if (data.workType.includes('Sick leave') || data.workType.includes('Unemployed')) {
            sickLeaveUnemployedHours += 8;
          }
        }
        if (data.employee === selectedEmployee) {
          days.push({
            selectedDate: data.selectedDate,
            hours: data.hours,
            hours2: data.hours2,
            workType: data.workType,
            workType2: data.workType2,
            overTime: data.overTime,
            overTime2: data.overTime2,
          });

          if (index === undefined) {
            for (let i = 0; i < calendarData.length; i++) {
              if (
                moment(data.selectedDate).isSame(moment(calendarData[i].selectedDate), 'days') &&
                selectedEmployee === calendarData[i].employee
              ) {
                index = i;
              }
            }
          }
          allData.splice(index, 1);
        }
      }
      values.hoursByDay = days;
      localStorage.setItem('calendarData', JSON.stringify(allData));
      let regularWorkHours = 0;
      let otherHours = 0;
      let existUnemployed = false;
      for (let item of workByType) {
        if (item.workType === 'Regular work') {
          regularWorkHours = parseFloat(item.hours);
        }
        if (item.workType !== 'Regular work' && item.workType !== 'Unemployed') {
          otherHours += item.hours;
        }
        if (item.workType === 'Unemployed') {
          existUnemployed = true;
        }
      }
      values.totalWork += values.overTime + values.overTime2;

      if (employee.data.items[0].company.calculationMethodMeal === 'automatic') {
        values.workHours = regularWorkHours;
        workHoursCalc = regularWorkCalc;
        if (findedSalary.length === 0) {
          values.hotMealInitial = values.hotMeal;
          values.hotMeal = parseFloat((values.hotMeal * regularWorkCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.hotMealInitial = values.hotMeal;
          if (findedSalary[0].hotMealInitial === 0) {
            values.hotMealInitial = values.hotMeal;
            values.hotMeal = parseFloat((values.hotMeal * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].hotMealInitial && findedSalary[0].hotMealInitial === values.hotMealInitial) {
            values.hotMeal = parseFloat((findedSalary[0].hotMealInitial * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].hotMealInitial && findedSalary[0].hotMealInitial !== values.hotMealInitial) {
            values.hotMeal = parseFloat((values.hotMealInitial * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (!findedSalary[0].hotMealInitial) {
            values.hotMeal = parseFloat((values.hotMealInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          }
        }
      } else if (employee.data.items[0].company.calculationMethodMeal === 'manual' && existUnemployed) {
        values.workHours = regularWorkHours + otherHours;
        workHoursCalc = regularWorkCalc + otherHoursCalc;
        if (findedSalary.length === 0) {
          values.hotMealInitial = values.hotMeal;
          values.hotMeal = parseFloat((values.hotMeal * workHoursCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.hotMealInitial = values.hotMeal;
          if (findedSalary[0].hotMealInitial === 0) {
            values.hotMealInitial = values.hotMeal;
            values.hotMeal = parseFloat((values.hotMeal * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].hotMealInitial && findedSalary[0].hotMealInitial === values.hotMealInitial) {
            values.hotMeal = parseFloat((findedSalary[0].hotMealInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].hotMealInitial && findedSalary[0].hotMealInitial !== values.hotMealInitial) {
            values.hotMeal = parseFloat((values.hotMealInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (!findedSalary[0].hotMealInitial) {
            values.hotMeal = parseFloat((values.hotMealInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          }
        }
      } else if (employee.data.items[0].company.calculationMethodMeal === 'perDay') {
        values.hotMealInitial = values.hotMeal;
        values.hotMeal = mealPerDay;
      } else if (employee.data.items[0].company.calculationMethodMeal === 'monthlyFee') {
        values.hotMealInitial = values.hotMeal;
        values.hotMeal = (values.hotMeal - (values.hotMeal * sickLeaveUnemployedHours) / totalWorkCalc).toFixed(2);
      }
      values.workHours = regularWorkHours;
      workHoursCalc = regularWorkCalc;
      if (employee.data.items[0].company.calculationMethodHoliday === 'automatic') {
        if (findedSalary.length === 0) {
          values.regresInitial = values.regres;
          values.regres = parseFloat((values.regres * regularWorkCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.regresInitial = values.regres;
          if (findedSalary[0].regresInitial === 0) {
            values.regresInitial = values.regres;
            values.regres = parseFloat((values.regres * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].regresInitial && findedSalary[0].regresInitial === values.regresInitial) {
            values.regres = parseFloat((findedSalary[0].regresInitial * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].regresInitial && findedSalary[0].regresInitial !== values.regresInitial) {
            values.regres = parseFloat((values.regresInitial * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (!findedSalary[0].regresInitial) {
            values.regres = parseFloat((values.regresInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          }
        }
      } else if (employee.data.items[0].company.calculationMethodHoliday === 'manual' && existUnemployed) {
        values.workHours = regularWorkHours + otherHours;
        workHoursCalc = regularWorkCalc + otherHoursCalc;
        if (findedSalary.length === 0) {
          values.regresInitial = values.regres;
          values.regres = parseFloat((values.regres * workHoursCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.regresInitial = values.regres;
          if (findedSalary[0].regresInitial === 0) {
            values.regresInitial = values.regres;
            values.regres = parseFloat((values.regres * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].regresInitial && findedSalary[0].regresInitial === values.regresInitial) {
            values.regres = parseFloat((findedSalary[0].regresInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (findedSalary[0].regresInitial && findedSalary[0].regresInitial !== values.regresInitial) {
            values.regres = parseFloat((values.regresInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (!findedSalary[0].regresInitial) {
            values.regres = parseFloat((values.regresInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
          }
        }
      } else if (employee.data.items[0].company.calculationMethodHoliday === 'perDay') {
        values.regresInitial = values.regres;
        values.regres = holidayPerDay;
      } else if (employee.data.items[0].company.calculationMethodHoliday === 'monthlyFee') {
        values.regresInitial = values.regres;
        values.regres = (values.regres - (values.regres * sickLeaveUnemployedHours) / totalWorkCalc).toFixed(2);
      }
      values.workHours = regularWorkHours;
      workHoursCalc = regularWorkCalc;
      if (employee.data.items[0].company.travelExpenses === 'automatic') {
        if (findedSalary.length === 0) {
          values.travelExpensesInitial = values.travelExpenses;
          values.travelExpenses = parseFloat((values.travelExpenses * regularWorkCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.travelExpensesInitial = values.travelExpenses;
          if (findedSalary[0].travelExpensesInitial === 0) {
            values.travelExpensesInitial = values.travelExpenses;
            values.travelExpenses = parseFloat((values.travelExpenses * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else if (
            findedSalary[0].travelExpensesInitial &&
            findedSalary[0].travelExpensesInitial === values.travelExpensesInitial
          ) {
            values.travelExpenses = parseFloat(
              (findedSalary[0].travelExpensesInitial * regularWorkCalc) / totalWorkCalc,
            ).toFixed(2);
          } else if (
            findedSalary[0].travelExpensesInitial &&
            findedSalary[0].travelExpensesInitial !== values.travelExpensesInitial
          ) {
            values.travelExpenses = parseFloat(
              (values.travelExpensesInitial * regularWorkCalc) / totalWorkCalc,
            ).toFixed(2);
          } else if (!findedSalary[0].travelExpensesInitial) {
            values.travelExpenses = parseFloat((values.travelExpensesInitial * workHoursCalc) / totalWorkCalc).toFixed(
              2,
            );
          }
        }
      } else if (employee.data.items[0].company.travelExpenses === 'manual' && existUnemployed) {
        values.workHours = regularWorkHours + otherHours;
        workHoursCalc = regularWorkCalc + otherHoursCalc;
        if (findedSalary.length === 0) {
          values.travelExpensesInitial = values.travelExpenses;
          values.travelExpenses = parseFloat((values.travelExpenses * workHoursCalc) / totalWorkCalc).toFixed(2);
        } else {
          values.travelExpensesInitial = values.travelExpenses;
          if (findedSalary[0].travelExpensesInitial === 0) {
            values.travelExpensesInitial = values.travelExpenses;
            values.travelExpenses = parseFloat((values.travelExpenses * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else if (
            findedSalary[0].travelExpensesInitial &&
            findedSalary[0].travelExpensesInitial === values.travelExpensesInitial
          ) {
            values.travelExpenses = parseFloat(
              (findedSalary[0].travelExpensesInitial * workHoursCalc) / totalWorkCalc,
            ).toFixed(2);
          } else if (
            findedSalary[0].travelExpensesInitial &&
            findedSalary[0].travelExpensesInitial !== values.travelExpensesInitial
          ) {
            values.travelExpenses = parseFloat((values.travelExpensesInitial * workHoursCalc) / totalWorkCalc).toFixed(
              2,
            );
          } else if (!findedSalary[0].travelExpensesInitial) {
            values.travelExpenses = parseFloat((values.travelExpensesInitial * workHoursCalc) / totalWorkCalc).toFixed(
              2,
            );
          }
        }
      } else if (employee.data.items[0].company.travelExpenses === 'perDay') {
        values.travelExpensesInitial = values.hotMeal;
        values.travelExpenses = travelPerDay;
      }

      if (employee.data.items[0].company.calculationMethodWorkFromHome === 'automatic') {
        if (employee.data.items[0].company.workFromHomeType === '1') {
          if (findedSalary.length === 0) {
            values.workFromHomeInitial = values.workFromHome;
            values.workFromHome = parseFloat((values.workFromHome * regularWorkCalc) / totalWorkCalc).toFixed(2);
          } else {
            values.workFromHomeInitial = values.workFromHome;
            if (findedSalary[0].workFromHomeInitial === 0) {
              values.workFromHomeInitial = values.workFromHome;
              values.workFromHome = parseFloat((values.workFromHome * regularWorkCalc) / totalWorkCalc).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial === values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat(
                (findedSalary[0].workFromHomeInitial * regularWorkCalc) / totalWorkCalc,
              ).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial !== values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * regularWorkCalc) / totalWorkCalc).toFixed(
                2,
              );
            } else if (!findedSalary[0].workFromHomeInitial) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            }
          }
        } else if (employee.data.items[0].company.workFromHomeType === '2') {
          values.workHours = regularWorkHours + otherHours;
          workHoursCalc = regularWorkCalc + otherHoursCalc;
          if (findedSalary.length === 0) {
            values.workFromHomeInitial = values.workFromHome;
            values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else {
            values.workFromHomeInitial = values.workFromHome;
            if (findedSalary[0].workFromHomeInitial === 0) {
              values.workFromHomeInitial = values.workFromHome;
              values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial === values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat(
                (findedSalary[0].workFromHomeInitial * workHoursCalc) / totalWorkCalc,
              ).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial !== values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (!findedSalary[0].workFromHomeInitial) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            }
          }
        }
      } else if (employee.data.items[0].company.calculationMethodWorkFromHome === 'manual') {
        if (employee.data.items[0].company.workFromHomeType === '1') {
          values.workHours = regularWorkHours + otherHours;
          workHoursCalc = regularWorkCalc + otherHoursCalc;
          if (findedSalary.length === 0) {
            values.workFromHomeInitial = values.workFromHome;
            values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else {
            values.workFromHomeInitial = values.workFromHome;
            if (findedSalary[0].workFromHomeInitial === 0) {
              values.workFromHomeInitial = values.workFromHome;
              values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial === values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat(
                (findedSalary[0].workFromHomeInitial * workHoursCalc) / totalWorkCalc,
              ).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial !== values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (!findedSalary[0].workFromHomeInitial) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            }
          }
        } else if (employee.data.items[0].company.calculationMethodWorkFromHome === '2') {
          values.workHours = regularWorkHours + otherHours;
          workHoursCalc = regularWorkCalc + otherHoursCalc;
          if (findedSalary.length === 0) {
            values.workFromHomeInitial = values.workFromHome;
            values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
          } else {
            values.workFromHomeInitial = values.workFromHome;
            if (findedSalary[0].workFromHomeInitial === 0) {
              values.workFromHomeInitial = values.workFromHome;
              values.workFromHome = parseFloat((values.workFromHome * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial === values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat(
                (findedSalary[0].workFromHomeInitial * workHoursCalc) / totalWorkCalc,
              ).toFixed(2);
            } else if (
              findedSalary[0].workFromHomeInitial &&
              findedSalary[0].workFromHomeInitial !== values.workFromHomeInitial
            ) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            } else if (!findedSalary[0].workFromHomeInitial) {
              values.workFromHome = parseFloat((values.workFromHomeInitial * workHoursCalc) / totalWorkCalc).toFixed(2);
            }
          }
        }
      }

      if (employeesInSalary.includes(selectedEmployee)) {
        setIsNew(false);
        for (let salary of salaries) {
          if (
            salary.employee &&
            salary.employee._id === selectedEmployee &&
            (salary.month === selectedMonth || salary.month === singleMonth) &&
            salary.year === moment(selectedDate).format('YYYY')
          ) {
            values.id = salary._id;
            break;
          }
        }
      }

      let inArray = false;
      let indexEmployee;
      for (let i = 0; i < halfTimePerEmployee.length; i++) {
        if (halfTimePerEmployee[0].employee === selectedEmployee) {
          inArray = true;
          indexEmployee = i;
          break;
        }
      }
      if (inArray) {
        halfTimePerEmployee.splice(indexEmployee, 1);
        localStorage.setItem('halfTimeEmployees', JSON.stringify(halfTimePerEmployee));
      }
      if (findedSalary && findedSalary.length !== 0) {
        attachments = findedSalary[0].attachments;
      }
      for (let i = 0; i < selectedTypes.length; i++) {
        attachments.push({
          typeOfFile: selectedTypes[i],
          location: files[i].location,
          originalName: files[i].originalName,
        });
      }
      values.attachments = attachments;
      const employeeFormData1 = employeeFormData.filter((item) => item.employee !== selectedEmployee);
      setEmployeeFormData(employeeFormData1);
      if (currentuser.data.role.includes('userOfficer') && findedSalary && findedSalary.length && !isNew) {
        values.id = findedSalary[0]._id;
      }

      if (chosenCompany[0].isHr) {
        onSubmit(values, isNew, requestModalData);
        setRequestModalData([]);
      } else {
        onSubmit(values, isNew);
      }
      setLocalStorageData([]);
    } catch (error) {
      notification.error({
        message: 'Problem with submit',
        placement: 'bottomRight',
      });
      console.error('submit error', error);
    }
  };

  // --------------------------------get list data start-----------------------------------------------------
  const getListData = (value) => {
    let listData = [];
    let isExist = false;
    let foundDay;
    let calendarData = [];
    let allCalendarData =
      localStorageData && localStorageData.length
        ? localStorageData
        : localStorage.getItem('calendarData')
          ? JSON.parse(localStorage.getItem('calendarData'))
          : [];

    if (
      employee &&
      employee.data &&
      employee.data.items &&
      employee.data.items.length !== 0 &&
      employee.data.items[0]._id === selectedEmployee
    ) {
      if (findedSalary.length === 0 || (findedSalary.length === 1 && !findedSalary[0].hoursByDay)) {
        for (const singleDate of allCalendarData) {
          if (
            moment(singleDate.selectedDate).isSame(moment(value), 'days') &&
            selectedEmployee === singleDate.employee
          ) {
            calendarData.push(singleDate);
          }
        }
      }
      if (findedSalary.length === 1 && findedSalary[0].hoursByDay) {
        for (const singleDate of findedSalary[0].hoursByDay) {
          if (
            moment(singleDate.selectedDate).isSame(moment(value), 'days') &&
            selectedEmployee === findedSalary[0]?.employee?._id &&
            !deletedDays.includes(singleDate.selectedDate)
          ) {
            delete singleDate._id;
            foundDay = {
              employee: findedSalary[0]?.employee?._id,
              ...singleDate,
            };
          }
        }
        if (!foundDay) {
          for (const singleDate of allCalendarData) {
            if (
              moment(singleDate.selectedDate).isSame(moment(value), 'days') &&
              selectedEmployee === singleDate.employee
            ) {
              calendarData.push(singleDate);
            }
          }
        }
      }

      // this checks public holidays 1,2 january
      if (
        ((moment(value).isSame(moment().set('months', 0).set('date', 1), 'days') ||
          moment(value).isSame(moment().set('months', 0).set('date', 2), 'days')) &&
          moment(value).day() !== 0) ||
        (moment().set('months', 0).set('date', 1).day() === 0 &&
          moment(value).isSame(moment().set('months', 0).set('date', 2), 'days')) ||
        (moment().set('months', 0).set('date', 2).day() === 0 &&
          moment(value).isSame(moment().set('months', 0).set('date', 3), 'days')) ||
        // holidays for 2024
        ((moment(value).isSame(moment().add(1, 'year').set('months', 0).set('date', 1), 'days') ||
          moment(value).isSame(moment().add(1, 'year').set('months', 0).set('date', 2), 'days')) &&
          moment(value).day() !== 0) ||
        (moment().add(1, 'year').set('months', 0).add(1, 'year').set('date', 1).day() === 0 &&
          moment(value).isSame(moment().add(1, 'year').set('months', 0).set('date', 2), 'days')) ||
        (moment().add(1, 'year').set('months', 0).set('date', 2).day() === 0 &&
          moment(value).isSame(moment().add(1, 'year').set('months', 0).set('date', 3), 'days'))
      ) {
        for (const day of calendarData) {
          if (moment(day.selectedDate).isSame(moment(value), 'days') && day.employee === selectedEmployee) {
            isExist = true;
            break;
          }
        }
        if (!isExist && findedSalary.length === 0 && moment(value).day() !== 0 && moment(value).day() !== 6) {
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          } else {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Public holiday',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        isExist = true;
      }

      // this checks public holiday on 7th January Christmas
      if (
        (moment(value).isSame(moment().set('months', 0).set('date', 7), 'days') && moment(value).day() !== 0) ||
        // holidays for 2024
        (moment(value).isSame(moment().add(1, 'year').set('months', 0).set('date', 7), 'days') &&
          moment(value).day() !== 0)
      ) {
        for (const day of calendarData) {
          if (moment(day.selectedDate).isSame(moment(value), 'days') && day.employee === selectedEmployee) {
            isExist = true;
            break;
          }
        }
        if (!isExist && findedSalary.length === 0 && moment(value).day() !== 0 && moment(value).day() !== 6) {
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          } else {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Public holiday',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        isExist = true;
      }

      // this checks for public holidays in February
      if (
        ((moment(value).isSame(moment().set('months', 1).set('date', 15), 'days') ||
          moment(value).isSame(moment().set('months', 1).set('date', 16), 'days')) &&
          moment(value).day() !== 0) ||
        (moment().set('months', 1).set('date', 15).day() === 0 &&
          (moment(value).isSame(moment().set('months', 1).set('date', 16), 'days') ||
            moment(value).isSame(moment().set('months', 1).set('date', 17), 'days'))) ||
        (moment().set('months', 1).set('date', 16).day() === 0 &&
          moment(value).isSame(moment().set('months', 1).set('date', 17), 'days')) ||
        // holidays for 2024
        ((moment(value).isSame(moment().add(1, 'year').set('months', 1).set('date', 15), 'days') ||
          moment(value).isSame(moment().add(1, 'year').set('months', 1).set('date', 16), 'days')) &&
          moment(value).day() !== 0) ||
        (moment().add(1, 'year').set('months', 1).set('date', 15).day() === 0 &&
          (moment(value).isSame(moment().add(1, 'year').set('months', 1).set('date', 16), 'days') ||
            moment(value).isSame(moment().add(1, 'year').set('months', 1).set('date', 17), 'days'))) ||
        (moment().add(1, 'year').set('months', 1).set('date', 16).day() === 0 &&
          moment(value).isSame(moment().add(1, 'year').set('months', 1).set('date', 17), 'days'))
      ) {
        for (const day of calendarData) {
          if (moment(day.selectedDate).isSame(moment(value), 'days') && day.employee === selectedEmployee) {
            isExist = true;
            break;
          }
        }
        if (!isExist && findedSalary.length === 0 && moment(value).day() !== 0 && moment(value).day() !== 6) {
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          } else {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee.data.items[0].workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Public holiday',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        isExist = true;
      }

      // this checks public holidays in May
      if (
        moment(value).isSame(moment().set('months', 4).set('date', 1), 'days') ||
        moment(value).isSame(moment().set('months', 4).set('date', 2), 'days') ||
        moment(value).isSame(moment().set('months', 4).set('date', 3), 'days') ||
        moment(value).isSame(moment().set('months', 4).set('date', 6), 'days')
      ) {
        for (const day of calendarData) {
          if (moment(day.selectedDate).isSame(moment(value), 'days') && day.employee === selectedEmployee) {
            isExist = true;
            break;
          }
        }
        if (!isExist && findedSalary.length === 0 && moment(value).day() !== 0 && moment(value).day() !== 6) {
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          } else {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Public holiday',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        isExist = true;
      }

      // this checks public holidays in November
      if (
        (moment(value).isSame(moment().set('months', 10).set('date', 11), 'days') && moment(value).day() !== 0) ||
        (moment().set('months', 10).set('date', 11).day() === 0 &&
          moment(value).isSame(moment().set('months', 10).set('date', 12), 'days')) ||
        // holidays for 2024
        (moment(value).isSame(moment().add(1, 'year').set('months', 10).set('date', 11), 'days') &&
          moment(value).day() !== 0) ||
        (moment().add(1, 'year').set('months', 10).set('date', 11).day() === 0 &&
          moment(value).isSame(moment().add(1, 'year').set('months', 10).set('date', 12), 'days'))
      ) {
        for (const day of calendarData) {
          if (moment(day.selectedDate).isSame(moment(value), 'days') && day.employee === selectedEmployee) {
            isExist = true;
            break;
          }
        }
        if (!isExist && findedSalary.length === 0 && moment(value).day() !== 0 && moment(value).day() !== 6) {
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          } else {
            allCalendarData.push({
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Public holiday',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            });
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        isExist = true;
      }

      if (foundDay) {
        let dayInStorage;
        let inArray = false;
        for (const singleDate of allCalendarData) {
          if (
            moment(singleDate.selectedDate).isSame(moment(value), 'days') &&
            selectedEmployee === singleDate.employee
          ) {
            dayInStorage = singleDate;
            inArray = true;
            break;
          }
        }
        if (!inArray) {
          allCalendarData.push(foundDay);
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
        }
        let workType = foundDay.workType;
        let hours = foundDay.hours;
        let workType2 = foundDay.workType2;
        let hours2 = foundDay.hours2;
        let overTimeParts = foundDay.overTime.split(':');
        let overTimeParts2 = foundDay.overTime2 ? foundDay.overTime2.split(':') : undefined;
        let overTime = foundDay.overTime;
        let overTime2 = foundDay.overTime2;
        if (dayInStorage) {
          workType = foundDay.workType === dayInStorage.workType ? foundDay.workType : dayInStorage.workType;
          workType2 = foundDay.workType2 === dayInStorage.workType2 ? foundDay.workType2 : dayInStorage.workType2;
          hours = foundDay.hours === dayInStorage.hours ? foundDay.hours : dayInStorage.hours;
          hours2 = foundDay.hours2 === dayInStorage.hours2 ? foundDay.hours2 : dayInStorage.hours2;
          overTimeParts =
            foundDay.overTime === dayInStorage.overTime
              ? foundDay.overTime.split(':')
              : dayInStorage.overTime.split(':');
          if (foundDay.overTimeParts2 || dayInStorage.overTime2) {
            overTimeParts2 =
              foundDay.overTime2 === dayInStorage.overTime2
                ? foundDay.overTime2.split(':')
                : dayInStorage.overTime2.split(':');
          }
          overTime = foundDay.overTime === dayInStorage.overTime ? foundDay.overTime : dayInStorage.overTime;
          overTime2 = foundDay.overTime2 === dayInStorage.overTime2 ? foundDay.overTime2 : dayInStorage.overTime2;
        }
        let hoursParts = hours.split(':');
        let hoursParts2 = hours2 && hours2.split(':');
        let nameOfClass =
          workType !== '' && (Number(hoursParts[0]) !== 0 || Number(hoursParts[1]) !== 0)
            ? workType?.replace('%', '').split(' ').join('')
            : 'empty';
        if (workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
          nameOfClass = 'Pregnancyleave';
        }
        let nameOfClass2 =
          workType2 && workType2 !== '' && hoursParts2 && (Number(hoursParts2[0]) !== 0 || Number(hoursParts2[1]) !== 0)
            ? workType2?.replace('%', '').split(' ').join('')
            : 'empty';
        if (workType2 === 'Pregnancy leave' && nameOfClass2 !== 'empty') {
          nameOfClass2 = 'Pregnancyleave';
        }
        let overTimeText = 'Overtime ' + overTime;
        let overTimeText2 = 'Overtime 2 ' + overTime2;
        for (const employeeCheck of halfTimePerEmployee) {
          if (employeeCheck.checked && selectedEmployee === employeeCheck.employee) {
            if (parseFloat(hoursParts[0]) === 8) {
              hoursParts[0] = parseFloat(hoursParts[0]) / 2;
              if (hoursParts[0].toString().length === 1) {
                hoursParts[0] = '0' + hoursParts[0];
              }
            }
            hours = hoursParts.join(':');
            let newCalendarData = [];
            for (const day of allCalendarData) {
              if (moment(value).isSame(moment(day.selectedDate), 'day') && day.employee === selectedEmployee) {
                day.hours = hours;
              }
              newCalendarData.push(day);
            }
            localStorage.setItem('calendarData', JSON.stringify(newCalendarData));
          }
        }
        if (
          Number(overTimeParts[0]) !== 0 ||
          Number(overTimeParts[1]) !== 0 ||
          (overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0))
        ) {
          listData.push({
            type: nameOfClass,
            content: `${Number(hoursParts[0]) !== 0 || Number(hoursParts[1]) !== 0 ? workType + ' ' + hours : ''}`,
          });
          if (hoursParts2 && workType2 && (Number(hoursParts2[0]) !== 0 || Number(hoursParts2[1]) !== 0)) {
            listData.push({
              type: nameOfClass2,
              content: `${
                hoursParts2 && (Number(hoursParts2[0]) !== 0 || Number(hoursParts2[1]) !== 0)
                  ? workType2 + ' ' + hours2
                  : ''
              }`,
            });
          }
          listData.push({
            type: 'overTime',
            content: `${Number(overTimeParts[0]) !== 0 || Number(overTimeParts[1]) !== 0 ? overTimeText : ''} ${
              overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0)
                ? overTimeText2
                : ''
            }`,
          });
        } else {
          listData.push({
            type: nameOfClass,
            content: `${Number(hoursParts[0]) !== 0 || Number(hoursParts[1]) !== 0 ? workType + ' ' + hours : ''}`,
          });
          if (hoursParts2 && workType2 && (Number(hoursParts2[0]) !== 0 || Number(hoursParts2[1]) !== 0)) {
            listData.push({
              type: nameOfClass2,
              content: `${
                hoursParts2 && (Number(hoursParts2[0]) !== 0 || Number(hoursParts2[1]) !== 0)
                  ? workType2 + ' ' + hours2
                  : ''
              }`,
            });
          }
        }
        return listData;
      }

      if (!selectedDate) {
        if (calendarData.length === 0 && selectedEmployee === employee.data.items[0]._id) {
          // this condition checks if employee was employed or not on Value date and check if it's not a weekend
          const isEmployed =
            (moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
              (employee.data.items[0].endDate &&
                moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))) &&
            moment(value).day() !== 0 &&
            moment(value).day() !== 6;

          let data = {
            employee: selectedEmployee,
            selectedDate: value,
            hours:
              employee.data.items[0].workTimeType === 'halfTime'
                ? '04:00'
                : employee?.data?.items[0]?.workTimeType === 'customTime'
                  ? `${customHours}`
                  : '08:00',
            hours2: '00:00',
            workType: !isEmployed ? 'Regular work' : 'Unemployed',
            workType2: '',
            overTime: '00:00',
            overTime2: chosenCompany[0]?.overTime2 ? '00:00' : undefined,
          };
          allCalendarData.push(data);
          let nameOfClass = data.workType !== '' ? data.workType.replace('%', '').split(' ').join('') : 'empty';
          if (data.workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
            nameOfClass = 'Pregnancyleave';
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
          listData.push({ type: nameOfClass, content: !isEmployed ? 'Regular work 08:00' : 'Unemployed 08:00' });
        } else {
          let isExist = false;
          let foundData;
          for (let data of calendarData) {
            if (moment(data.selectedDate).isSame(moment(value), 'days') && data.employee === selectedEmployee) {
              isExist = true;
              foundData = data;
              break;
            }
          }
          if (
            isExist === false &&
            moment(value).day() !== 0 &&
            moment(value).day() !== 6 &&
            selectedEmployee === employee.data.items[0]._id
          ) {
            let data = {
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Regular work',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany[0].overTime2 ? '00:00' : undefined,
            };
            allCalendarData.push(data);
            let nameOfClass = data.workType !== '' ? data.workType.replace('%', '').split(' ').join('') : 'empty';
            if (data.workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
              nameOfClass = 'Pregnancyleave';
            }
            localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
            listData.push({ type: nameOfClass, content: 'Regular work 08:00' });
          } else if (isExist) {
            let overTimeParts = foundData.overTime.split(':');
            let overTimeParts2 = foundData.overTime2 ? foundData.overTime2.split(':') : undefined;
            let workTimeParts = foundData.hours.split(':');
            let workTimeMinutes = Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
            let workTimeParts2 = foundData.hours2.split(':');
            let sumHours;
            let hours2 = foundData.hours2;
            if (Number(overTimeParts[1]) !== 0) {
              sumHours = moment().set('hours', 0).set('minutes', 0).add(workTimeMinutes, 'minutes').format('hh:mm');
            } else {
              sumHours = foundData.hours;
            }
            let nameOfClass =
              foundData.workType !== '' ? foundData.workType.replace('%', '').split(' ').join('') : 'empty';
            if (foundData.workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
              nameOfClass = 'Pregnancyleave';
            }
            let nameOfClass2 =
              foundData.workType2 && foundData.workType2 !== ''
                ? foundData.workType2?.replace('%', '').split(' ').join('')
                : 'empty';
            if (foundData.workType2 === 'Pregnancy leave' && nameOfClass2 !== 'empty') {
              nameOfClass2 = 'Pregnancyleave';
            }
            let overTimeText = 'Overtime ' + foundData.overTime;
            let overTimeText2 = 'Overtime 2 ' + foundData.overTime2;
            if (
              Number(overTimeParts[0]) !== 0 ||
              Number(overTimeParts[1]) !== 0 ||
              (overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0))
            ) {
              listData.push({
                type: nameOfClass,
                content: `${
                  Number(workTimeParts[0]) !== 0 || Number(workTimeParts[1]) !== 0
                    ? foundData.workType + ' ' + sumHours
                    : ''
                }`,
              });
              if (
                workTimeParts2 &&
                foundData.workType2 &&
                (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
              ) {
                listData.push({
                  type: nameOfClass2,
                  content: `${
                    workTimeParts2 && (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
                      ? foundData.workType2 + ' ' + hours2
                      : ''
                  }`,
                });
              }
              listData.push({
                type: 'overTime',
                content: `${Number(overTimeParts[0]) !== 0 || Number(overTimeParts[1]) !== 0 ? overTimeText : ''} ${
                  overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0)
                    ? overTimeText2
                    : ''
                }`,
              });
            } else {
              listData.push({
                type: nameOfClass,
                content: `${
                  Number(workTimeParts[0]) !== 0 || Number(workTimeParts[1]) !== 0
                    ? foundData.workType + ' ' + sumHours
                    : ''
                }`,
              });
              if (
                workTimeParts2 &&
                foundData.workType2 &&
                (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
              ) {
                listData.push({
                  type: nameOfClass2,
                  content: `${
                    workTimeParts2 && (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
                      ? foundData.workType2 + ' ' + hours2
                      : ''
                  }`,
                });
              }
            }
          }
        }
      } else {
        let hours = customHours ? customHours : '08:00';

        let hoursParts = hours.split(':');
        for (const employeeCheck of halfTimePerEmployee) {
          if (employeeCheck.checked && selectedEmployee === employeeCheck.employee) {
            hoursParts[0] = parseFloat(hoursParts[0]) / 2;
            if (hoursParts[0].toString().length === 1) {
              hoursParts[0] = '0' + hoursParts[0];
            }
            hours = hoursParts.join(':');
            let newCalendarData = [];
            for (const day of allCalendarData) {
              if (moment(value).isSame(moment(day.selectedDate), 'day') && day.employee === selectedEmployee) {
                day.hours = hours;
              }
              newCalendarData.push(day);
            }
            localStorage.setItem('calendarData', JSON.stringify(newCalendarData));
          } else if (!employeeCheck.checked && selectedEmployee === employeeCheck.employee) {
            let newCalendarData = [];
            for (const day of allCalendarData) {
              if (moment(value).isSame(moment(day.selectedDate), 'day') && day.employee === selectedEmployee) {
                if (!clickedHalfTime) {
                  day.hours = day.hours !== hours ? day.hours : hours;
                } else {
                  day.hours = hours;
                  setClickedHalfTime(false);
                }
              }
              newCalendarData.push(day);
            }
            localStorage.setItem('calendarData', JSON.stringify(newCalendarData));
          }
        }

        for (let data of calendarData) {
          if (moment(data.selectedDate).isSame(moment(value), 'days') && selectedEmployee === data.employee) {
            let overTimeParts = data.overTime.split(':');
            let overTimeParts2 = data.overTime2 ? data.overTime2.split(':') : undefined;

            let workTimeParts = customHours ? customHours.split(':') : data.hours.split(':');

            let workTimeMinutes = Number(workTimeParts[0]) * 60 + Number(workTimeParts[1]);
            let workTimeParts2 = data.hours2 && data.hours2.split(':');
            let sumHours;
            let hours2 = data.hours2;
            if (Number(overTimeParts[1]) !== 0) {
              sumHours = moment().set('hours', 0).set('minutes', 0).add(workTimeMinutes, 'minutes').format('hh:mm');
            } else {
              sumHours = customHours ? customHours : data.hours;
            }
            let nameOfClass =
              data.workType !== '' && (Number(workTimeParts[0]) !== 0 || Number(workTimeParts[1]) !== 0)
                ? data.workType.replace('%', '').split(' ').join('')
                : 'empty';
            if (data.workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
              nameOfClass = 'Pregnancyleave';
            }

            let nameOfClass2 =
              data.workType2 &&
              data.workType2 !== '' &&
              workTimeParts2 &&
              (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
                ? data.workType2?.replace('%', '').split(' ').join('')
                : 'empty';
            if (data.workType2 === 'Pregnancy leave' && nameOfClass2 !== 'empty') {
              nameOfClass2 = 'Pregnancyleave';
            }
            let overTimeText = 'Overtime ' + data.overTime;
            let overTimeText2 = 'Overtime 2 ' + data.overTime2;
            if (
              Number(overTimeParts[0]) !== 0 ||
              Number(overTimeParts[1]) !== 0 ||
              (overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0))
            ) {
              listData.push({
                type: nameOfClass,
                content: `${
                  Number(workTimeParts[0]) !== 0 || Number(workTimeParts[1]) !== 0 ? data.workType + ' ' + sumHours : ''
                }`,
              });
              if (
                workTimeParts2 &&
                data.workType2 &&
                (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
              ) {
                listData.push({
                  type: nameOfClass2,
                  content: `${
                    workTimeParts2 && (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
                      ? data.workType2 + ' ' + hours2
                      : ''
                  }`,
                });
              }
              listData.push({
                type: 'overTime',
                content: `${Number(overTimeParts[0]) !== 0 || Number(overTimeParts[1]) !== 0 ? overTimeText : ''} ${
                  overTimeParts2 && (Number(overTimeParts2[0]) !== 0 || Number(overTimeParts2[1]) !== 0)
                    ? overTimeText2
                    : ''
                }`,
              });
            } else {
              listData.push({
                type: nameOfClass,
                content: `${
                  Number(workTimeParts[0]) !== 0 || Number(workTimeParts[1]) !== 0 ? data.workType + ' ' + sumHours : ''
                }`,
              });
              if (
                workTimeParts2 &&
                data.workType2 &&
                (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
              ) {
                listData.push({
                  type: nameOfClass2,
                  content: `${
                    workTimeParts2 && (Number(workTimeParts2[0]) !== 0 || Number(workTimeParts2[1]) !== 0)
                      ? data.workType2 + ' ' + hours2
                      : ''
                  }`,
                });
              }
            }
            isExist = true;
            break;
          }
        }

        if (isExist === false && moment(value).day() !== 0 && moment(value).day() !== 6) {
          let data = {};
          if (
            moment(employee.data.items[0].startDate).isAfter(moment(value), 'days') ||
            (employee.data.items[0].endDate && moment(employee.data.items[0].endDate).isBefore(moment(value), 'days'))
          ) {
            data = {
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Unemployed',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany && chosenCompany[0].overTime2 ? '00:00' : undefined,
            };
            allCalendarData.push(data);
          } else {
            data = {
              employee: selectedEmployee,
              selectedDate: value,
              hours:
                employee.data.items[0].workTimeType === 'halfTime'
                  ? '04:00'
                  : employee?.data?.items[0]?.workTimeType === 'customTime'
                    ? `${customHours}`
                    : '08:00',
              hours2: '00:00',
              workType: 'Regular work',
              workType2: '',
              overTime: '00:00',
              overTime2: chosenCompany && chosenCompany[0]?.overTime2 ? '00:00' : undefined,
            };

            allCalendarData.push(data);
          }

          let nameOfClass = data.workType !== '' ? data.workType.replace('%', '').split(' ').join('') : 'empty';
          if (data.workType === 'Pregnancy leave' && nameOfClass !== 'empty') {
            nameOfClass = 'Pregnancyleave';
          }
          localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
          listData.push({ type: nameOfClass, content: data.workType + ' ' + data.hours });
        }

        if (
          selectedDate &&
          isExist === false &&
          moment(selectedDate).isSame(moment(value), 'days') &&
          !openedWeekendForm
        ) {
          form.setFieldsValue({
            workHours: undefined,
            workType: undefined,
            overTime: undefined,
            overTime2: undefined,
          });
          setOpenWeekendForm(true);
        }
      }
    }

    // this removes all weekend days except some by the rules
    allCalendarData = allCalendarData.filter((item) => {
      const dayOfWeek = moment(item.selectedDate).day();
      // Check for weekend
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      return (
        !!item.selectedDate ||
        item.workType === '' ||
        !isWeekend ||
        item.workType !== 'Regular work' ||
        item.workType2 !== ''
      );
    });

    localStorage.setItem('calendarData', JSON.stringify(allCalendarData));
    return listData;
  };

  // --------------------------------get list data end-----------------------------------------------------

  const dateCellRender = (value) => {
    const formattedDate = formatDate(value);
    const isSelectedDate = formattedDate === selectedDate;
    const cellClass =
      isSelectedDate && selectedDate && !customSingleDayChildrenDrawer && !customChildrenDrawer
        ? 'selected-date-cell'
        : '';

    if (showCalendar) {
      const listData = getListData(formattedDate);
      let calendarList = [];

      if (listData.length !== 0) {
        for (const singleData of listData) {
          calendarList.push(
            <li key={singleData.content}>
              <Badge status={singleData.type} text={singleData.content} />
            </li>,
          );
        }
      }

      return (
        <ul className={`events ${value.day() === 0 || value.day() === 6 ? 'weekend' : ''} ${cellClass}`}>
          {calendarList}
        </ul>
      );
    }
  };

  const onChangeWorkHours = (value) => {
    updateDateEmployee();
    const selectedDateLocal =
      (selectedMonth === singleMonth || !singleMonth) && selectedDate
        ? moment(selectedDate)
        : moment(selectedDate).set('month', months[singleMonth] - 1);
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(selectedDateLocal), 'days')) {
        data.hours = moment(value).format('HH:mm');
        break;
      }
    }
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    setRandomKeyCalendar(Math.random());
  };

  const onChangeWorkType = async (value) => {
    const selectedDateLocal =
      (selectedMonth === singleMonth || !singleMonth) && selectedDate
        ? moment(selectedDate)
        : moment(selectedDate).set('month', months[singleMonth] - 1);

    createRequestForWorkType(value, selectedDateLocal.startOf('day').format('YYYY-MM-DD'));

    updateDateEmployee();
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(selectedDateLocal), 'days')) {
        data.workType = value;
        break;
      }
    }

    setWorkType(value);
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    setRandomKeyCalendar(Math.random());
  };

  const onChangeWorkType2 = async (value) => {
    const selectedDateLocal =
      (selectedMonth === singleMonth || !singleMonth) && selectedDate
        ? moment(selectedDate)
        : moment(selectedDate).set('month', months[singleMonth] - 1);
    createRequestForWorkType(value, selectedDateLocal.startOf('day').format('YYYY-MM-DD'));
    await updateDateEmployee();
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(selectedDateLocal), 'days')) {
        data.workType2 = value;
        break;
      }
    }
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
  };

  const onChangeOverTime = (value) => {
    updateDateEmployee();
    const selectedDateLocal =
      (selectedMonth === singleMonth || !singleMonth) && selectedDate
        ? moment(selectedDate)
        : moment(selectedDate).set('month', months[singleMonth] - 1);
    // TODO uncomment this if we want to make requests for overtime too
    // createRequestForWorkType('Overtime', value);
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(selectedDateLocal), 'days')) {
        data.overTime = moment(value).format('HH:mm');
        break;
      }
    }
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    setTimePickerKey(timePickerKey + 1);
  };

  const onChangeOverTime2 = (value) => {
    // setOverTime(value);
    updateDateEmployee();
    const selectedDateLocal =
      (selectedMonth === singleMonth || !singleMonth) && selectedDate
        ? moment(selectedDate)
        : moment(selectedDate).set('month', months[singleMonth] - 1);
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(selectedDateLocal), 'days')) {
        data.overTime2 = moment(value).format('HH:mm');
        break;
      }
    }
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    setTimePickerKey2(timePickerKey2 + 1);
  };

  const calendarHeaderDropdownOnClick = ({ key }) => {
    switch (key) {
      case 'terminateEmployee':
        setMultimarking(false);
        form.setFieldsValue({
          multimarkingMode: false,
          workType2: undefined,
        });
        setIsTerminateEmployeeModalOpen(true);
        break;
      case 'extendEmployment':
        setMultimarking(false);
        form.setFieldsValue({
          multimarkingMode: false,
          workType2: undefined,
        });
        setIsExtendEmploymentModalOpen(true);
        break;
      default:
        console.log('Default action not defined');
    }
  };

  const formHeaderItems = useMemo(() => {
    return [
      {
        key: 'terminateEmployee',
        label: (
          <Tooltip
            title='Select the date from which you want to terminate personel employment.'
            color='#b1b1b1'
            placement='leftTop'
            autoAdjustOverflow={true}
          >
            <span>Terminate Employee</span>
          </Tooltip>
        ),
        disabled: timeRangeCalendarDisabled,
      },
      {
        key: 'extendEmployment',
        label: (
          <Tooltip
            title='Select the date from which you want to terminate personel employment.'
            color='#b1b1b1'
            placement='leftTop'
            autoAdjustOverflow={true}
          >
            Extend Employment
          </Tooltip>
        ),
        hidden:
          employee?.data?.items[0]?.employmentType === 'limited' && !!employee?.data?.items[0]?.endDate ? false : true,
      },
    ];
  }, [employee?.data?.items, timeRangeCalendarDisabled]);

  const menu = (
    <Menu onClick={calendarHeaderDropdownOnClick}>
      {formHeaderItems.map((item) => {
        const disabled = item.disabled === undefined ? false : item.disabled;
        const hidden = item.hidden === undefined ? false : item.hidden;

        return (
          <Menu.Item key={item.key} disabled={disabled} hidden={hidden}>
            {item.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      employee &&
      employee?.data &&
      employee?.data?.items &&
      employee?.data?.items[0]?._id === selectedEmployee &&
      onXIcon
    ) {
      form.setFieldsValue({
        hotMeal: employee.data.items[0].hotMeal || 0,
        regres: employee.data.items[0].regres || 0,
        travelExpenses: employee.data.items[0].travelExpenses || 0,
        workFromHome: Number(employee.data.items[0].workFromHome) || 0,
        workTimeCustomType: Number(employee.data.items[0].workTimeCustomType) || 0,
        otherIncome: Number(employee.data.items[0].otherIncomeValue) || 0,
        suspension: Number(employee.data.items[0].suspensionValue) || 0,
        otherExpenses: Number(employee.data.items[0].otherExpensesValue) || 0,
        bonus: Number(employee.data.items[0].bonusValue) || 0,
        numberOfHolidayDays: Number(employee.data.items[0].numberOfHolidayDays) || 0,
        numberOfHolidayDaysByEndOfContract: Number(employee.data.items[0].numberOfHolidayDaysByEndOfContract) || 0,
      });

      onFinish(form.getFieldsValue());
      setOnXIcon(false);
    }

    return () => {};
  }, [employee, form, onFinish, onXIcon, selectedEmployee]);

  useEffect(() => {
    if (resetFormFieldValues) {
      form.resetFields();
    }

    return () => {};
  }, [resetFormFieldValues]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      employee &&
      employee.data &&
      employee.data.items &&
      employee.data.items.length !== 0 &&
      employee.data.items[0]._id === selectedEmployee &&
      findedSalary.length === 0
    ) {
      if (employee.data.items[0].hotMeal) {
        form.setFieldsValue({
          hotMeal: employee.data.items[0].hotMeal,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.hotMeal === 0 &&
            employee.data.items[0].hotMeal !== 0
          ) {
            formData.hotMeal = employee.data.items[0].hotMeal;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }
      if (employee.data.items[0].regres) {
        form.setFieldsValue({
          regres: employee.data.items[0].regres,
        });
        for (const formData of employeeFormData) {
          if (formData.employee === selectedEmployee && formData.regres === 0 && employee.data.items[0].regres !== 0) {
            formData.regres = employee.data.items[0].regres;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }
      if (employee.data.items[0].travelExpenses) {
        form.setFieldsValue({
          travelExpenses: employee.data.items[0].travelExpenses,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.travelExpenses === 0 &&
            employee.data.items[0].travelExpenses !== 0
          ) {
            formData.travelExpenses = employee.data.items[0].travelExpenses;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }
      if (employee.data.items[0].workFromHome) {
        form.setFieldsValue({
          workFromHome: employee.data.items[0].workFromHome,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.workFromHome === 0 &&
            employee.data.items[0].workFromHome !== 0
          ) {
            formData.workFromHome = employee.data.items[0].workFromHome;
            break;
          }
        }

        setEmployeeFormData(employeeFormData);
      }
      if (employee.data.items[0].workTimeCustomType) {
        form.setFieldsValue({
          workTimeCustomType: employee.data.items[0].workTimeCustomType,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.workTimeCustomType === 0 &&
            employee.data.items[0].workTimeCustomType !== 0
          ) {
            formData.workTimeCustomType = employee.data.items[0].workTimeCustomType;
            break;
          }
        }

        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].otherIncomeValue) {
        form.setFieldsValue({
          otherIncome: employee.data.items[0].otherIncomeValue,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.otherIncome === 0 &&
            employee.data.items[0].otherIncomeValue !== 0
          ) {
            formData.otherIncome = employee.data.items[0].otherIncomeValue;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].suspensionValue) {
        form.setFieldsValue({
          suspension: employee.data.items[0].suspensionValue,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.suspension === 0 &&
            employee.data.items[0].suspensionValue !== 0
          ) {
            formData.suspension = employee.data.items[0].suspensionValue;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].bonusValue) {
        form.setFieldsValue({
          bonus: employee.data.items[0].bonusValue,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.bonus === 0 &&
            employee.data.items[0].bonusValue !== 0
          ) {
            formData.bonus = employee.data.items[0].bonusValue;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].numberOfHolidayDays) {
        form.setFieldsValue({
          numberOfHolidayDays: employee.data.items[0].numberOfHolidayDays,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.numberOfHolidayDays === 0 &&
            employee.data.items[0].numberOfHolidayDays !== 0
          ) {
            formData.numberOfHolidayDays = employee.data.items[0].numberOfHolidayDays;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].numberOfHolidayDaysByEndOfContract) {
        form.setFieldsValue({
          numberOfHolidayDaysByEndOfContract: employee.data.items[0].numberOfHolidayDaysByEndOfContract,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.numberOfHolidayDaysByEndOfContract === 0 &&
            employee.data.items[0].numberOfHolidayDaysByEndOfContract !== 0
          ) {
            formData.numberOfHolidayDaysByEndOfContract = employee.data.items[0].numberOfHolidayDaysByEndOfContract;
            break;
          }
        }
        setEmployeeFormData(employeeFormData);
      }

      if (employee.data.items[0].otherExpensesValue) {
        form.setFieldsValue({
          otherExpenses: employee.data.items[0].otherExpensesValue,
        });
        for (const formData of employeeFormData) {
          if (
            formData.employee === selectedEmployee &&
            formData.otherExpenses === 0 &&
            employee.data.items[0].otherExpensesValue !== 0
          ) {
            formData.otherExpenses = employee.data.items[0].otherExpensesValue;
            break;
          }
        }

        setEmployeeFormData(employeeFormData);
      }
    }

    if (findedSalary && findedSalary.length !== 0) {
      form.setFieldsValue({
        hotMeal: findedSalary[0]?.hotMeal,
        regres: findedSalary[0]?.regresInitial,
        travelExpenses: findedSalary[0]?.travelExpensesInitial,
        workFromHome: findedSalary[0]?.employee?.workFromHomeInitial,
        otherIncome: findedSalary[0]?.employee?.otherIncomeValue,
        suspension: findedSalary[0]?.employee?.suspensionValue,
        otherExpenses: findedSalary[0]?.employee?.otherExpensesValue,
        bonus: findedSalary[0]?.employee?.bonusValue,
        numberOfHolidayDays: findedSalary[0]?.employee?.numberOfHolidayDays,
        numberOfHolidayDaysByEndOfContract: findedSalary[0]?.employee?.numberOfHolidayDaysByEndOfContract,
      });

      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.hotMeal = findedSalary[0]?.hotMeal;
          formData.regres = findedSalary[0]?.regresInitial;
          formData.travelExpenses = findedSalary[0]?.travelExpensesInitial;
          formData.workFromHome = findedSalary[0]?.employee?.workFromHomeInitial;
          formData.otherIncome = findedSalary[0]?.employee?.otherIncomeValue;
          formData.suspension = findedSalary[0]?.employee?.suspensionValue;
          formData.otherExpenses = findedSalary[0]?.employee?.otherExpensesValue;
          formData.bonus = findedSalary[0]?.employee?.bonusValue;
          formData.numberOfHolidayDays = findedSalary[0]?.employee?.numberOfHolidayDays;
          formData.numberOfHolidayDaysByEndOfContract = findedSalary[0]?.employee?.numberOfHolidayDaysByEndOfContract;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0) {
      form.setFieldsValue({
        hotMeal: !findedSalary[0].hotMealInitial ? findedSalary[0].hotMeal : findedSalary[0].hotMealInitial,
        regres: !findedSalary[0].regresInitial ? findedSalary[0].regres : findedSalary[0].regresInitial,
        travelExpenses: !findedSalary[0]?.travelExpensesInitial
          ? findedSalary[0]?.travelExpenses
          : findedSalary[0]?.travelExpensesInitial,
        workFromHome: !findedSalary[0]?.workFromHomeInitial
          ? findedSalary[0]?.workFromHome
          : findedSalary[0]?.workFromHomeInitial,
        otherIncome: findedSalary[0]?.employee?.otherIncomeValue,
        suspension: findedSalary[0]?.employee?.suspensionValue,
        bonus: findedSalary[0]?.employee?.bonusValue,
        otherExpenses: findedSalary[0]?.employee?.otherExpensesValue,
        numberOfHolidayDays: findedSalary[0]?.employee?.numberOfHolidayDays,
        numberOfHolidayDaysByEndOfContract: findedSalary[0]?.employee?.numberOfHolidayDaysByEndOfContract,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.hotMeal = findedSalary[0].hotMeal;
          formData.regres = findedSalary[0].regres;
          formData.travelExpenses = findedSalary[0].travelExpenses;
          formData.workFromHome = findedSalary[0]?.employee?.workFromHome;
          formData.otherIncome = findedSalary[0]?.employee?.otherIncomeValue;
          formData.suspension = findedSalary[0]?.employee?.suspensionValue;
          formData.bonus = findedSalary[0]?.employee?.bonusValue;
          formData.otherExpenses = findedSalary[0]?.employee?.otherExpensesValue;
          formData.numberOfHolidayDays = findedSalary[0]?.employee?.numberOfHolidayDays;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0) {
      form.setFieldsValue({
        bonus: findedSalary[0].bonus,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.bonus = findedSalary[0].bonus;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].otherIncome) {
      form.setFieldsValue({
        otherIncome: findedSalary[0].otherIncome,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.otherIncome = findedSalary[0].otherIncome;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }
    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].suspension) {
      form.setFieldsValue({
        suspension: findedSalary[0].suspension,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.suspension = findedSalary[0].suspension;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].bonus) {
      form.setFieldsValue({
        bonus: findedSalary[0].bonus,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.bonus = findedSalary[0].bonus;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].otherExpenses) {
      form.setFieldsValue({
        otherExpenses: findedSalary[0].otherExpenses,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.otherExpenses = findedSalary[0].otherExpenses;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].numberOfHolidayDays) {
      form.setFieldsValue({
        numberOfHolidayDays: findedSalary[0].numberOfHolidayDays,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.numberOfHolidayDays = findedSalary[0].numberOfHolidayDays;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].numberOfHolidayDaysByEndOfContract) {
      form.setFieldsValue({
        numberOfHolidayDaysByEndOfContract: findedSalary[0].numberOfHolidayDaysByEndOfContract,
      });
      for (const formData of employeeFormData) {
        if (formData.employee === selectedEmployee) {
          formData.numberOfHolidayDaysByEndOfContract = findedSalary[0].numberOfHolidayDaysByEndOfContract;
          break;
        }
      }
      setEmployeeFormData(employeeFormData);
    }

    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].comment) {
      form.setFieldsValue({
        comment: findedSalary[0].comment,
      });
    }
    setRandomKeyForm(Math.random());
    return () => {};
  }, [selectedEmployee, findedSalary, employee.data, employee, form, employeeFormData]);

  let workTypeList = [];
  if (chosenCompany && chosenCompany.length !== 0 && chosenCompany[0].workTypes) {
    for (const type of chosenCompany[0].workTypes) {
      if (type !== 'Other leave') {
        workTypeList.push(
          <TreeNode
            value={type}
            key={type}
            title={
              <div>
                <img className={'circleImage ' + type.toLowerCase().replace('%', '').split(' ').join('')} />
                <span
                  className='type-of-work-dropdown-span__element'
                  style={
                    type === 'Paid leave'
                      ? {
                          color: '#000',
                          cursor: 'default',
                        }
                      : undefined
                  }
                >
                  {type}
                </span>{' '}
              </div>
            }
            disabled={type === 'Paid leave' ? true : false}
            className={type === 'Paid leave' ? 'type-of-work-tree-node-paid-leave' : ''}
            style={
              type === 'Paid leave'
                ? {
                    cursor: 'default',
                  }
                : undefined
            }
          >
            {type === 'Paid leave' && (
              <>
                <TreeNode
                  value='Paid leave W'
                  title={
                    <div>
                      <img className={'circleImage ' + type.toLowerCase().replace('%', '').split(' ').join('')} />
                      <span className='type-of-work-dropdown-span__element'>wedding</span>
                    </div>
                  }
                />
                <TreeNode
                  value='Paid leave S'
                  title={
                    <div>
                      <img className={'circleImage ' + type.toLowerCase().replace('%', '').split(' ').join('')} />
                      <span className='type-of-work-dropdown-span__element'>slava</span>
                    </div>
                  }
                />
                <TreeNode
                  value='Paid leave D'
                  title={
                    <div>
                      <img className={'circleImage ' + type.toLowerCase().replace('%', '').split(' ').join('')} />
                      <span className='type-of-work-dropdown-span__element'>death</span>
                    </div>
                  }
                />
                <TreeNode
                  value='Paid leave O'
                  title={
                    <div>
                      <img className={'circleImage ' + type.toLowerCase().replace('%', '').split(' ').join('')} />
                      <span className='type-of-work-dropdown-span__element'>other</span>
                    </div>
                  }
                />
              </>
            )}
          </TreeNode>,
        );
      }
    }
  }

  const changeFormData = (event) => {
    for (const formData of employeeFormData) {
      if (formData.employee === selectedEmployee) {
        formData[event.target.id] = parseFloat(event.target.value);
        break;
      }
    }
  };

  let calendarValue;
  if (selectedYear) {
    calendarValue = moment().set('years', selectedYear);
  } else {
    if (selectedDate) {
      calendarValue = moment(selectedDate).startOf('day').format('YYYY-MM-DD');
    }
  }

  if (selectedMonth) {
    calendarValue = moment(calendarValue).set('months', selectedMonth);
  } else {
    calendarValue = moment(calendarValue).set('months', selectedMonth);
  }

  // this triggers render for showing all classes and data
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (changedMonth) {
      setChangedMonth(false);
    }
    if (changedYear) {
      setChangedYear(false);
    }
    setChangedData(false);

    return () => {};
  }, [changedMonth, changedData, changedYear]);

  // function which triggers on multimarking mode
  const changeDayData = (value) => {
    setCustomDay(false);
    let isFound = false;
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(value), 'days')) {
        isFound = true;
        data.selectedDate = moment(value).startOf('day').format('YYYY-MM-DD');
        data.hours = workHours;
        data.hours2 = '00:00';
        data.workType = workType ? workType : data.workType;
        data.overTime = overTime ? moment(overTime).format('HH:mm') : '00:00';
        break;
      }
    }
    if (!isFound && (moment(value).day() === 0 || moment(value).day() === 6)) {
      if (multimarking) {
        setMultimarking(false);
        form.setFieldsValue({
          multimarkingMode: false,
        });
        setWorkType(undefined);
        setOverTime(undefined);
      }
      if (customDay) {
        setCustomDay(false);
        form.setFieldsValue({
          customDayMode: false,
        });
      }
      form.setFieldsValue({
        workHours: undefined,
        workType: undefined,
        overTime: undefined,
        overTime2: undefined,
      });
      let data = {
        employee: selectedEmployee,
        selectedDate: moment(value).startOf('day').format('YYYY-MM-DD'),
        hours: form.getFieldValue('workHours') ? moment(form.getFieldValue('workHours')).format('HH:mm') : '00:00',
        workType: form.getFieldValue('workType') ? form.getFieldValue('workType') : '',
        overTime: form.getFieldValue('overTime') ? moment(form.getFieldValue('overTime')).format('HH:mm') : '00:00',
        overTime2: form.getFieldValue('overTime2') ? moment(form.getFieldValue('overTime2')).format('HH:mm') : '00:00',
      };
      calendarData.push(data);
      localStorage.setItem('calendarData', JSON.stringify(calendarData));
    } else if (!isFound) {
      let data = {
        employee: selectedEmployee,
        selectedDate: moment(value),
        hours: workHours,
        workType: workType ? workType : '',
        overTime: overTime ? moment(overTime).format('HH:mm') : '00:00',
        overTime2: '00:00',
      };
      calendarData.push(data);
      localStorage.setItem('calendarData', JSON.stringify(calendarData));
    }
    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    createRequestForWorkType(workType, value, 'multimarking');
  };

  // function which triggers on multimarking mode on custom days or in single custom day mode
  const changeDayDataCustom = (value, isSingleCustomDay) => {
    setCustomDay(false);
    let isFound = false;
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    for (let data of calendarData) {
      if (data.employee === selectedEmployee && moment(data.selectedDate).isSame(moment(value), 'days')) {
        isFound = true;
        data.selectedDate = moment(value).startOf('day').format('YYYY-MM-DD');
        data.hours = new Date(custom1HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        data.hours2 = new Date(custom2HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        data.workType = workType1FromDrawer;
        data.workType2 = workType2FromDrawer;
        data.workType = workType ? workType : data.workType;
        data.overTime = overTime ? moment(overTime).format('HH:mm') : '00:00';
        break;
      }
    }
    if (!isFound && (moment(value).day() === 0 || moment(value).day() === 6)) {
      form.setFieldsValue({
        workHours: undefined,
        workType: undefined,
        overTime: undefined,
        overTime2: undefined,
      });
      let data = {
        employee: selectedEmployee,
        selectedDate: moment(value).startOf('day').format('YYYY-MM-DD'),
        hours: new Date(custom1HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        hours2: new Date(custom2HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        workType: workType1FromDrawer,
        workType2: workType2FromDrawer,
        overTime: form.getFieldValue('overTime') ? moment(form.getFieldValue('overTime')).format('HH:mm') : '00:00',
        overTime2: form.getFieldValue('overTime2') ? moment(form.getFieldValue('overTime2')).format('HH:mm') : '00:00',
      };
      calendarData.push(data);
      localStorage.setItem('calendarData', JSON.stringify(calendarData));
    } else if (!isFound) {
      let data = {
        employee: selectedEmployee,
        selectedDate: (moment(value).day() === 0 || moment(value).day() === 6) ? moment('00:00', 'HH:mm') : moment(value).startOf('day').format('YYYY-MM-DD'),
        hours: new Date(custom1HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        hours2: new Date(custom2HoursFromDrawer).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
        workType: workType1FromDrawer,
        workType2: workType2FromDrawer,
        overTime: overTime ? moment(overTime).format('HH:mm') : '00:00',
        overTime2: '00:00',
      };
      calendarData.push(data);
      localStorage.setItem('calendarData', JSON.stringify(calendarData));
    }

    localStorage.setItem('calendarData', JSON.stringify(calendarData));

    if (isSingleCustomDay) {
      form.setFieldsValue({
        customDayMode: false,
      });
      setCustomDay(false);
      setWorkType1FromDrawer(undefined);
      setWorkType2FromDrawer(undefined);
      setCustom1HoursFromDrawer(undefined);
      setCustom2HoursFromDrawer(undefined);
      setRandomKeyForm(Math.random());
    }
  };

  useEffect(() => {
    if (!multiMarkingCustomMode) {
      setWorkType1FromDrawer(undefined);
      setWorkType2FromDrawer(undefined);
      setCustom1HoursFromDrawer(undefined);
      setCustom2HoursFromDrawer(undefined);
    }
  }, [multiMarkingCustomMode]);

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };
  // triggers on change of screen width
  window.addEventListener('resize', updateSize);

  const extractBaseName = (fileName) => {
    return fileName?.replace(/\.(doc|pdf)$/i, '');
  };

  const checkFileType = (originalName) => {
    const pdfPattern = /\.pdf$/i;
    const wordPattern = /\.(doc|docx)$/i;

    if (pdfPattern.test(originalName)) {
      return 'pdf';
    }

    if (wordPattern.test(originalName)) {
      return 'word';
    }
    return 'unknown';
  };

  useEffect(() => {
    if (
      findedSalary &&
      findedSalary.length === 1 &&
      findedSalary[0].attachments &&
      findedSalary[0].attachments.length !== 0 &&
      fileList.length === 0
    ) {
      findedSalary[0].attachments.forEach((attachment, index) => {
        let thumbUrl;
        let nameArray = attachment.originalName?.split('.') || '';
        switch (nameArray[nameArray.length - 1]) {
          case 'pdf':
            thumbUrl = '/pdf.png';
            break;
          case 'doc':
          case 'docx':
            thumbUrl = '/docx.png';
            break;
          case 'png':
            thumbUrl = '/png.png';
            break;
          case 'jpg':
            thumbUrl = '/jpg.png';
            break;
          case 'xlsx':
            thumbUrl = '/xlsx.png';
            break;

          default:
            thumbUrl = '/other.png';
            break;
        }

        // This set state throws warning of a bad set state
        setFileList((prevState) => {
          // Check if the new uid already exists in prevState

          const currentFileName = extractBaseName(attachment.originalName);
          const fileExtension = checkFileType(attachment.originalName);
          const doesFileExist = prevState.length
            ? prevState.some((prevFile) => prevFile.name === currentFileName)
            : false;

          const isTerminationFile = currentFileName?.toLowerCase().includes('termination');
          const isOfficialTravelFile = currentFileName?.toLowerCase().includes('službeni_put');

          if (doesFileExist) {
            return prevState.map((prevFile) => {
              if (prevFile.name === currentFileName && fileExtension === 'pdf') {
                return {
                  ...prevFile,
                  thumbUrl: prevFile.response.key.length ? '/other.png' : '/pdf.png',
                  response: {
                    ...prevFile.response,
                    keyPDF: attachment.location,
                  },
                };
              }

              if (prevFile.name === currentFileName && fileExtension !== 'pdf') {
                return {
                  ...prevFile,
                  thumbUrl: prevFile.response.keyPDF.length ? '/other.png' : '/docx.png',
                  response: {
                    ...prevFile.response,
                    key: attachment.location,
                  },
                };
              }
              return prevFile;
            });
          }

          return [
            ...prevState,
            {
              uid: (index + 1).toString(),
              name: attachment.location ? currentFileName : attachment.originalName,
              requestId: attachment.requestId ? attachment.requestId : null,
              status: isTerminationFile ? 'termination' : isOfficialTravelFile ? 'officialTravel' : 'done',
              thumbUrl,
              response: {
                key: fileExtension !== 'pdf' ? attachment.location : '',
                keyPDF: fileExtension === 'pdf' ? attachment.location : '',
              },
            },
          ];
        });
      });
    }
  }, [fileList.length, findedSalary, setFileList]);

  // Adding hr documents to fileList array
  if (documents && documents.data && hrDocumentsList.length === 0 && selectedMonth) {
    const monthForRequestsDocuments = moment().month(selectedMonth).format('MM');
    documents.data.map((document, index) => {
      if (document.originalname) {
        let documentName = document.originalname;
        let thumbUrl;
        switch (documentName[documentName.length - 1]) {
          case 'pdf':
            thumbUrl = '/pdf.png';
            break;
          case 'docx':
            thumbUrl = '/docx.png';
            break;
          case 'png':
            thumbUrl = '/png.png';
            break;
          case 'jpg':
            thumbUrl = '/jpg.png';
            break;
          case 'xlsx':
            thumbUrl = '/xlsx.png';
            break;

          default:
            thumbUrl = '/other.png';
            break;
        }
        if (
          document?.dateFrom &&
          document?.dateTo &&
          ((document?.dateFrom[3].includes(monthForRequestsDocuments[0]) &&
            document?.dateFrom[4].includes(monthForRequestsDocuments[1])) ||
            (document?.dateTo[3].includes(monthForRequestsDocuments[0]) &&
              document?.dateTo[4].includes(monthForRequestsDocuments[1])))
        ) {
          setHrDocumentsList((prevState) => [
            ...prevState,
            {
              uid: (index + 1).toString(),
              name: document.originalname,
              requestId: document.requestId ? document.requestId : null,
              status: 'done',
              thumbUrl,
              response: {
                key: document.location,
              },
            },
          ]);
        } else if (
          document.dateBonus &&
          document?.dateBonus[3].includes(monthForRequestsDocuments[0]) &&
          document?.dateBonus[4].includes(monthForRequestsDocuments[1])
        ) {
          setHrDocumentsList((prevState) => [
            ...prevState,
            {
              uid: (index + 1).toString(),
              name: document.originalname,
              requestId: document.requestId ? document.requestId : null,
              status: 'done',
              thumbUrl,
              response: {
                key: document.location,
              },
            },
          ]);
        } else if (
          document.dateOvertime &&
          document?.dateOvertime[3].includes(monthForRequestsDocuments[0]) &&
          document?.dateOvertime[4].includes(monthForRequestsDocuments[1])
        ) {
          setHrDocumentsList((prevState) => [
            ...prevState,
            {
              uid: (index + 1).toString(),
              name: document.originalname,
              requestId: document.requestId ? document.requestId : null,
              status: 'done',
              thumbUrl,
              response: {
                key: document.location,
              },
            },
          ]);
        }
      }
    });
  }

  const openCommentModal = () => {
    setShowCommentModal(true);
  };

  const resetCommentField = () => {
    if (findedSalary && findedSalary.length !== 0 && findedSalary[0].comment && !commentField) {
      form.setFieldsValue({ comment: findedSalary[0].comment });
    } else if (commentField) {
      form.setFieldsValue({ comment: commentField });
    } else {
      form.setFieldsValue({ comment: undefined });
    }
    setShowCommentModal(false);
  };

  // this function and below useEffect are for setting custom work hours for multimarking mode
  const [workTimeEmployee, setWorkTimeEmployee] = useState(0);
  const handleMultimarkingCustomTime = (multimarkingChecked) => {
    if (
      multimarkingChecked &&
      employee &&
      employee.data &&
      employee.data.items &&
      employee.data.items[0].workTimeType
    ) {
      if (employee.data.items[0].workTimeType === 'halfTime') setWorkTimeEmployee(4);
      if (employee.data.items[0].workTimeType === 'fullTime') setWorkTimeEmployee(8);
      if (employee.data.items[0].workTimeType === 'customTime')
        setWorkTimeEmployee((8 * employee.data.items[0].workTimeCustomType) / 100);
    } else {
      setWorkTimeEmployee(8);
    }
  };

  useEffect(() => {
    const minutes = workTimeEmployee * 60;
    const hours = Math.floor(minutes / 60);
    const minutesRemaining = minutes % 60;
    form.setFieldsValue({
      workHours: moment().set('hours', hours).set('minutes', minutesRemaining),
      workType: undefined,
      overTime: undefined,
    });

    if (minutesRemaining === 0) {
      setWorkHours(`0${hours}:${minutesRemaining}0`);
    } else {
      setWorkHours(`0${hours}:${minutesRemaining}`);
    }

    return () => {};
  }, [form, setWorkTimeEmployee, workTimeEmployee]);

  // this checks if end date of employment is within current year
  // if yes numberOfHolidayDaysByEndOfContract form field is displayed instead of numberOfHolidayDays
  const currentYear = new Date().getFullYear();
  let limitedEmployment = false;
  if (
    employee &&
    employee.data.items[0].endDate &&
    new Date(employee.data.items[0].endDate).getFullYear() === currentYear
  ) {
    limitedEmployment = true;
  }

  const createRequestForWorkType = (typeOfLeave, selectedDateLocal) => {
    const isHrCompany = employee.data.items[0].company.isHr;
    const companyDocsSettings = chosenCompany[0].companyDocsSettings;
    const isWorkTypeSuitableForModal = typeOfLeave === 'Non-paid leave' && companyDocsSettings?.nonPaidLeave;
    const isWorkTypeSuitableForRequest =
      (typeOfLeave === 'Holiday leave' && companyDocsSettings?.holidayLeave) ||
      // TODO uncomment this upon implementation
      // typeOfLeave === 'Pregnancy leave' ||
      typeOfLeave === 'Maternity leave' ||
      (typeOfLeave?.startsWith('Paid') && companyDocsSettings?.paidLeave);

    if (!isHrCompany && !isWorkTypeSuitableForModal && !isWorkTypeSuitableForRequest) {
      return 'ok';
    }

    if (isWorkTypeSuitableForRequest && !isWorkTypeSuitableForModal) {
      const array = [...requestModalData];
      const newObj = {
        typeOfLeave,
        startDate: selectedDateLocal,
      };
      array.push(newObj);
      setRequestModalData(array);
      setRequestModalVisible(false);
    } else if (!isWorkTypeSuitableForRequest && isWorkTypeSuitableForModal) {
      setSelectedWorkTypeForModal(typeOfLeave);
      setSelectedDateLocalForModal(selectedDateLocal);
      setRequestModalVisible(true);
    } else {
      setRequestModalVisible(false);
    }
  };

  const createRequestForWorkTypeDateRange = (workType, startDate, endDate, numOfDays) => {
    const isHrCompany = employee.data.items[0].company.isHr;

    if (!isHrCompany) {
      return 'ok';
    }
    const array = [...requestModalData];

    // this eliminates clicked dates in calendar which are already among dates from time range calendar picker
    const rangeStart = moment(startDate).startOf('day').format('YYYY-MM-DD');
    const rangeEnd = moment(endDate).endOf('day').format('YYYY-MM-DD');
    const filteredArray = array.filter((item) => {
      const itemDate = moment(item.startDate).startOf('day');
      return !itemDate.isBetween(rangeStart, rangeEnd, 'day', '[]');
    });

    const requestObject = {
      workType,
      startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'),
      endDate: moment(endDate).startOf('day').format('YYYY-MM-DD'),
      numOfDays,
    };

    filteredArray.push(requestObject);
    setRequestModalData(filteredArray);
    setRequestModalVisible(false);
  };

  const handleOkRequestModal = (values) => {
    const array = [...requestModalData];
    const newObj = { ...values };
    array.push(newObj);
    setRequestModalData(array);
    setRequestModalVisible(false);
  };

  const handleCancelRequestModal = () => {
    setRequestModalVisible(false);
  };

  useEffect(() => {
    const shouldShowRemoveIconForAnyFile = fileList.some((file) => !!file.requestId);
    setShowUploadList((prev) => ({
      ...prev,
      showRemoveIcon: !shouldShowRemoveIconForAnyFile,
    }));
  }, [fileList]);

  // start of drawer functionalities
  const handleEmployeeDrawer = (isOpen) => {
    setIsEmployeeDrawerOpen(isOpen);
  };

  const onClose = () => {
    setCustomDayDrawer(false);
  };

  const showChildrenDrawer = () => {
    setCustomChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setCustomChildrenDrawer(false);
  };

  const showChildrenCustomDayDrawer = () => {
    setMultiMarkingCustomMode(false);
    setCustomSingleDayChildrenDrawer(true);
    setCustomDay(true);
  };

  const onChildrenSingleDayDrawerClose = () => {
    setCustomSingleDayChildrenDrawer(false);
    setCustomDay(false);
  };

  const handlePatternCreation = () => {
    setIsSelected(false);
    // this resets the value of selectedDate
    setSelectedDate(selectedYear);
    setMultiMarkingCustomMode(true);
    setCustomChildrenDrawer(false);
    setCustomDayDrawer(false);
  };

  const handleSingleCreation = () => {
    setIsSelected(false);
    setSelectedDate(selectedYear);
    setMultiMarkingCustomMode(false);
    setCustomSingleDayChildrenDrawer(false);
    setCustomDayDrawer(false);
  };
  // end of drawer functionalities

  // start of date range functionalities for requests
  useEffect(() => {
    const selectedWorkType = form.getFieldValue('workType');
    if (selectedWorkType) {
      setTimeRangeCalendarDisabled(false);
    } else {
      setTimeRangeCalendarDisabled(true);
    }
  }, [form.getFieldValue('workType')]);

  const checkPublicHolidayDaysForDisabledCalendar = async (employeeId) => {
    try {
      const dataForDisabling = await Axios.get(`${SERVER_URL}/check-public-holidays-timesheet/${employeeId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setDatesForDisabling(dataForDisabling.data.items);
    } catch (error) {
      console.error('error in check-public-holidays-timesheet', error);
    }
  };

  useEffect(() => {
    if (employee?.data?.items[0]?._id) {
      checkPublicHolidayDaysForDisabledCalendar(employee?.data?.items[0]?._id);
    }
  }, [employee]);

  const setRangePickerValue = (value) => {
    setInitialEndDate(value[1]);
    let countDays;
    let firstDayBack;
    if (value && value.length > 0) {
      let start = value[0];
      let startWhile = value[0];
      let end = value[1];
      countDays = 0;
      while (startWhile <= end) {
        if (
          startWhile.format('E') !== '6' &&
          startWhile.format('E') !== '7' &&
          !holidays.some((holiday) => startWhile.isSame(holiday, 'day'))
        ) {
          countDays++;
        }
        startWhile = moment(startWhile, 'YYYY-MM-DD').add(1, 'days');
        setNumOfDays(countDays);
      }
      end.format('E') === '5'
        ? (firstDayBack = moment(end, 'YYYY-MM-DD').add(3, 'days'))
        : (firstDayBack = moment(end, 'YYYY-MM-DD').add(1, 'days'));

      setStartDate(start.format('L'));
      setEndDate(end.format('L'));
    }
  };

  // helper function to check if date is holiday
  const isHoliday = (date) => {
    return holidaysArray.some((holiday) => holiday.isSame(date, 'day'));
  };

  const changeDateRangeData = (start, end) => {
    setCustomDay(false);
    let calendarData = localStorage.getItem('calendarData') ? JSON.parse(localStorage.getItem('calendarData')) : [];
    const employeeWorkingTime = employee?.data?.items[0]?.workTimeType;
    const startDate = moment(start, 'DD/MM/YYYY');
    const endDate = moment(end, 'DD/MM/YYYY');
    let workingHours = 8;

    if (employeeWorkingTime) {
      if (employeeWorkingTime === 'halfTime') setWorkTimeEmployee(4);
      if (employeeWorkingTime === 'fullTime') setWorkTimeEmployee(8);
      if (employeeWorkingTime === 'customTime') workingHours = (8 * employeeWorkingTime) / 100;
      const minutes = workingHours * 60;
      const hours = Math.floor(minutes / 60);
      const minutesRemaining = minutes % 60;
      form.setFieldsValue({
        workHours: moment().set('hours', hours).set('minutes', minutesRemaining),
        workType: undefined,
        overTime: undefined,
      });

      if (minutesRemaining === 0) {
        workingHours = `0${hours}:${minutesRemaining}0`;
      } else {
        workingHours = `0${hours}:${minutesRemaining}`;
      }
    }

    if (!startDate.isValid() || !endDate.isValid()) {
      console.error('Invalid date format');
      return;
    }

    let arrayOfNewDates = [];

    for (let current = startDate.clone(); current.isSameOrBefore(endDate, 'day'); current.add(1, 'day')) {
      let isFound = false;
      for (let data of calendarData) {
        if (
          data.employee === selectedEmployee &&
          moment(data.selectedDate).isSame(current, 'day') &&
          current.day() !== 0 &&
          current.day() !== 6 &&
          !isHoliday(current)
        ) {
          isFound = true;
          data.hours = workingHours;
          data.hours2 = '00:00';
          data.workType = workType ? workType : data.workType;
          data.overTime = overTime ? moment(overTime).format('HH:mm') : '00:00';
          arrayOfNewDates.push(data);
          break;
        }
      }
      if (!isFound && current.day() === 0 && current.day() === 6 && isHoliday(current)) {
        form.setFieldsValue({
          workHours: undefined,
          workType: undefined,
          overTime: undefined,
          overTime2: undefined,
        });
        let data = {
          employee: selectedEmployee,
          selectedDate: current.format('YYYY-MM-DD'),
          hours: form.getFieldValue('workHours') ? moment(form.getFieldValue('workHours')).format('HH:mm') : '00:00',
          workType: form.getFieldValue('workType') ? form.getFieldValue('workType') : '',
          overTime: form.getFieldValue('overTime') ? moment(form.getFieldValue('overTime')).format('HH:mm') : '00:00',
          overTime2: form.getFieldValue('overTime2')
            ? moment(form.getFieldValue('overTime2')).format('HH:mm')
            : '00:00',
        };
        calendarData.push(data);
      } else if (!isFound && current.day() !== 0 && current.day() !== 6 && !isHoliday(current)) {
        let data = {
          employee: selectedEmployee,
          selectedDate: current.format('YYYY-MM-DD'),
          hours: workingHours,
          workType: workType ? workType : '',
          overTime: overTime ? moment(overTime).format('HH:mm') : '00:00',
          overTime2: '00:00',
        };
        arrayOfNewDates.push(data);
        calendarData.push(data);
      }
    }

    localStorage.setItem('calendarData', JSON.stringify(calendarData));
    createRequestForWorkTypeDateRange(workType, startDate, endDate, numOfDays, arrayOfNewDates);
  };

  useEffect(() => {
    if (dateRangeSubmitted && startDate && endDate && numOfDays > 0) {
      changeDateRangeData(startDate, endDate);
      resetRangeCalendar();
    }
  }, [dateRangeSubmitted]);
  // end of date range functionalities for requests

  return (
    <div className='panel panel-body timesheetStyles salary-form__wrapper'>
      <div className='panel-body timesheetStyles salary-form__inner-wrapper'>
        {employee && employee.data && employee.data.items && (
          <Form
            className='form-horizontal timesheetStyles salary-form__form-wrapper'
            layout={windowWidth > 1270 ? 'horizontal' : 'vertical'}
            initialValues={initialValues}
            onFinish={(values) => {
              const icons = document.getElementsByClassName('anticon');
              for (var i = 0; i < icons.length; i++) {
                var currentEl = icons[i];
                currentEl.style.pointerEvents = 'none';
              }
              onFinish(values);
            }}
            form={form}
            key={randomKeyForm}
          >
            <Row>
              <div id='salaryCalendar' className='panel-body timesheetStyles  salary-form-row__wrapper'>
                <Col xs={24} md={24}>
                  {showCalendar && (
                    <div style={{ display }}>
                      <div className='calendar-upper-header__wrapper'>
                        <div className='top-section-name__wrapper'>
                          <span className='full-name__wrapper'>
                            <span className='full-name__element'>
                              <Tooltip
                                title={
                                  <>
                                    {employee?.data?.items[0]?.firstName} {employee?.data?.items[0]?.lastName}
                                  </>
                                }
                                color='#b1b1b1'
                                placement='top'
                                autoAdjustOverflow={true}
                              >
                                {employee?.data?.items[0]?.firstName} {employee?.data?.items[0]?.lastName}
                              </Tooltip>
                            </span>
                            <span className='holidays__section'>
                              {chosenCompany &&
                              chosenCompany[0] &&
                              chosenCompany[0].isHr &&
                              chosenCompany[0].holidayDaysTracker
                                ? `Holiday days: ${!limitedEmployment ? employee.data.items[0].numberOfHolidayDays : employee.data.items[0].numberOfHolidayDaysByEndOfContract}`
                                : ''}
                            </span>
                          </span>
                          <span className='hidden-input__wrapper'>
                            {chosenCompany &&
                              chosenCompany[0] &&
                              chosenCompany[0].isHr &&
                              chosenCompany[0].holidayDaysTracker && (
                                <Form.Item
                                  label='Available holiday days:'
                                  name={
                                    !limitedEmployment ? 'numberOfHolidayDays' : 'numberOfHolidayDaysByEndOfContract'
                                  }
                                  className='firstOverTime timesheetStyles'
                                  labelCol={{ span: 10 }}
                                  labelAlign='left'
                                >
                                  <Input className='input__element' disabled />
                                </Form.Item>
                              )}
                          </span>
                        </div>
                        {/* TODO: Add items here */}
                        <div className='calendar-configuration__wrapper'>
                          {showCalendar && !multiMarkingCustomMode && (
                            <>
                              <Form.Item
                                label='Type of work'
                                name='workType'
                                className={`workField salaryFormWorkField timesheetStyles calendar-form-item__wrapper calendar-type-of-work__wrapper ${customDay ? 'display-none' : 'display-block'}`}
                              >
                                <TreeSelect
                                  disabled={!isSelected}
                                  size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                                  treeDefaultExpandAll
                                  onChange={(value) => {
                                    if (multimarking) {
                                      setWorkType(value);
                                    } else {
                                      onChangeWorkType(value);
                                    }
                                  }}
                                >
                                  {workTypeList.length !== 0 && workTypeList}
                                </TreeSelect>
                              </Form.Item>
                              <Form.Item
                                name='workHours'
                                label='Work hours'
                                className={`workField salaryFormWorkField timesheetStyles calendar-form-item__wrapper ${customDay ? 'display-none' : 'display-block'}`}
                              >
                                <TimePicker
                                  disabled={multimarking || !isSelected}
                                  onChange={(value) => {
                                    if (multimarking) {
                                      setWorkHours(moment(value).format('HH:mm'));
                                    } else {
                                      onChangeWorkHours(value);
                                    }
                                  }}
                                  className='time-picker__element work-hours__element'
                                  onClick={() => !multimarking && form.setFieldsValue({ workHours: undefined })}
                                  format='HH:mm'
                                  showNow={false}
                                  hideDisabledOptions={true}
                                  allowClear={false}
                                  size={window.innerHeight < 800 && window.innerWidth < 1600 ? 'small' : 'middle'}
                                />
                              </Form.Item>
                              <Form.Item
                                label='Overtime work'
                                name='overTime'
                                className='workField firstOverTime timesheetStyles calendar-form-item__wrapper'
                                labelAlign='left'
                              >
                                <div className='salaryFormInput timesheetStyles'>
                                  <TimePicker
                                    key={timePickerKey}
                                    className='time-picker__element'
                                    onChange={(value) => {
                                      if (multimarking) {
                                        setOverTime(value);
                                        setTimePickerKey(timePickerKey + 1);
                                      } else {
                                        onChangeOverTime(value);
                                      }
                                    }}
                                    format='HH:mm'
                                    showNow={false}
                                    hideDisabledOptions={true}
                                    allowClear={true}
                                  />
                                </div>
                              </Form.Item>
                            </>
                          )}
                          <div className='calendar-header-checkboxes__wrapper'>
                            <div className='non-multimarked__wrapper'>
                              {!multiMarkingCustomMode && (
                                <Form.Item
                                  name='customDayMode'
                                  className='customDay customDaySalaryForm timesheetStyles form-item-wrapper'
                                >
                                  <Checkbox
                                    checked={customDay}
                                    disabled={!isSelected}
                                    onChange={(event) => {
                                      setCustomDayDrawer(!customDayDrawer);
                                      setCustomDay(false);
                                      if (!event.target.checked) {
                                        form.setFieldsValue({
                                          workHours2: undefined,
                                          workType2: undefined,
                                        });
                                      } else {
                                        if (multimarking) {
                                          setMultimarking(false);
                                          form.setFieldsValue({
                                            multimarkingMode: false,
                                            workType2: undefined,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    <span>Custom day</span>
                                    <Tooltip
                                      title='User wants to record 2 different types of work within the same day (not including overtime work).'
                                      color='#b1b1b1'
                                      placement='bottom'
                                      autoAdjustOverflow={true}
                                    >
                                      <QuestionCircleOutlined
                                        className='tooltip-body-styling__element'
                                        style={{ marginLeft: '3px' }}
                                      />
                                    </Tooltip>
                                  </Checkbox>
                                </Form.Item>
                              )}

                              {multiMarkingCustomMode && (
                                <Form.Item
                                  name='customDayMode'
                                  className='customDay customDaySalaryForm timesheetStyles'
                                >
                                  <Checkbox
                                    checked={multiMarkingCustomMode}
                                    disabled={!isSelected}
                                    onChange={(event) => {
                                      setMultiMarkingCustomMode(false);
                                    }}
                                  >
                                    <span>Multimarking custom day</span>
                                    <Tooltip
                                      title='User wants to record 2 different types of work within the same day (not including overtime work).'
                                      color='#b1b1b1'
                                      placement='bottom'
                                      autoAdjustOverflow={true}
                                    >
                                      <QuestionCircleOutlined
                                        className='tooltip-body-styling__element'
                                        style={{ marginLeft: '3px' }}
                                      />
                                    </Tooltip>
                                  </Checkbox>
                                </Form.Item>
                              )}
                            </div>

                            {!multiMarkingCustomMode && (
                              <div className='multimarkingForm timesheetStyles'>
                                <Form.Item
                                  name='multimarkingMode'
                                  valuePropName='checked'
                                  className='customDaySalaryForm'
                                >
                                  <Checkbox
                                    disabled={moment(selectedDate).day() === 0 || moment(selectedDate).day() === 6}
                                    onChange={(event) => {
                                      setMultimarking(event.target.checked);
                                      setWorkType(undefined);
                                      setOverTime(undefined);
                                      handleMultimarkingCustomTime(event.target.checked);
                                      if (!event.target.checked) {
                                        form.setFieldsValue({
                                          workHours: undefined,
                                          workType: undefined,
                                          overTime: undefined,
                                        });
                                      } else {
                                        if (customDay) {
                                          setCustomDay(false);
                                          form.setFieldsValue({
                                            customDayMode: false,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    Multimarking
                                    <Tooltip
                                      title='When multiple days shall be marked with same type of work or with same overtime hours
                              (excluding weekend).'
                                      color='#b1b1b1'
                                      placement='bottom'
                                      autoAdjustOverflow={true}
                                    >
                                      <QuestionCircleOutlined
                                        className='tooltip-body-styling__element'
                                        style={{ marginLeft: '3px' }}
                                      />
                                    </Tooltip>
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            )}
                            <div className='multimarkingForm timesheetStyles'>
                              <Form.Item name='timeRange' valuePropName='checked' className='customDaySalaryForm'>
                                <Checkbox
                                  disabled={timeRangeCalendarDisabled}
                                  onChange={() => {
                                    setMultimarking(false);
                                    form.setFieldsValue({
                                      multimarkingMode: false,
                                      workType2: undefined,
                                    });
                                    setRangeCalendarVisible(!rangeCalendarVisible);
                                  }}
                                >
                                  Leave extender
                                  <Tooltip
                                    title='to be used for creation of decisions that start in one month and end in next month.'
                                    color='#b1b1b1'
                                    placement='bottom'
                                    autoAdjustOverflow={true}
                                  >
                                    <QuestionCircleOutlined
                                      className='tooltip-body-styling__element'
                                      style={{ marginLeft: '3px' }}
                                    />
                                  </Tooltip>
                                </Checkbox>
                              </Form.Item>
                            </div>
                            {!multiMarkingCustomMode && !customDay ? (
                              <div className='multimarkingForm timesheetStyles terminate-extend__wrapper'>
                                <Dropdown overlay={menu}>
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a onClick={(e) => e.preventDefault()}>
                                    <Space className='dropdown-space__element'>
                                      {employee?.data?.items[0]?.employmentType === 'limited'
                                        ? 'Terminate/Extend'
                                        : 'Terminate'}
                                      <UserOutlined />
                                    </Space>
                                  </a>
                                </Dropdown>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className='timesheetStyles calendar-header-buttons__wrapper'>
                          <Button
                            type='primary'
                            htmlType='submit'
                            onClick={() => form.submit()}
                            className='salarySubmitButton calendar-header-btn__element'
                            disabled={submitTimesheetDisabled || submitClicked}
                          >
                            Submit
                          </Button>
                          <div className='btn-menu__wrapper'>
                            <Button
                              type='ghost'
                              id='salaryDrawerButton'
                              htmlType='button'
                              className='salarySubmitButton timesheetStyles  calendar-header-btn__element calendar-header-menu__element'
                              onClick={() => {
                                handleEmployeeDrawer(true);
                              }}
                            >
                              <MenuFoldOutlined />
                            </Button>
                            <span className='btn-menu-helper__element'>Menu</span>
                          </div>
                        </div>
                      </div>

                      {/* Calendar Here */}
                      {showCalendar && (
                        <Form.Item>
                          <Calendar
                            key={randomKeyCalendar}
                            className='salaryCalendar timesheetStyles'
                            disabledDate={disabledDate}
                            onSelect={handleSelect}
                            dateCellRender={dateCellRender}
                            onChange={(value) => setSingleMonth(moment(value).format('MMMM'))}
                            value={calendarValue}
                          />
                        </Form.Item>
                      )}
                    </div>
                  )}
                </Col>
              </div>
            </Row>
            <EmployeeSalaryDrawer
              selectedEmployee={selectedEmployee}
              employee={employee}
              fileList={fileList}
              showCalendar={showCalendar}
              selectedType={selectedType}
              selectedTypes={selectedTypes}
              findedSalary={findedSalary}
              files={files}
              isEmployeeDrawerOpen={isEmployeeDrawerOpen}
              hrDocumentsList={hrDocumentsList}
              changeFormData={changeFormData}
              showUploadList={showUploadList}
              employeeReq={employeeReq}
              currentuser={currentuser}
              chosenCompany={chosenCompany[0]}
              setSelectedType={setSelectedType}
              setSelectedTypes={setSelectedTypes}
              setFiles={setFiles}
              setFileList={setFileList}
              handleIsDrawerOpen={handleEmployeeDrawer}
              openCommentModal={openCommentModal}
              dateOfCreation={dateOfCreation}
              editedReasonOfSuspension={editedReasonOfSuspension}
              editedReasonOfSuspensionEng={editedReasonOfSuspensionEng}
              suspensionAmount={suspensionAmount}
              suspensionCurrency={suspensionCurrency}
              setDateOfCreation={setDateOfCreation}
              setEditedReasonOfSuspension={setEditedReasonOfSuspension}
              setEditedReasonOfSuspensionEng={setEditedReasonOfSuspensionEng}
              setSuspensionAmount={setSuspensionAmount}
            />
            {isTerminateEmployeeModalOpen && employee.data.items[0] && !!chosenCompany.length && selectedDate ? (
              <TerminateEmployeeModal
                open={isTerminateEmployeeModalOpen}
                employee={employee.data.items[0]}
                company={chosenCompany[0]}
                selectedDate={selectedDate}
                handleSuccess={handleRefetchEmployeeData}
                handleClose={() => {
                  handleCloseTerminateModal();
                }}
              />
            ) : null}
            {isExtendEmploymentModalOpen && !!Object.keys(employee.data.items[0]).length && !!chosenCompany.length ? (
              <ExtendEmploymentModal
                open={isExtendEmploymentModalOpen}
                employee={employee.data.items[0]}
                company={chosenCompany[0]}
                selectedDate={selectedDate}
                handleClose={handleCloseExtendEmploymentModal}
              />
            ) : null}
            <Modal
              open={showCommentModal}
              title={!form.getFieldValue('comment') ? 'Add comment' : 'Edit comment'}
              onCancel={resetCommentField}
              footer={[
                <Button className='comment-modal-btn__element' key='cancel' type='danger' onClick={resetCommentField}>
                  Cancel
                </Button>,
                <Button
                  type='primary'
                  onClick={() => {
                    setCommentField(form.getFieldValue('comment'));
                    setShowCommentModal(false);
                  }}
                >
                  {!form.getFieldValue('comment') ? 'Add' : 'Edit'}
                </Button>,
              ]}
            >
              <Form.Item name='comment' label='Comment'>
                <TextArea rows={4}></TextArea>
              </Form.Item>
            </Modal>
            <Modal
              open={rangeCalendarVisible}
              key='dateRangeTimesheet'
              title='Date range calendar'
              onCancel={() => resetRangeCalendar()}
              footer={[
                <Button
                  className='comment-modal-btn__element'
                  key='dateRangeTimesheetCancel'
                  type='danger'
                  onClick={() => {
                    resetRangeCalendar();
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  type='primary'
                  key='dateRangeTimesheetCreate'
                  onClick={() => {
                    setDateRangeSubmitted(true);
                    setRangeCalendarVisible(false);
                  }}
                >
                  Create
                </Button>,
              ]}
            >
              <RangePicker
                disabledDate={(current) => disabledDateRangePicker(current, selectedMonth.toLowerCase())}
                value={[moment(selectedDate), initialEndDate ? moment(initialEndDate) : '']}
                disabled={[true, false]}
                onChange={(val) => setRangePickerValue(val)}
                format={'DD-MM-YYYY'}
                style={{ marginLeft: '15px' }}
                defaultPickerValue={[moment(selectedDate)]}
              />
            </Modal>

            <RequestModal
              open={requestModalVisible}
              handleOkRequestModal={handleOkRequestModal}
              companyTypeOfHR={employee?.data?.items[0]?.company?.typeOfHr}
              typeOfLeave={selectedWorkTypeForModal}
              handleCancelRequestModal={handleCancelRequestModal}
              selectedDateLocalForModal={selectedDateLocalForModal}
            />
          </Form>
        )}
        <CustomDrawer
          onClose={onClose}
          customDayDrawer={customDayDrawer}
          showChildrenDrawer={showChildrenDrawer}
          onChildrenDrawerClose={onChildrenDrawerClose}
          showChildrenCustomDayDrawer={showChildrenCustomDayDrawer}
          onChildrenSingleDayDrawerClose={onChildrenSingleDayDrawerClose}
          customChildrenDrawer={customChildrenDrawer}
          customSingleDayChildrenDrawer={customSingleDayChildrenDrawer}
          setWorkType1FromDrawer={setWorkType1FromDrawer}
          workTypeList={workTypeList}
          setCustom2HoursFromDrawer={setCustom2HoursFromDrawer}
          handlePatternCreation={handlePatternCreation}
          setCustom1HoursFromDrawer={setCustom1HoursFromDrawer}
          setWorkType2FromDrawer={setWorkType2FromDrawer}
          handleSingleCreation={handleSingleCreation}
          workType1FromDrawer={workType1FromDrawer}
          workType2FromDrawer={workType2FromDrawer}
          custom1HoursFromDrawer={custom1HoursFromDrawer}
          custom2HoursFromDrawer={custom2HoursFromDrawer}
          randomKey={randomKeyForm}
        />
      </div>
    </div>
  );
};

export default SalaryForm;
