import React, { useState } from 'react';
import { Form, Radio, Typography, Divider } from 'antd';
import FunctionaryAndOwnershipDrawer from '../FunctionaryAndOwnershipDrawer.js';

const { Title } = Typography;

const FunctionaryAndOwnershipForm = ({ data, user, SERVER_URL }) => {
  const [form] = Form.useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const [functionaryAndOwnershipDrawer, setFunctionaryAndOwnershipDrawer] = useState(false);

  const options = [
    { label: 'Client Questionnaire - SPNFT 1', value: 'questionnarie' },
    { label: 'Statement of Functionary - SPNFT 2', value: 'functionary' },
    { label: 'Statement of Ownership - SPNFT 3', value: 'ownership' },
  ];

  const onChange = (e) => {
    setSelectedValue(e.target.value);
    form.setFieldsValue({ documentType: e.target.value });
    setFunctionaryAndOwnershipDrawer(true);
  };

  const onClose = () => {
    setFunctionaryAndOwnershipDrawer(false);
    form.setFieldsValue({ documentType: null });
  };

  return (
    <div
      style={{
        margin: '20px',
        maxWidth: '900px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        padding: '20px',
        backgroundColor: '#fff',
      }}
    >
      <Title level={3} style={{ textAlign: 'left' }}>
        Functionary and Ownership Documents (SPNFT)
      </Title>
      <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
      <Form form={form} layout='vertical'>
        <Form.Item
          name='documentType'
          label='Type of document'
          rules={[
            {
              required: true,
              message: 'You have to choose one!',
            },
          ]}
        >
          <Radio.Group
            onChange={onChange}
            value={selectedValue}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
          >
            {options.map((option) => (
              <Radio key={option.value} value={option.value} style={{ margin: '5px 0' }}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
      </Form>
      <FunctionaryAndOwnershipDrawer
        onClose={onClose}
        open={functionaryAndOwnershipDrawer}
        type={selectedValue}
        data={data}
        SERVER_URL={SERVER_URL}
        user={user}
      />
    </div>
  );
};

export default FunctionaryAndOwnershipForm;
