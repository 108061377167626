import React, { memo } from 'react';
import { SERVER_URL } from '../../config';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

function UploadFiles({ token, company, handleRefetchRequest }) {
  const props = {
    name: 'imageFiles',
    multiple: true,
    accept:
      'image/jpeg,image/png,image/jpg,image/gif,image/bmp,image/webp,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    action: `${SERVER_URL}/upload-questionnarie-docs-s3?company=${encodeURIComponent(
      JSON.stringify({
        companyId: company._id,
        name: company.name,
        pib: company.pib,
      }),
    )}`,
    headers: {
      Authorization: `Bearer ${token}`,
      uri: 'static/pdf/',
      timestamp: Date.now().toString(),
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5; // 5MB
      if (!isLt5M) {
        message.error('File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} uploaded successfully.`);

        if (handleRefetchRequest) {
          handleRefetchRequest();
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  if (!company) {
    return (
      <div
        style={{
          padding: '20px',
          textAlign: 'center',
          border: '1px solid #ddd',
          borderRadius: '8px',
          backgroundColor: '#fafafa',
          color: '#999',
          fontSize: '16px',
        }}
      >
        <p>No user data available.</p>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', maxWidth: '700px', margin: '0 auto' }}>
      <Dragger
        {...props}
        style={{
          border: '2px dashed #1890ff',
          borderRadius: '10px',
          padding: '20px',
          backgroundColor: '#f0f8ff',
          transition: 'border-color 0.3s, box-shadow 0.3s',
        }}
      >
        <p
          className='ant-upload-drag-icon'
          style={{
            fontSize: '48px',
            color: '#1890ff',
          }}
        >
          <InboxOutlined />
        </p>
        <p
          className='ant-upload-text'
          style={{
            fontSize: '18px',
            fontWeight: '500',
            color: '#333',
            marginBottom: '8px',
          }}
        >
          Click or drag files to this area to upload
        </p>
        <p
          className='ant-upload-hint'
          style={{
            color: '#666',
            fontSize: '14px',
            marginBottom: '4px',
          }}
        >
          Supports single or multiple file uploads.
        </p>
        <p
          className='ant-upload-hint'
          style={{
            color: '#999',
            fontSize: '14px',
            marginBottom: '4px',
          }}
        >
          Allowed file types: PDF, DOC, DOCX, JPEG, PNG, JPG, GIF, BMP, WEBP
        </p>
        <p
          className='ant-upload-hint'
          style={{
            color: '#999',
            fontSize: '14px',
          }}
        >
          Maximum file size: 5MB
        </p>
      </Dragger>

      {/* Custom styles for upload list */}
      <style>
        {`
            .ant-upload-list-text .ant-upload-span,
            .ant-upload-list-picture .ant-upload-span {
              justify-content: space-between;
            }
        
            .ant-upload-list {
              margin-top: 20px;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 8px;
              background-color: #fafafa;
            }
        
            .ant-upload-list-item {
              padding: 10px 15px;
              border-radius: 6px;
              background-color: #fff;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              margin-bottom: 10px;
              transition: transform 0.2s;
            }
        
            .ant-upload-list-item:hover {
              transform: scale(1.02);
              background-color: #f5f5f5;
            }
        
            .ant-upload-list-item-name {
              font-size: 14px;
              font-weight: 500;
              color: #333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 400px;
            }
        
            .ant-upload-list-item-actions {
              display: flex;
              align-items: center;
              gap: 8px;
            }
        
            .ant-upload-list-item-actions > .anticon {
              font-size: 18px;
              color: #1890ff;
              cursor: pointer;
            }
          `}
      </style>
    </div>
  );
}

export default memo(UploadFiles);
