import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { UserContext } from '../../App';
import ArchiveTable from '../../components/tables/ArchiveTable';
import { Tabs, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { formatEInvoiceDate } from '../../helpers/dates';

const { TabPane } = Tabs;

const RECEIVABLE_TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'type',
  'user',
  'products',
  'documentType',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'dueDate',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'customerVat',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'pdf',
  'purchaseInvoiceId',
  'invoiceId',
  'documentLanguage',
  'documentCurrency',
  'customerCompanyNumber',
  'customerPostalAddress',
  'customerCity',
  'customerZipCode',
  'customerCountry',
  'customerCounty',
  'createdAt',
  'updatedAt',
  'isForeignInvoice',
  'archived',
  'total',
  'recovered',
  'qrCode',
  'unifiedPdf',
  'documentDate',
  'status',
  'includeLogoInInvoice',
  'supplierAddress',
  'supplierCity',
  'supplierCompanyNumber',
  'supplierCountryCode',
  'supplierPostalCode',
  'type',
];

const PAYABLE_TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'paymentDate',
  'documentId',
  'creationDate',
  'sendingDate',
  'taxPointDate',
  'invoicePeriodStartDate',
  'invoicePeriodEndDate',
  'contractDocumentReference',
  'additionalDocumentReference',
  'attachment',
  'paymentMeansCode',
  'taxTotalAmount',
  'archived',
  'status',
  'customerPostalAddress',
  'dueDate',
  'recovered',
  'unifiedPdf',
  'documentDate',
  'supplyDate',
];

const Archive = () => {
  const currentuser = useContext(UserContext);
  const [tableKey, setTableKey] = useState(0);
  const [payableInvoices, setPayableInvoices] = useState(undefined);
  const [receivableInvoices, setReceivableInvoices] = useState(undefined);
  const [receivableInvoicesFull, setReceivableInvoicesFull] = useState(undefined);
  const [payableInvoicesFull, setPayableInvoicesFull] = useState(undefined);
  const [paginationPurchase, setPaginationPurchase] = useState({
    current: 1, // Default current page
    pageSize: 20, // Default page size
    total: 0, // Total count
  });
  const [paginationSales, setPaginationSales] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [tableDataPayable, setTableDataPayable] = useState([]);
  const [tableData, setTableData] = useState([]);

  const selectedEInvoiceCompanyId = (() => {
    const storedCompanyId = localStorage.getItem('selectedEInvoiceCompany');
    const companies = Array.isArray(currentuser?.data?.company)
      ? currentuser.data.company
      : [currentuser?.data?.company].filter(Boolean);
    const companyIds = companies.length > 0 ? companies.map((item) => item._id) : [];

    return companyIds.includes(storedCompanyId) ? storedCompanyId : companies.length > 0 ? companies[0]?._id : null;
  })();

  useEffect(() => {
    getPurchaseInvoices(paginationPurchase);
    getSalesInvoices(paginationSales);
    getPurchaseInvoicesFullForSearch();
    getSalesInvoicesFullForSearch();
  }, []);

  const getPurchaseInvoices = async (paginationObject) => {
    const { current, pageSize } = paginationObject;
    const filters = {
      companyId: selectedEInvoiceCompanyId,
      page: current,
      pageSize,
    };
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-purchase`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const { total } = data.data;

      setPaginationPurchase({ ...paginationObject, total });
      setPayableInvoices(data.data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  const getSalesInvoices = async (paginationSales) => {
    const { current, pageSize } = paginationSales;
    const filters = {
      companyId: selectedEInvoiceCompanyId,
      page: current,
      pageSize,
    };
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-sales`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const { total } = data.data;

      setReceivableInvoices(data.data.data);
      setPaginationSales({ ...paginationSales, total });
    } catch (err) {
      console.log('error', err);
    }
  };

  // fetch all purchase invoices in archive for search in columns
  const getPurchaseInvoicesFullForSearch = async () => {
    const filters = {
      companyId: selectedEInvoiceCompanyId,
    };
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-purchase`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setPayableInvoicesFull(data.data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  // fetch all sales invoices in archive for search in columns
  const getSalesInvoicesFullForSearch = async () => {
    const filters = {
      companyId: selectedEInvoiceCompanyId,
    };
    try {
      const data = await Axios.get(`${SERVER_URL}/get-archive-sales`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setReceivableInvoicesFull(data.data.data);
    } catch (err) {
      console.log('error', err);
    }
  };

  const checkIncomingStatus = async (id, paginationObject) => {
    try {
      const statusData = await Axios.get(
        `${SERVER_URL}/purchase-status/${id}?filter=${JSON.stringify(selectedEInvoiceCompanyId)}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      return statusData.data.status;
    } catch (error) {
      notification.error({
        message: 'Problem with checking invoice status. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      // Update the pagination to retain the current page position
      setPaginationPurchase(paginationObject);
    }
  };

  let payableColumnKeys;
  let payableNewColumnKeys;
  if (payableInvoices && payableInvoices.length > 0) {
    const payableKeys = Object.keys(payableInvoices[0]);
    payableColumnKeys = payableKeys?.filter((k) => !PAYABLE_TABLE_COLUMN_KEYS.includes(k));
    let payableAlternativeNames = [];
    for (const payableColumnKey of payableColumnKeys) {
      if (payableColumnKey === 'documentNumber') {
        payableAlternativeNames.push('Invoice No');
      } else if (payableColumnKey === 'purchaseInvoiceId') {
        payableAlternativeNames.push('SEF No');
      } else if (payableColumnKey === 'deliveryDate') {
        payableAlternativeNames.push('Delivery Date');
      } else if (payableColumnKey === 'supplierName') {
        payableAlternativeNames.push('Supplier name');
      } else if (payableColumnKey === 'archivedDate') {
        payableAlternativeNames.push('Archive Date');
      }
    }
    let keyPayableArray = payableColumnKeys;
    payableNewColumnKeys = payableColumnKeys?.map((item, index) => ({
      originalName: keyPayableArray[index],
      alternativeName: payableAlternativeNames[index],
    }));
  }

  let receivableColumnKeys;
  let receivableNewColumnKeys = [];
  if (receivableInvoices && receivableInvoices.length > 0) {
    const receivableKeys = Object.keys(receivableInvoices[0]);
    receivableColumnKeys = receivableKeys?.filter((k) => !RECEIVABLE_TABLE_COLUMN_KEYS.includes(k));
    let receivableAlternativeNames = [];
    for (const receivableColumnKey of receivableColumnKeys) {
      if (receivableColumnKey === 'documentNumber') {
        receivableAlternativeNames.push('Invoice No');
      } else if (receivableColumnKey === 'salesInvoiceId') {
        receivableAlternativeNames.push('SEF Number');
      } else if (receivableColumnKey === 'supplyDate') {
        receivableAlternativeNames.push('Supply Date');
      } else if (receivableColumnKey === 'customerName') {
        receivableAlternativeNames.push('Customer Name');
      } else if (receivableColumnKey === 'archivedDate') {
        receivableAlternativeNames.push('Archive Date');
      }
    }

    let keyReceivableArray = receivableColumnKeys;
    receivableNewColumnKeys = receivableColumnKeys?.map((item, index) => ({
      originalName: keyReceivableArray[index],
      alternativeName: receivableAlternativeNames[index],
    }));
  }

  useEffect(() => {
    if (payableInvoices && payableInvoices.length > 0) {
      const array = payableInvoices.map((item) => {
        const formattedDate = formatEInvoiceDate(item.deliveryDate);
        const archiveDateFormatted = formatEInvoiceDate(item.archivedDate);
        return { ...item, deliveryDate: formattedDate, archivedDate: archiveDateFormatted };
      });
      setTableDataPayable(array);
    }
  }, [payableInvoices]);

  useEffect(() => {
    if (receivableInvoices && receivableInvoices.length > 0) {
      const array = receivableInvoices.map((item) => {
        const formattedDate = formatEInvoiceDate(item.supplyDate);
        const archiveDateFormatted = formatEInvoiceDate(item.archivedDate);
        return { ...item, supplyDate: formattedDate, archivedDate: archiveDateFormatted };
      });
      setTableData(array);
    }
  }, [receivableInvoices]);

  // prepare table data for search in columns
  let tableDataPayableFullForSearch = [];
  if (payableInvoicesFull && payableInvoicesFull.length > 0) {
    tableDataPayableFullForSearch = payableInvoicesFull.map((item) => {
      const formattedDate = formatEInvoiceDate(item.deliveryDate);
      const archiveDateFormatted = formatEInvoiceDate(item.archivedDate);
      return { ...item, deliveryDate: formattedDate, archivedDate: archiveDateFormatted };
    });
  }

  // prepare table data for search in columns
  let tableDataFullForSearch = [];
  if (receivableInvoicesFull && receivableInvoicesFull.length > 0) {
    tableDataFullForSearch = receivableInvoicesFull.map((item) => {
      const formattedDate = formatEInvoiceDate(item.supplyDate);
      const archiveDateFormatted = formatEInvoiceDate(item.archivedDate);
      return { ...item, supplyDate: formattedDate, archivedDate: archiveDateFormatted };
    });
  }

  const handleRecoverInvoice = async (id, type) => {
    try {
      await Axios.post(
        `${SERVER_URL}/recover-archived/${id}`,
        { type: type },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      if (type === 'purchase') {
        await getPurchaseInvoices(paginationPurchase);
        await getPurchaseInvoicesFullForSearch();
      }

      if (type === 'sales') {
        await getSalesInvoices(paginationSales);
        await getSalesInvoicesFullForSearch();
      }
      notification.success({
        message: 'E-Invoice returned back to E-Invoices.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with this operation. Please, try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <Tabs>
        <TabPane tab='Archive of outgoing invoices' key={0}>
          <ArchiveTable
            columnKeys={receivableNewColumnKeys}
            dataSource={tableData}
            dataForSearch={tableDataFullForSearch}
            recoverHandler={handleRecoverInvoice}
            type={'sales'}
            handlePaginationChange={getSalesInvoices}
            paginationSales={paginationSales}
            setPaginationSales={setPaginationSales}
          />
        </TabPane>
        <TabPane tab='Archive of incoming invoices' key={1}>
          {payableInvoices && (
            <ArchiveTable
              key={tableKey}
              columnKeys={payableNewColumnKeys}
              dataSource={tableDataPayable}
              dataForSearch={tableDataPayableFullForSearch}
              recoverHandler={handleRecoverInvoice}
              type={'purchase'}
              checkIncomingStatus={checkIncomingStatus}
              handlePaginationChange={getPurchaseInvoices}
              paginationPurchase={paginationPurchase}
              setPaginationPurchase={setPaginationPurchase}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Archive;
