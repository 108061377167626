import React, { memo, useState } from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config/index.js';
import * as FileSaver from 'file-saver';
import axios from 'axios';

function FilesList({ token, sources: initialSources }) {
  const [sources, setSources] = useState(initialSources);

  const downloadQuestionnarieFile = async (token, key, originalName) => {
    const url = `${SERVER_URL}/file-questionnarie?fileKey=${key}&originalName=${originalName}`;

    try {
      const response = await axios.get(url, {
        headers: {
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      });

      FileSaver.saveAs(response.config.url, originalName);

      return response.statusText;
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  };

  const deleteQuestionnarieFileByFileKey = async (token, originalName, key) => {
    try {
      const response = await axios.delete(`${SERVER_URL}/delete-questionnarie-s3/${originalName}`, {
        headers: {
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        data: {
          key,
        },
      });

      return response.message;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };

  const downloadFile = async (originalName, key) => {
    try {
      await downloadQuestionnarieFile(token, key, originalName);
    } catch (err) {
      console.error(err);
    }
  };

  const removeFile = async (originalName, key) => {
    try {
      await deleteQuestionnarieFileByFileKey(token, originalName, key);

      setSources((prevSources) => prevSources.filter((file) => file.originalName !== originalName));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}>
      {sources && sources.length ? (
        sources.map((src, idx) => (
          <div
            key={src._id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '900px',
              padding: '10px 20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: '16px',
                fontWeight: '500',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '20px',
              }}
              title={src.originalName}
            >
              #{idx + 1} - {src.originalName}
            </p>
            <div style={{ display: 'flex', gap: '15px', marginLeft: 'auto' }}>
              <button
                type='button'
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  padding: '5px 4px 5px 8px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => downloadFile(src.originalName, src.location)}
              >
                <DownloadOutlined />
              </button>
              <Popconfirm
                title='Are you sure you want to delete this file?'
                onConfirm={() => removeFile(src.originalName, src.location)}
                okText='Yes'
                cancelText='No'
              >
                <button
                  type='button'
                  style={{
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    padding: '5px 4px 5px 8px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                >
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            </div>
          </div>
        ))
      ) : (
        <p style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px' }}>No files to display</p>
      )}
    </div>
  );
}

export default memo(FilesList);
