import React, { useState, useEffect } from 'react';
import { Button, Drawer, Input, Form, DatePicker, List, Typography, Space, Select, Tooltip, Tag, Divider } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { scrollToTop } from '../helpers';

const initialValues = {
  type: 'owner',
  name: '',
  dateOfBirth: '',
  placeOfBirth: '',
  countryOfBirth: '',
  placeOfResidence: '',
  countryOfResidence: '',
  addressOfResidence: '',
  JMBG: '',
  typeOfDocument: '',
  numberOfDocument: '',
  issuerOfDocument: '',
  placeOfIdDocIssue: '',
  dateOfIdDocIssue: '',
  percentageOfOwnership: '',
};

const CompanyOwnershipDrawer = ({ data, onClose, open, onSubmit }) => {
  const [drawerForm] = Form.useForm();
  const [owners, setOwners] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedType, setSelectedType] = useState(initialValues.type);

  useEffect(() => {
    if (data && data.length > 0) {
      setOwners(data);
    }
  }, [data]);

  const handleSubmit = () => {
    drawerForm
      .validateFields()
      .then((values) => {
        const formattedValues = {
          ...values,
          dateOfBirth: values.dateOfBirth ? values.dateOfBirth.format('DD/MM/YYYY') : '',
          dateOfIdDocIssue: values.dateOfIdDocIssue ? values.dateOfIdDocIssue.format('DD/MM/YYYY') : '',
        };

        if (editingIndex !== null) {
          const updatedOwners = [...owners];
          updatedOwners[editingIndex] = formattedValues;
          setOwners(updatedOwners);
          setEditingIndex(null);
        } else {
          setOwners([...owners, formattedValues]);
        }

        drawerForm.resetFields();
        drawerForm.setFieldsValue({ type: 'owner' });
        setSelectedType('owner');
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });

    scrollToTop('.company-owner-details .ant-drawer-body');
  };

  const handleEdit = (index) => {
    const ownerToEdit = owners[index];
    drawerForm.setFieldsValue({
      ...ownerToEdit,
      dateOfBirth: ownerToEdit.dateOfBirth ? moment(ownerToEdit.dateOfBirth, 'DD/MM/YYYY') : null,
      dateOfIdDocIssue: ownerToEdit.dateOfIdDocIssue ? moment(ownerToEdit.dateOfIdDocIssue, 'DD/MM/YYYY') : null,
    });
    setEditingIndex(index);
    setSelectedType(ownerToEdit.type);
  };

  const handleDelete = (index) => {
    const updatedOwners = owners.filter((_, i) => i !== index);
    setOwners(updatedOwners);

    if (editingIndex === index) {
      drawerForm.resetFields();
      setEditingIndex(null);
    }
  };

  const handleCancelEdit = () => {
    drawerForm.resetFields();
    setEditingIndex(null);
    setSelectedType('owner');
    scrollToTop('.company-owner-details .ant-drawer-body');
  };

  const groupedOwners = {
    founder: owners.filter((owner) => owner.type === 'founder'),
    owner: owners.filter((owner) => owner.type === 'owner'),
    authorized: owners.filter((owner) => owner.type === 'authorized'),
  };

  const typeColors = {
    founder: 'purple',
    owner: 'blue',
    authorized: 'green',
  };

  return (
    <Drawer
      title={<span style={{ fontWeight: 'bold' }}>Company Owners and Authorized Persons Details</span>}
      width={720}
      closable={false}
      onClose={onClose}
      open={open}
      afterOpenChange={(visible) => {
        if (visible) {
          document.body.querySelector('.company-owner-details .ant-drawer-body').scrollTo(0, 0);
        }
      }}
      className='company-owner-details'
    >
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginBottom: '10px' }}>Overview of owners and authorized persons: person</p>

        {Object.entries(groupedOwners).map(([type, items]) => (
          <div key={type} style={{ marginBottom: '20px' }}>
            <Typography.Title level={5}>
              <Tag color={typeColors[type]}>{type.charAt(0).toUpperCase() + type.slice(1)}</Tag>
            </Typography.Title>

            {items.length === 0 ? (
              <Typography.Text style={{ color: 'gray' }}>No data</Typography.Text>
            ) : (
              <List
                bordered
                dataSource={items}
                renderItem={(item) => (
                  <List.Item
                    style={{ padding: '8px 12px', height: '30px' }}
                    actions={[
                      <EditOutlined key='edit' onClick={() => handleEdit(owners.indexOf(item))} />,
                      <DeleteOutlined key='delete' onClick={() => handleDelete(owners.indexOf(item))} />,
                    ]}
                  >
                    <Tooltip title={item.name}>
                      <Typography.Text ellipsis style={{ fontSize: '14px' }}>
                        {item.name}
                      </Typography.Text>
                    </Tooltip>
                  </List.Item>
                )}
              />
            )}
          </div>
        ))}

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '10px 0' }} />
        <p style={{ marginBottom: '10px' }}>
          Here you can enter the personal details of an owner or an authorized: person
        </p>

        <Form form={drawerForm} layout='vertical' initialValues={initialValues} style={{ marginTop: '20px' }}>
          <Form.Item name='type' className='custom-label' label='Type:'>
            <Select onChange={(value) => setSelectedType(value)} value={selectedType} style={{ padding: '1px 4px' }}>
              <Select.Option value='founder'>
                <Tag
                  color='purple'
                  style={{
                    marginRight: 4,
                    padding: '1px 8px',
                    fontWeight: selectedType === 'founder' ? 'bold' : 'normal',
                  }}
                >
                  Founder - owner
                </Tag>
              </Select.Option>
              <Select.Option value='owner'>
                <Tag
                  color='blue'
                  style={{
                    marginRight: 4,
                    padding: '1px 8px',
                    fontWeight: selectedType === 'owner' ? 'bold' : 'normal',
                  }}
                >
                  Owner
                </Tag>
              </Select.Option>
              <Select.Option value='authorized'>
                <Tag
                  color='green'
                  style={{
                    marginRight: 4,
                    padding: '1px 8px',
                    fontWeight: selectedType === 'authorized' ? 'bold' : 'normal',
                  }}
                >
                  Authorized person
                </Tag>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='name'
            label='Full name and surname/Company name:'
            className='custom-label'
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='dateOfBirth' label='Date of birth:' className='custom-label'>
            <DatePicker
              format='DD/MM/YYYY'
              placeholder='DD/MM/YYYY'
              style={{ width: '100%' }}
              defaultPickerValue={moment('1980', 'YYYY')}
            />
          </Form.Item>
          <Form.Item name='placeOfBirth' label='Place of birth:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item name='countryOfBirth' label='Country of birth:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item
            name='addressOfResidence'
            label='Address of residence/Company address:'
            className='custom-label'
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='placeOfResidence'
            label='Place of residence/Company place:'
            className='custom-label'
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='countryOfResidence'
            label='Country of residence/Company country:'
            className='custom-label'
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='JMBG'
            label='JMBG or other ID number/Company registration no.:'
            className='custom-label'
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='typeOfDocument' label='Type of the personal identification:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item name='numberOfDocument' label='Number of the personal identification:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item name='issuerOfDocument' label='Issuer of the personal identification:' className='custom-label'>
            <Input />
          </Form.Item>
          <Form.Item
            name='placeOfIdDocIssue'
            label='Place of issue of the personal identification document:'
            className='custom-label'
          >
            <Input />
          </Form.Item>
            <Form.Item name='dateOfIdDocIssue' label='Date of issue of the personal identification document:' className='custom-label'>
              <DatePicker
                  format='DD/MM/YYYY'
                  placeholder='DD/MM/YYYY'
                  style={{ width: '100%' }}
                  defaultPickerValue={moment('2020', 'YYYY')}
              />
          </Form.Item>
          {selectedType !== 'authorized' && (
            <Form.Item
              name='percentageOfOwnership'
              label='Percentage of ownership:'
              className='custom-label'
              rules={[
                {
                  required: true,
                  message: 'This field is required.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editingIndex !== null && (
                <Button onClick={handleCancelEdit} style={{ marginRight: '8px' }}>
                  Cancel
                </Button>
              )}
              <Button type='primary' onClick={handleSubmit}>
                {editingIndex !== null ? 'Update' : 'Add'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Button
          type='primary'
          block
          style={{ marginTop: '20px' }}
          onClick={() => onSubmit(owners)}
          disabled={owners.length === 0}
        >
          Submit All Owners
        </Button>
      </div>
    </Drawer>
  );
};

export default CompanyOwnershipDrawer;
