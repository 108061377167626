import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Form, Input, Card, Col, Row, Checkbox, Divider, Button, Select, notification, Tooltip } from 'antd';
import { FilePdfOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import en from '../../translations/en/en.json';
import sr from '../../translations/sr/sr.json';
import ru from '../../translations/ru/ru.json';
import UploadFiles from '../upload-questionnarie-files/UploadFiles';
import FilesList from '../upload-questionnarie-files/FilesList';

const { Option } = Select;

const initialValues = {
  accountingServices: true,
  payrollProcessing: false,
  other: false,
  generatedFromBusinessActivities: true,
  donationsAndSponsorships: false,
  businessWithForeignCountriesSpecification: null,
  businessInActivitiesOtherThanPrimarySpecification: null,
};

const translations = {
  English: en,
  Serbian: sr,
  Russian: ru,
};

const QuestionnaireForm = ({ data, companyId, user, SERVER_URL }) => {
  const [form] = Form.useForm();
  const [languageType, setLanguageType] = useState('English');
  const [loading, setLoading] = useState(false);
  const [accountingServices, setAccountingServices] = useState(data.questionnarie?.accountingServices ?? true);
  const [payrollProcessing, setPayrollProcessing] = useState(data.questionnarie?.payrollProcessing ?? false);
  const [generatedFromBusinessActivities, setGeneratedFromBusinessActivities] = useState(
    data.questionnarie?.generatedFromBusinessActivities ?? false,
  );
  const [donationsAndSponsorships, setDonationsAndSponsorships] = useState(
    data.questionnarie?.donationsAndSponsorships ?? false,
  );
  const [isOtherChecked, setIsOtherChecked] = useState(data.questionnarie?.isOtherChecked ?? false);
  const [isOtherSourceChecked, setIsOtherSourceChecked] = useState(data.questionnarie?.isOtherSourceChecked ?? false);
  const [businessWithForeignCountries, setBusinessWithForeignCountries] = useState(
    data.questionnarie?.businessWithForeignCountries ?? false,
  );
  const [businessInActivitiesOtherThanPrimary, setBusinessInActivitiesOtherThanPrimary] = useState(
    data.questionnarie?.businessInActivitiesOtherThanPrimary ?? false,
  );
  const [isStateCompany, setIsStateCompany] = useState(data.questionnarie?.isStateCompany ?? false);
  const [isMainlyStateCompany, setIsMainlyStateCompany] = useState(data.questionnarie?.isMainlyStateCompany ?? false);
  const [securitiesListedOnSecurityMarket, setSecuritiesListedOnSecurityMarket] = useState(
    data.questionnarie?.securitiesListedOnSecurityMarket ?? false,
  );
  const [securitiesListedOnReputableMarket, setSecuritiesListedOnReputableMarket] = useState(
    data.questionnarie?.securitiesListedOnReputableMarket ?? false,
  );
  const [anyOwnerOffShore, setAnyOwnerOffShore] = useState(data.questionnarie?.anyOwnerOffShore ?? false);
  const [legalEntityWithComplexOwnership, setLegalEntityWithComplexOwnership] = useState(
    data.questionnarie?.legalEntityWithComplexOwnership ?? false,
  );
  const [isOwnerForeignLegalEntity, setIsOwnerForeignLegalEntity] = useState(
    data.questionnarie?.isOwnerForeignLegalEntity ?? false,
  );
  const [changesInActualOwnersSinceLastSubmission, setChangesInActualOwnersSinceLastSubmission] = useState(
    data.questionnarie?.changesInActualOwnersSinceLastSubmission ?? false,
  );

  let company = {
    ...initialValues,
    ...data,
    ...data.questionnarie,
  };

  useEffect(() => {
    form.setFieldsValue(company);
  }, [company]);

  const t = (key) => translations[languageType]?.[key] || key;

  const onFinish = async (values) => {
    setLoading(true);
    values.company = companyId;
    values.accountingServices = accountingServices;
    values.payrollProcessing = payrollProcessing;
    values.generatedFromBusinessActivities = generatedFromBusinessActivities;
    values.donationsAndSponsorships = donationsAndSponsorships;
    values.isOtherChecked = isOtherChecked;
    values.isOtherSourceChecked = isOtherSourceChecked;
    values.businessWithForeignCountries = businessWithForeignCountries;
    values.businessInActivitiesOtherThanPrimary = businessInActivitiesOtherThanPrimary;
    values.isStateCompany = isStateCompany;
    values.isMainlyStateCompany = isMainlyStateCompany;
    values.securitiesListedOnSecurityMarket = securitiesListedOnSecurityMarket;
    values.securitiesListedOnReputableMarket = securitiesListedOnReputableMarket;
    values.anyOwnerOffShore = anyOwnerOffShore;
    values.legalEntityWithComplexOwnership = legalEntityWithComplexOwnership;
    values.isOwnerForeignLegalEntity = isOwnerForeignLegalEntity;
    values.changesInActualOwnersSinceLastSubmission = changesInActualOwnersSinceLastSubmission;

    values.owners = owners.map((owner, index) => ({
      ...owner,
      ...ownerStates[index],
    }));

    try {
      await Axios.post(`${SERVER_URL}/companies/questionnarie`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      }).then(() => {
        notification.success({
          message: 'Questionnarie is submitted.',
          placement: 'bottomRight',
        });

        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (err) {
      console.error('err', err);
      notification.error({
        message: 'Problem with creating or updating the questionnarie. Please try again later.',
        placement: 'bottomRight',
      });
    }
  };

  const authorizedPeople = company.ownershipStructure?.filter((person) => person.type === 'authorized') || [];
  const owners =
    company.ownershipStructure?.filter((person) => person.type === 'owner' || person.type === 'founder') || [];

  const [ownerStates, setOwnerStates] = useState(
    company.ownershipStructure
      ?.filter((person) => person.type === 'owner' || person.type === 'founder')
      ?.map((owner) => {
        const matchingOwner = data.questionnarie?.owners?.find(
          (qOwner) => qOwner.name === owner.name || qOwner.JMBG === owner.JMBG,
        );

        return {
          ...owner,
          ...matchingOwner,
          addressOfResidence: owner.addressOfResidence ?? '',
          placeOfResidence: owner.placeOfResidence ?? '',
          countryOfResidence: owner.countryOfResidence ?? '',
          directOrIndirectOwns25: matchingOwner?.directOrIndirectOwns25 ?? false,
          directOrIndirectPredominant: matchingOwner?.directOrIndirectPredominant ?? false,
          indirectlyProvidesFunds: matchingOwner?.indirectlyProvidesFunds ?? false,
          entrepreneur: matchingOwner?.entrepreneur ?? false,
          founderInManagementOfOtherForeignEntity: matchingOwner?.founderInManagementOfOtherForeignEntity ?? false,
          founderInManagementOfOtherForeignEntityList: matchingOwner?.founderInManagementOfOtherForeignEntityList ?? '',
          individualDirectOwnerOfCompany: matchingOwner?.individualDirectOwnerOfCompany ?? false,
          nameOfLegalEntityOwnersOfCompany: matchingOwner?.nameOfLegalEntityOwnersOfCompany ?? '',
          officialOfDomesticState: matchingOwner?.officialOfDomesticState ?? false,
          officialOfForeignState: matchingOwner?.officialOfForeignState ?? false,
          officialOfInternationalOrganization: matchingOwner?.officialOfInternationalOrganization ?? false,
        };
      }) || [],
  );

  const NOT_SUBMITTED_KEY = 'not_submitted';
  const updateDate = company.questionnarie?.updatedAt
    ? moment(company.questionnarie?.updatedAt).format('DD-MM-YYYY HH:mm')
    : company.questionnarie?.createdAt
      ? moment(company.questionnarie?.createdAt).format('DD-MM-YYYY HH:mm')
      : t(NOT_SUBMITTED_KEY);

  const isNotSubmitted = updateDate === t(NOT_SUBMITTED_KEY);

  const handleOtherChange = (e) => {
    setIsOtherChecked(e.target.checked);
  };

  const handleOtherSourceChange = (e) => {
    setIsOtherSourceChecked(e.target.checked);
  };

  const handleCheckboxChange = (index, field, value) => {
    setOwnerStates((prevStates) => prevStates.map((state, i) => (i === index ? { ...state, [field]: value } : state)));
  };

  const handleInputChange = (index, field, value) => {
    setOwnerStates((prevStates) => prevStates.map((state, i) => (i === index ? { ...state, [field]: value } : state)));
  };

  const downloadQuestionnairePdfHandler = async () => {
    const postData = {
      company: companyId,
      companyName: data.name,
      type: 'questionnarie',
    };
    try {
      const response = await Axios.post(`${SERVER_URL}/functionary-ownership-docs`, postData, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));

      let fileName = `functionary_docs_${data.name}.zip`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // setIsLoading(false);
    } catch (err) {
      console.error('Error in creating functionary document:', err);
      let errorMessage = 'An error occurred';
      if (err.response && err.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result);
            const { error, fields } = json;

            errorMessage = fields ? `${error}: ${fields.join(', ')}` : error;

            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          } catch (parseError) {
            notification.error({
              message: errorMessage,
              placement: 'bottomRight',
              duration: 0,
            });
          }
        };
        reader.readAsText(err.response.data);
      } else {
        notification.error({
          message: errorMessage,
          placement: 'bottomRight',
          duration: 0,
        });
      }
      // setIsLoading(false);
    }
  };

  return (
    <Form
      name='basic'
      initialValues={company}
      onFinish={(values) => onFinish(values)}
      layout='vertical'
      form={form}
      style={{ marginTop: '15px', width: '75%' }}
    >
      <Row justify='space-between' align='middle'>
        <Col>
          <p
            style={{
              margin: 0,
              color: isNotSubmitted ? 'red' : '#2b4a1d',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {t('last_update')}&nbsp;
            <span
              style={{
                backgroundColor: isNotSubmitted ? '#fdecea' : '#e6f7e9',
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
              }}
            >
              {updateDate}
            </span>
          </p>
        </Col>
        <Col>
          <Form.Item>
            <span style={{ marginRight: '10px' }}>{t('language')}</span>
            <Select style={{ width: '100px' }} onChange={(value) => setLanguageType(value)} value={languageType}>
              <Option value='English'>English</Option>
              <Option value='Serbian'>Serbian</Option>
              <Option value='Russian'>Russian</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* General Info Block */}
      <Card title={t('client_information')} style={{ marginBottom: '20px' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item className='custom-label' label={t('client_name')} name='name' style={{ marginBottom: '10px' }}>
              <Input value={data?.name} disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item className='custom-label' label={t('location')} name='city' style={{ marginBottom: '10px' }}>
              <Input value={data?.city} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='custom-label'
              label={t('street_and_number')}
              name='address'
              style={{ marginBottom: '10px' }}
            >
              <Input value={data?.address} disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item className='custom-label' label={t('tin')} name='pib' style={{ marginBottom: '10px' }}>
              <Input value={data?.pib} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='custom-label'
              label={t('registration_number')}
              name='code'
              style={{ marginBottom: '8px' }}
            >
              <Input value={data?.code} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      {/* Representatives Info Block */}
      <Card title={t('representatives_nfo')} style={{ marginBottom: '20px' }}>
        {authorizedPeople.length > 0 ? (
          authorizedPeople.map((person, index) => (
            <Card
              key={index}
              style={{ marginBottom: '10px', backgroundColor: '#f9f9f9' }}
              bodyStyle={{ padding: '15px' }}
              title={<span style={{ color: '#F5F0CD' }}>{`${t('representative')} ${index + 1} info:`}</span>}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('first_last_name')} style={{ marginBottom: '8px' }}>
                    <Input value={person.name} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('jmbg')} style={{ marginBottom: '8px' }}>
                    <Input value={person.JMBG || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('date_of_birth')} style={{ marginBottom: '8px' }}>
                    <Input value={person.dateOfBirth || 'N/A'} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('place_of_birth')} style={{ marginBottom: '8px' }}>
                    <Input value={`${person.placeOfBirth || 'N/A'}, ${person.countryOfBirth || 'N/A'}`} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='custom-label'
                    label={t('street_number_residence')}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input value={person.streetAndNumberOfResidence || 'N/A'} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='custom-label'
                    label={t('place_country_residence')}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input value={person.placeAndCountryOfResidence || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='custom-label'
                    label={t('type_number_personal_id')}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input value={person.typeNumberAndIssuerOfDocument || 'N/A'} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('date_place_issue_id')} style={{ marginBottom: '8px' }}>
                    <Input value={person.placeAndDateOfIdDocIssue || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('issuer_id')} style={{ marginBottom: '8px' }}>
                    <Input value={person.issuerId || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <p>{t('no_authorized_representatives_found')}</p>
        )}

        {/* Input for Reason of Absence */}
        <Form.Item
          name='reasonOfAbsenceOfRepresentative'
          label={t('reason_for_absence_representative')}
          style={{ marginTop: '20px' }}
          className='custom-label'
        >
          <Input placeholder={t('enter_reason_for_absence')} />
        </Form.Item>
      </Card>

      {/* Purpose and Intention Block */}
      <Card title={t('purpose_and_intention')} style={{ marginBottom: '20px' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('accounting_services')}
              </span>
              <Checkbox checked={accountingServices} onChange={(e) => setAccountingServices(e.target.checked)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('payroll_processing')}
              </span>
              <Checkbox checked={payrollProcessing} onChange={(e) => setPayrollProcessing(e.target.checked)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='other'
              valuePropName='checked'
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('other')}
              </span>
              <Checkbox onChange={handleOtherChange} checked={isOtherChecked} />
            </Form.Item>
          </Col>
        </Row>

        {isOtherChecked && (
          <Form.Item
            name='otherServices'
            label={t('specify_other')}
            style={{ marginTop: '16px' }}
            className='custom-label'
          >
            <Input placeholder={t('specify_other')} />
          </Form.Item>
        )}
      </Card>

      {/* Source of Company's Funds Block */}
      <Card title={t('source_of_client_funds')} style={{ marginBottom: '20px' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('generated_from_business_activities')}
              </span>
              <Checkbox
                checked={generatedFromBusinessActivities}
                onChange={(e) => setGeneratedFromBusinessActivities(e.target.checked)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('donations_and_sponsorships')}
              </span>
              <Checkbox
                checked={donationsAndSponsorships}
                onChange={(e) => setDonationsAndSponsorships(e.target.checked)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              valuePropName='checked'
              className='custom-label'
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <span style={{ flex: '1', textAlign: 'left', marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('other')}
              </span>
              <Checkbox onChange={handleOtherSourceChange} checked={isOtherSourceChecked} />
            </Form.Item>
          </Col>
        </Row>

        {isOtherSourceChecked && (
          <Form.Item
            name='otherDonationsAndSponsorships'
            label={t('specify_other_donations_and_sponsorships')}
            style={{ marginTop: '16px' }}
            className='custom-label'
          >
            <Input />
          </Form.Item>
        )}
      </Card>

      {/* Additional business information of the Client  */}
      <Card title={t('additional_business_information_client')} style={{ marginBottom: '20px' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='businessWithForeignCountries'
              className='custom-label'
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>{t('please_indicate')}</span>
              <Checkbox
                checked={businessWithForeignCountries}
                onChange={(e) => setBusinessWithForeignCountries(e.target.checked)}
              >
                {t('yes')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {businessWithForeignCountries && (
          <Form.Item
            name='businessWithForeignCountriesSpecification'
            label={t('specify_country_countries')}
            className='custom-label'
            style={{ marginTop: '16px' }}
          >
            <Input />
          </Form.Item>
        )}

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='businessInActivitiesOtherThanPrimary'
              className='custom-label'
              style={{
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ marginRight: '15px', fontWeight: '550', color: '#2b4a1d' }}>
                {t('please_indicate_whether')}
              </span>
              <Checkbox
                checked={businessInActivitiesOtherThanPrimary}
                onChange={(e) => setBusinessInActivitiesOtherThanPrimary(e.target.checked)}
              >
                {t('yes')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {businessInActivitiesOtherThanPrimary && (
          <Form.Item
            name='businessInActivitiesOtherThanPrimarySpecification'
            label={t('specify_activities')}
            className='custom-label'
            style={{ marginTop: '16px' }}
          >
            <Input />
          </Form.Item>
        )}
      </Card>

      {/* Ownership structure data of the Client */}
      <Card title={t('ownership_structure_data')}>
        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item name='isStateCompany' label={t('is_client_state_body')} className='custom-label' />
          </Col>
          <Col span={4}>
            <Checkbox checked={isStateCompany} onChange={(e) => setIsStateCompany(e.target.checked)}>
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item name='isMainlyStateCompany' label={t('is_client_majority_owned')} className='custom-label' />
          </Col>
          <Col span={4}>
            <Checkbox checked={isMainlyStateCompany} onChange={(e) => setIsMainlyStateCompany(e.target.checked)}>
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        {isMainlyStateCompany && (
          <Form.Item
            name='mainlyStateCompanySpecification'
            label={t('specify_name_headquarter')}
            className='custom-label'
          >
            <Input />
          </Form.Item>
        )}

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item
              name='securitiesListedOnSecurityMarket'
              label={t('are_securities_market')}
              className='custom-label'
            />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={securitiesListedOnSecurityMarket}
              onChange={(e) => setSecuritiesListedOnSecurityMarket(e.target.checked)}
            >
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        {securitiesListedOnSecurityMarket && (
          <Form.Item name='listedOnSecurityMarket' label={t('specify_stock_exchange')} className='custom-label'>
            <Input />
          </Form.Item>
        )}

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item
              name='securitiesListedOnReputableMarket'
              label={t('are_securities_reputable')}
              className='custom-label'
            />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={securitiesListedOnReputableMarket}
              onChange={(e) => setSecuritiesListedOnReputableMarket(e.target.checked)}
            >
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        {securitiesListedOnReputableMarket && (
          <Form.Item name='listedOnReputableMarket' label={t('specify_stock_reputable')} className='custom-label'>
            <Input />
          </Form.Item>
        )}

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item name='anyOwnerOffShore' label={t('is_client_appear')} className='custom-label' />
          </Col>
          <Col span={4}>
            <Checkbox checked={anyOwnerOffShore} onChange={(e) => setAnyOwnerOffShore(e.target.checked)}>
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item name='legalEntityWithComplexOwnership' label={t('if_yes_previous')} className='custom-label' />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={legalEntityWithComplexOwnership}
              onChange={(e) => setLegalEntityWithComplexOwnership(e.target.checked)}
            >
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>

        <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

        <Row style={{ marginBottom: '-35px' }}>
          <Col span={20}>
            <Form.Item name='isOwnerForeignLegalEntity' label={t('is_foreign_legal_entity')} className='custom-label' />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={isOwnerForeignLegalEntity}
              onChange={(e) => setIsOwnerForeignLegalEntity(e.target.checked)}
            >
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>
      </Card>

      {/* Owners Info Block */}
      <Card title={t('info_about_owners')} style={{ marginBottom: '20px' }}>
        {ownerStates.length > 0 ? (
          ownerStates.map((person, index) => (
            <Card
              key={index}
              style={{ marginBottom: '10px', backgroundColor: '#f9f9f9' }}
              bodyStyle={{ padding: '15px' }}
              title={<span style={{ color: '#F5F0CD' }}>{`${t('beneficial_owner')} ${index + 1}:`}</span>}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('first_last_name')} style={{ marginBottom: '8px' }}>
                    <Input value={person.name} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('jmbg')} style={{ marginBottom: '8px' }}>
                    <Input value={person.JMBG || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('date_of_birth')} style={{ marginBottom: '8px' }}>
                    <Input value={person.dateOfBirth || 'N/A'} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('place_of_birth')} style={{ marginBottom: '8px' }}>
                    <Input value={person.placeOfBirth || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item className='custom-label' label={t('country_of_birth')} style={{ marginBottom: '8px' }}>
                    <Input value={person.countryOfBirth || 'N/A'} disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className='custom-label'
                    label={t('place_country_residence')}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input value={person.placeAndCountryOfResidence || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className='custom-label'
                    label={t('percentage_of_ownership')}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input value={person.percentageOfOwnership || 'N/A'} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
              <Row style={{ padding: '15px 0', textDecoration: 'underline', fontWeight: 'bolder' }}>
                1. {t('section_1')}
              </Row>
              <Row gutter={4}>
                <Col span={20}>
                  <Form.Item name='directOrIndirectOwns25' className='custom-label' label={t('natural_25')} />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.directOrIndirectOwns25}
                    onChange={(e) => handleCheckboxChange(index, 'directOrIndirectOwns25', e.target.checked)}
                  >
                    Yes
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item
                    name='directOrIndirectPredominant'
                    className='custom-label'
                    label={t('natural_permanent')}
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.directOrIndirectPredominant}
                    onChange={(e) => handleCheckboxChange(index, 'directOrIndirectPredominant', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item name='indirectlyProvidesFunds' className='custom-label' label={t('natural_provides')} />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.indirectlyProvidesFunds}
                    onChange={(e) => handleCheckboxChange(index, 'indirectlyProvidesFunds', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item name='entrepreneur' className='custom-label' label={t('entrepreneur')} />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.entrepreneur}
                    onChange={(e) => handleCheckboxChange(index, 'entrepreneur', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item
                    name='founderInManagementOfOtherForeignEntity'
                    className='custom-label'
                    label={t('natural_founder')}
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.founderInManagementOfOtherForeignEntity}
                    onChange={(e) =>
                      handleCheckboxChange(index, 'founderInManagementOfOtherForeignEntity', e.target.checked)
                    }
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>

              <Form.Item style={{ marginTop: '-40px' }} className='custom-label'>
                <Input
                  value={person.founderInManagementOfOtherForeignEntityList}
                  onChange={(e) =>
                    handleInputChange(index, 'founderInManagementOfOtherForeignEntityList', e.target.value)
                  }
                />
              </Form.Item>

              <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />
              <Row gutter={4}>
                <Col span={20}>
                  <Form.Item
                    name='individualDirectOwnerOfCompany'
                    style={{ padding: '15px 0', fontWeight: 'bolder' }}
                    label={
                      <span style={{ textDecoration: 'underline' }}>2. {t('is_individual_direct_owner_client')}</span>
                    }
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    style={{ marginTop: '15px' }}
                    checked={person.individualDirectOwnerOfCompany}
                    onChange={(e) => handleCheckboxChange(index, 'individualDirectOwnerOfCompany', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row style={{ marginTop: '-60px' }}>{t('name_and_registered_office')}</Row>
              <Form.Item style={{ marginTop: '10px' }} className='custom-label'>
                <Input
                  value={person.nameOfLegalEntityOwnersOfCompany}
                  onChange={(e) => handleInputChange(index, 'nameOfLegalEntityOwnersOfCompany', e.target.value)}
                />
              </Form.Item>
              <Row style={{ fontWeight: 'bold' }}>❗❗❗ {t('along_with')}</Row>

              <Divider style={{ backgroundColor: '#2db1ab50', margin: '10px 0' }} />
              <Row style={{ padding: '15px 0', textDecoration: 'underline', fontWeight: 'bolder' }}>
                3. {t('section_3')}
              </Row>
              <Row gutter={4}>
                <Col span={20}>
                  <Form.Item name='officialOfDomesticState' className='custom-label' label={t('state_official')} />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.officialOfDomesticState}
                    onChange={(e) => handleCheckboxChange(index, 'officialOfDomesticState', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item name='officialOfForeignState' className='custom-label' label={t('foreign_official')} />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.officialOfForeignState}
                    onChange={(e) => handleCheckboxChange(index, 'officialOfForeignState', e.target.checked)}
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={4} style={{ marginTop: '-50px' }}>
                <Col span={20}>
                  <Form.Item
                    name='officialOfInternationalOrganization'
                    className='custom-label'
                    label={t('international_official')}
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    checked={person.officialOfInternationalOrganization}
                    onChange={(e) =>
                      handleCheckboxChange(index, 'officialOfInternationalOrganization', e.target.checked)
                    }
                  >
                    {t('yes')}
                  </Checkbox>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <p> {t('no_owners')}</p>
        )}
      </Card>

      {/* Statement on Client's Ownership/Management Structure Block */}
      <Card title={t('statement_on_ownership_structure')} style={{ marginBottom: '20px' }}>
        <Row gutter={4}>
          <Col span={20}>
            <Form.Item
              name='changesInActualOwnersSinceLastSubmission'
              className='custom-label'
              label={t('please_confirm')}
            />
          </Col>
          <Col span={4}>
            <Checkbox
              checked={changesInActualOwnersSinceLastSubmission}
              onChange={(e) => setChangesInActualOwnersSinceLastSubmission(e.target.checked)}
            >
              {t('yes')}
            </Checkbox>
          </Col>
        </Row>
        <Row style={{ fontWeight: 'bold' }}>❗❗❗ {t('if_answer_yes')}</Row>
      </Card>

      <UploadFiles token={user.data.token} company={data} />
      <FilesList token={user.data.token} sources={company.questionnarieDocs ?? []} />

      <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0', height: '3px', borderStyle: 'solid' }} />
      <Tooltip title="Download Questionnaire as PDF">
      <FilePdfOutlined
        style={{
          fontSize: '50px',
          color: '#d32f2f',
          cursor: 'pointer',
        }}
        onClick={() => downloadQuestionnairePdfHandler()}
      />
      </Tooltip>

      <Button
        type='primary'
        htmlType='submit'
        style={{
          display: 'block',
          margin: '40px auto 20px',
          padding: '10px 20px',
          fontSize: '18px',
          width: '150px',
          height: '50px',
        }}
        loading={loading}
      >
        Submit
      </Button>

      <Divider style={{ backgroundColor: '#2db1ab50', margin: '5px 0' }} />

      {/* Definitions Block */}
      <Row style={{ marginTop: '50px', fontWeight: 'bold' }}>
        <span>{t('definitions_title')}</span>
      </Row>
      <Row>
        <span style={{ color: '#5f4b8b', textAlign: 'justify', display: 'block' }}>
          <strong style={{ display: 'block' }}>{t('definition_title_1')}</strong>
          {t('definition_answer_1')}
          <strong style={{ display: 'block' }}>{t('definition_title_2')}</strong>
          {t('definition_answer_2')}
          <strong style={{ display: 'block' }}>{t('definition_title_3')}</strong>
          {t('definition_answer_3')}
          <strong style={{ display: 'block' }}>{t('definition_title_4')}</strong>
          {t('definition_answer_4')}
          <strong style={{ display: 'block' }}>{t('definition_title_5')}</strong>
          {t('definition_answer_5')}
          <strong style={{ display: 'block' }}>{t('definition_title_6')}</strong>
          {t('definition_answer_6')}
          <strong style={{ display: 'block' }}>{t('definition_title_7')}</strong>
          {t('definition_answer_7')}
          <strong style={{ display: 'block' }}>{t('definition_title_8')}</strong>
          {t('definition_answer_8')}
          <strong style={{ display: 'block' }}>{t('definition_title_9')}</strong>
          {t('definition_answer_9')}
          <strong style={{ display: 'block' }}>{t('definition_title_10')}</strong>
          {t('definition_answer_10')}
          <strong style={{ display: 'block' }}>{t('definition_title_11')}</strong>
          {t('definition_answer_11')}
          <strong style={{ display: 'block' }}>{t('definition_title_12')}</strong>
          {t('definition_answer_12')}
        </span>
      </Row>
    </Form>
  );
};

export default QuestionnaireForm;
