import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Row, Select, DatePicker, notification, Button, Divider, Tooltip } from 'antd';
import moment from 'moment';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { holidaysArray } from '../constants/holidays';
import { edgeDateForOldHolidayDays } from '../constants/edgeDateForOldHolidayDays';
import { calculateBusinessDays, disabledDate } from '../helpers/dates';
import { OfficialTravel } from './forms/official-travel';
import { getUserFullName, hasUserData } from '../utils';

const { RangePicker, MonthPicker } = DatePicker;
const { Option } = Select;

const HrForm = ({
  employeeId,
  data,
  companyTypeOfHR,
  selectedCompany,
  datesForDisabling,
  handleChangeTab,
  handleRefetchData,
}) => {
  const user = useContext(UserContext);
  const companyId = data?.company?._id;
  const [form] = Form.useForm();
  const [typeOfLeave, setTypeOfLeave] = useState('');
  const [numOfDays, setNumOfDays] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [firstDayBack, setFirstDayBack] = useState('');
  const [reasonOfLeave, setReasonOfLeave] = useState('');
  const [reasonOfLeaveEng, setReasonOfLeaveEng] = useState('');
  const [typeBonus] = useState('');
  const [typeBonusEng] = useState('');
  const [valueBonus, setValueBonus] = useState('');
  const [currencyBonus, setCurrencyBonus] = useState('');
  const [bonusMonth, setBonusMonth] = useState(undefined);
  const [dateOvertime, setDateOvertime] = useState('');
  const [basisOvertime, setBasisOvertime] = useState('');
  const [basisOvertimeEng, setBasisOvertimeEng] = useState('');
  const [numOfHoursOvertime, setNumOfHoursOvertime] = useState('');
  const [percentageBonusOvertime] = useState(26);
  const [otherReasonOfLeaveEngFieldVisible, setOtherReasonOfLeaveEngFieldVisible] = useState(false);
  const [otherReasonOfLeaveFieldVisible, setOtherReasonOfLeaveFieldVisible] = useState(false);
  const [isEndDateThisYear, setIsEndDateThisYear] = useState(false);
  const [dateOfCreation, setDateOfCreation] = useState(undefined);
  const [holidays] = useState(
    datesForDisabling && datesForDisabling.length
      ? holidaysArray.concat(datesForDisabling.map((item) => moment(item, 'DD.MM.YYYY.')))
      : holidaysArray,
  );

  const history = useHistory();

  useEffect(() => {
    if (selectedCompany && selectedCompany.bonusCurrency) {
      setCurrencyBonus(selectedCompany.bonusCurrency);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (data) {
      // this is to check if endDate is within current year and conditional display of holiday tracker fields
      const currentYear = new Date().getFullYear();
      if (data.endDate && new Date(data.endDate).getFullYear() === currentYear) {
        setIsEndDateThisYear(true);
      }
      form.setFieldsValue({
        numberOfHolidayDays: data.numberOfHolidayDays,
        numberOfHolidayDaysPast: data.numberOfHolidayDaysPast,
        numberOfHolidayDaysCurrent: data.numberOfHolidayDaysCurrent,
        numberOfHolidayDaysContract: data.numberOfHolidayDaysContract,
        numberOfHolidayDaysByEndOfContract: data.numberOfHolidayDaysByEndOfContract,
        numberOfHolidayDaysUsed: data.numberOfHolidayDaysUsed,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    const { role } = user.data;

    if (role.includes('employee')) {
      if (
        role.includes('hrOfficer') &&
        selectedCompany?.isSector &&
        (selectedCompany.isHrOfficerSelfApprove || user.data.employee !== employeeId)
      ) {
        selfApprovingRequest(values);
      } else {
        employeeSubmitRequest(values);
      }
    } else {
      selfApprovingRequest(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const selfApprovingRequest = async () => {
    try {
      if (typeOfLeave === 'Annual leave' && selectedCompany.holidayDaysTracker) {
        await Axios.put(
          `${SERVER_URL}/request-check/${employeeId === undefined ? user.data.employee : employeeId}`,
          {
            record: {
              startDate,
              endDate,
              numOfDays,
            },
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.data.token}` },
          },
        );
      }
      notification.info({
        message: 'Creation of request is in progress...',
        placement: 'bottomRight',
      });

      const availableDays = data.numberOfHolidayDaysByEndOfContract || data.numberOfHolidayDays;
      let initialPastDays = +data.numberOfHolidayDaysPast > numOfDays ? +data.numberOfHolidayDaysPast - numOfDays : 0;
      const requestDateFrom = moment(startDate, 'DD/MM/YYYY');
      const requestDateTo = moment(endDate, 'DD/MM/YYYY');

      if (typeOfLeave === 'Annual leave' && data.numberOfHolidayDaysPast && +data.numberOfHolidayDaysPast > 0) {
        if (edgeDateForOldHolidayDays.isBetween(requestDateFrom, requestDateTo, 'day', '[]')) {
          const businessDays = calculateBusinessDays(requestDateFrom, edgeDateForOldHolidayDays);
          initialPastDays = businessDays < numOfDays ? 0 : businessDays;
        }
        if (edgeDateForOldHolidayDays.isBefore(requestDateFrom, 'day')) {
          initialPastDays = 0;
        }
        if (edgeDateForOldHolidayDays.isSame(requestDateFrom, 'day')) {
          initialPastDays = 1;
        }
      }

      await Axios.post(
        `${SERVER_URL}/request/approve`,
        {
          record: {
            startDate,
            endDate,
            numOfDays,
            typeOfLeave,
            firstDayBack,
            typeBonus,
            valueBonus,
            currencyBonus,
            dateOvertime,
            basisOvertime,
            numOfHoursOvertime,
            percentageBonusOvertime,
            employeeId,
            reasonOfLeave,
            reasonOfLeaveEng,
            typeBonusEng,
            basisOvertimeEng,
            dateOfCreation,
            numberOfHolidayDaysPast: data.numberOfHolidayDaysPast,
            holidayDaysLeftTotal: {
              holidayDaysLeft: availableDays - numOfDays,
              holidayDaysPast: initialPastDays,
              isPastYearHoliday: +data.numberOfHolidayDaysPast > 0,
            },
            isSelfApprove: true,
          },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );
      notification.success({
        message: 'Your leave has been submitted.',
        placement: 'bottomRight',
      });
      history.push(
        user.data.role.includes('employee') && !user.data.role.includes('hrOfficer')
          ? '/admin/hr-platform-requests'
          : '/admin/data',
      );
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Handle error code 400
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        // Handle other errors
        notification.error({
          message: 'Problem with sending. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const employeeSubmitRequest = async () => {
    try {
      if (
        typeOfLeave === 'Suspension' &&
        user.data.role.includes('employee') &&
        !user.data.role.includes('hrOfficer')
      ) {
        notification.warning({
          message: 'You are not allowed to make this request!',
          placement: 'bottomRight',
          duration: 3,
        });
        return;
      }
      if (typeOfLeave === 'Annual leave' && selectedCompany.holidayDaysTracker) {
        await Axios.put(
          `${SERVER_URL}/request-check/${employeeId === undefined ? user.data.employee : employeeId}`,
          {
            record: {
              startDate,
              endDate,
              numOfDays,
            },
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.data.token}` },
          },
        );
      }
      notification.info({
        message: 'Creation of request is in progress...',
        placement: 'bottomRight',
      });
      const availableDays = data.numberOfHolidayDaysByEndOfContract || data.numberOfHolidayDays;

      let initialPastDays = +data.numberOfHolidayDaysPast > numOfDays ? +data.numberOfHolidayDaysPast - numOfDays : 0;
      const requestDateFrom = moment(startDate, 'DD/MM/YYYY');
      const requestDateTo = moment(endDate, 'DD/MM/YYYY');

      if (typeOfLeave === 'Annual leave' && data.numberOfHolidayDaysPast && +data.numberOfHolidayDaysPast > 0) {
        if (edgeDateForOldHolidayDays.isBetween(requestDateFrom, requestDateTo, 'day', '[]')) {
          const businessDays = calculateBusinessDays(requestDateFrom, edgeDateForOldHolidayDays);
          initialPastDays = businessDays < numOfDays ? 0 : businessDays;
        }
        if (edgeDateForOldHolidayDays.isBefore(requestDateFrom, 'day')) {
          initialPastDays = 0;
        }
        if (edgeDateForOldHolidayDays.isSame(requestDateFrom, 'day')) {
          initialPastDays = 1;
        }
      }

      await Axios.post(
        `${SERVER_URL}/request`,
        {
          record: {
            startDate,
            endDate,
            numOfDays,
            typeOfLeave,
            firstDayBack,
            typeBonus,
            valueBonus,
            currencyBonus,
            dateOvertime,
            basisOvertime,
            numOfHoursOvertime,
            percentageBonusOvertime,
            employeeId,
            reasonOfLeave,
            reasonOfLeaveEng,
            typeBonusEng,
            basisOvertimeEng,
            dateOfCreation,
            numberOfHolidayDaysPast: data.numberOfHolidayDaysPast,
            holidayDaysLeftTotal: {
              holidayDaysLeft: availableDays - numOfDays,
              holidayDaysPast: initialPastDays,
              isPastYearHoliday: +data.numberOfHolidayDaysPast > 0,
            },
          },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );
      notification.success({
        message: 'Your leave has been submitted.',
        placement: 'bottomRight',
      });
      history.push(
        user.data.role.includes('employee') && !user.data.role.includes('hrOfficer')
          ? '/admin/hr-platform-requests'
          : '/admin/data',
      );
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
        });
      } else {
        console.error('err', err);
        notification.error({
          message: 'Problem with sending. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const handleReasonOfLeaveEng = (val) => {
    setReasonOfLeaveEng(val);
    if (val === 'Other') {
      setOtherReasonOfLeaveEngFieldVisible(true);
    } else {
      setOtherReasonOfLeaveEngFieldVisible(false);
    }
  };

  const handleReasonOfLeave = (val) => {
    setReasonOfLeave(val);
    if (val === 'Ostalo') {
      setOtherReasonOfLeaveFieldVisible(true);
    } else {
      setOtherReasonOfLeaveFieldVisible(false);
    }
  };
  function setRangePickerValue(value) {
    let countDays;
    let firstDayBack;
    if (value && value.length > 0) {
      let start = value[0];
      let startWhile = value[0];
      let end = value[1];
      countDays = 0;
      while (startWhile <= end) {
        if (
          startWhile.format('E') !== '6' &&
          startWhile.format('E') !== '7' &&
          !holidays.some((holiday) => startWhile.isSame(holiday, 'day'))
        ) {
          countDays++;
        }
        startWhile = moment(startWhile, 'YYYY-MM-DD').add(1, 'days');
        setNumOfDays(countDays);
      }
      end.format('E') === '5'
        ? (firstDayBack = moment(end, 'YYYY-MM-DD').add(3, 'days'))
        : (firstDayBack = moment(end, 'YYYY-MM-DD').add(1, 'days'));

      setStartDate(start.format('L'));
      setEndDate(end.format('L'));
      setFirstDayBack(firstDayBack.format('L'));
    }
  }

  const handleBonusMonthPicker = (val) => {
    if (val && val._isAMomentObject) {
      const firstDayOfMonth = val.startOf('month').format('L');
      const lastDayOfMonth = val.endOf('month').format('L');

      setBonusMonth(val);
      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
    }
  };

  // validation for bonus month picker
  const handleValidateMonthPicker = (rule, value, callback) => {
    if (!bonusMonth) {
      callback('Please select a month!');
    } else {
      callback();
    }
  };

  /**
   * TODO: USER TESTING data object
   */
  const userData = hasUserData(data);
  const userFullName = userData ? getUserFullName(userData) : 'Loading...';

  const displayForm = (data, user) => {
    if (typeOfLeave === 'Annual leave') {
      return (
        <>
          <Row>
            <Form.Item
              label='Date of leave'
              name='dateFromTo'
              labelCol={{ span: 10 }}
              labelAlign='left'
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                onChange={(val) => setRangePickerValue(val)}
                format={'DD-MM-YYYY'}
                style={{ marginLeft: '5px' }}
              />
            </Form.Item>

            <Form.Item label='Number of days' name='numOfDays' style={{ marginRight: '40px', marginLeft: '30px' }}>
              <p style={{ marginTop: '5px' }}>{numOfDays}</p>
            </Form.Item>
            <Form.Item label='First day back' name='firstDayBack' style={{ marginRight: '40px' }}>
              <p style={{ marginTop: '5px' }}>{firstDayBack}</p>
            </Form.Item>
          </Row>
          {!user.data.role.includes('employee') && (
            <Form.Item
              label='Date of request creation'
              name='dateOfCreation'
              labelCol={{ span: 3.5 }}
              labelAlign='left'
              style={{ marginTop: '-7px' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                  style={{ marginLeft: '8px' }}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px', marginBottom: '20px' }}>
            Submit
          </Button>
        </>
      );
    }
    if (typeOfLeave === 'Paid days off' || typeOfLeave === 'Unpaid days off') {
      return (
        <>
          {(user?.data?.role?.includes('admin') || user?.data?.role?.includes('SuperAdmin')) && (
            <div>
              <Form.Item
                label='Reason of leave - Serbian'
                name='reasonOfLeave'
                labelCol={{ span: 6 }}
                labelAlign='left'
              >
                {typeOfLeave === 'Paid days off' && (
                  <>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: '30%' }}
                      onChange={(val) => handleReasonOfLeave(val)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in Serbian!',
                        },
                      ]}
                    >
                      <Option key='childBirthSer' value='Rođenje deteta' />
                      <Option key='weddingSer' value='Svadba' />
                      <Option key='funeralSer' value='Sahrana člana uže porodice' />
                      <Option key='illnessSer' value='Bolest člana uže porodice' />
                      <Option key='bloodDonationSer' value='Davanje krvi' />
                      <Option key='otherSer' value='Ostalo' />
                    </Select>

                    {otherReasonOfLeaveFieldVisible && (
                      <Input
                        onChange={(e) => setReasonOfLeave(e.target.value)}
                        style={{ marginTop: '20px' }}
                        placeholder='Molimo, unesite razlog odsustva'
                      />
                    )}
                  </>
                )}

                {typeOfLeave === 'Unpaid days off' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave in Serbian!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
              <Form.Item
                label='Reason of leave - English'
                name='reasonOfLeaveEng'
                labelCol={{ span: 6 }}
                labelAlign='left'
              >
                {typeOfLeave === 'Paid days off' && (
                  <>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '30%' }}
                      onChange={(val) => handleReasonOfLeaveEng(val)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in English!',
                        },
                      ]}
                    >
                      <Option key='childBirth' value='Birth of child' />
                      <Option key='wedding' value='Wedding' />
                      <Option key='funeral' value='Funeral of a close family member' />
                      <Option key='illness' value='Illness of a close family member' />
                      <Option key='birthday' value='Blood donation' />
                      <Option key='other' value='Other' />
                    </Select>
                    {otherReasonOfLeaveEngFieldVisible && (
                      <Input
                        onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                        style={{ marginTop: '20px' }}
                        placeholder='Please, specify the reason'
                      />
                    )}
                  </>
                )}
                {typeOfLeave === 'Unpaid days off' && (
                  <Input
                    onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                    placeholder='Please, specify the reason'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave in English!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            </div>
          )}
          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user.data.company.typeOfHr === 'bilingual' && (
              <div>
                <Form.Item
                  label='Reason of leave - Serbian'
                  name='reasonOfLeave'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Paid days off' && (
                    <>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '30%' }}
                        onChange={(val) => handleReasonOfLeave(val)}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter reason of leave in Serbian!',
                          },
                        ]}
                      >
                        <Option key='childBirthSer' value='Rođenje deteta' />
                        <Option key='weddingSer' value='Svadba' />
                        <Option key='funeralSer' value='Sahrana člana uže porodice' />
                        <Option key='illnessSer' value='Bolest člana uže porodice' />
                        <Option key='bloodDonationSer' value='Davanje krvi' />
                        <Option key='otherSer' value='Ostalo' />
                      </Select>

                      {otherReasonOfLeaveFieldVisible && (
                        <Input
                          onChange={(e) => setReasonOfLeave(e.target.value)}
                          style={{ marginTop: '20px' }}
                          placeholder='Molimo, unesite razlog odsustva'
                        />
                      )}
                    </>
                  )}

                  {typeOfLeave === 'Unpaid days off' && (
                    <Input
                      onChange={(e) => setReasonOfLeave(e.target.value)}
                      placeholder='Molimo, unesite razlog odsustva'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in Serbian!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label='Reason of leave - English'
                  name='reasonOfLeaveEng'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Paid days off' && (
                    <>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '30%' }}
                        onChange={(val) => handleReasonOfLeaveEng(val)}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter reason of leave in English!',
                          },
                        ]}
                      >
                        <Option key='childBirth' value='Birth of child' />
                        <Option key='wedding' value='Wedding' />
                        <Option key='funeral' value='Funeral of a close family member' />
                        <Option key='illness' value='Illness of a close family member' />
                        <Option key='birthday' value='Blood donation' />
                        <Option key='other' value='Other' />
                      </Select>
                      {otherReasonOfLeaveEngFieldVisible && (
                        <Input
                          onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                          style={{ marginTop: '20px' }}
                          placeholder='Please, specify the reason'
                        />
                      )}
                    </>
                  )}
                  {typeOfLeave === 'Unpaid days off' && (
                    <Input
                      onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                      placeholder='Please, specify the reason'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in English!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user?.data?.company?.typeOfHr === 'serbian' && (
              <Form.Item label='Reason of leave' name='reasonOfLeave' labelCol={{ span: 6 }} labelAlign='left'>
                {typeOfLeave === 'Paid days off' && (
                  <>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '30%' }}
                      onChange={(val) => handleReasonOfLeave(val)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave!',
                        },
                      ]}
                    >
                      <Option key='childBirthSer' value='Rođenje deteta' />
                      <Option key='weddingSer' value='Svadba' />
                      <Option key='funeralSer' value='Sahrana člana uže porodice' />
                      <Option key='illnessSer' value='Bolest člana uže porodice' />
                      <Option key='bloodDonationSer' value='Davanje krvi' />
                      <Option key='otherSer' value='Ostalo' />
                    </Select>

                    {otherReasonOfLeaveFieldVisible && (
                      <Input
                        onChange={(e) => setReasonOfLeave(e.target.value)}
                        style={{ marginTop: '20px' }}
                        placeholder='Molimo, unesite razlog odsustva'
                      />
                    )}
                  </>
                )}

                {typeOfLeave === 'Unpaid days off' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              (user.data.role.includes('hrOfficer') && data)) &&
            (user?.data?.employeeCompany?.typeOfHr === 'bilingual' ||
              companyTypeOfHR === 'bilingual' ||
              data.company.typeOfHr === 'bilingual') && (
              <div>
                <Form.Item
                  label='Reason of leave - Serbian'
                  name='reasonOfLeave'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Paid days off' && (
                    <>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '30%' }}
                        onChange={(val) => handleReasonOfLeave(val)}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter reason of leave in Serbian!',
                          },
                        ]}
                      >
                        <Option key='childBirthSer' value='Rođenje deteta' />
                        <Option key='weddingSer' value='Svadba' />
                        <Option key='funeralSer' value='Sahrana člana uže porodice' />
                        <Option key='illnessSer' value='Bolest člana uže porodice' />
                        <Option key='bloodDonationSer' value='Davanje krvi' />
                        <Option key='otherSer' value='Ostalo' />
                      </Select>

                      {otherReasonOfLeaveFieldVisible && (
                        <Input
                          onChange={(e) => setReasonOfLeave(e.target.value)}
                          style={{ marginTop: '20px' }}
                          placeholder='Molimo, unesite razlog odsustva'
                        />
                      )}
                    </>
                  )}

                  {typeOfLeave === 'Unpaid days off' && (
                    <Input
                      placeholder='Molimo, unesite razlog odsustva'
                      onChange={(e) => setReasonOfLeave(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in Serbian!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label='Reason of leave - English'
                  name='reasonOfLeaveEng'
                  labelCol={{ span: 6 }}
                  labelAlign='left'
                >
                  {typeOfLeave === 'Paid days off' && (
                    <>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '30%' }}
                        onChange={(val) => handleReasonOfLeaveEng(val)}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter reason of leave in English!',
                          },
                        ]}
                      >
                        <Option key='childBirth' value='Birth of child' />
                        <Option key='wedding' value='Wedding' />
                        <Option key='funeral' value='Funeral of a close family member' />
                        <Option key='illness' value='Illness of a close family member' />
                        <Option key='birthday' value='Blood donation' />
                        <Option key='other' value='Other' />
                      </Select>
                      {otherReasonOfLeaveEngFieldVisible && (
                        <Input
                          onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                          style={{ marginTop: '20px' }}
                          placeholder='Please, specify the reason'
                        />
                      )}
                    </>
                  )}
                  {typeOfLeave === 'Unpaid days off' && (
                    <Input
                      onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                      placeholder='Please, specify the reason'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave in English!',
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              (user.data.role.includes('hrOfficer') && data)) &&
            (user?.data?.employeeCompany?.typeOfHr === 'serbian' ||
              companyTypeOfHR === 'serbian' ||
              data.company.typeOfHr === 'serbian') && (
              <Form.Item label='Reason of leave' name='reasonOfLeave' labelCol={{ span: 6 }} labelAlign='left'>
                {typeOfLeave === 'Paid days off' && (
                  <>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '30%' }}
                      onChange={(val) => handleReasonOfLeave(val)}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter reason of leave!',
                        },
                      ]}
                    >
                      <Option key='weddingSer' value='Venčanje' />
                      <Option key='childBirthSer' value='Rođenje deteta' />
                      <Option key='childBirthSer' value='Venčanje detata' />
                      <Option key='birthdaySer' value='Rođendan' />
                      <Option key='movingSer' value='Preseljenje' />
                      <Option key='funeralSer' value='Sahrana' />
                      <Option key='otherSer' value='Drugo' />
                    </Select>

                    {otherReasonOfLeaveFieldVisible && (
                      <Input
                        onChange={(e) => setReasonOfLeave(e.target.value)}
                        style={{ marginTop: '20px' }}
                        placeholder='Molimo, unesite razlog odsustva'
                      />
                    )}
                  </>
                )}

                {typeOfLeave === 'Unpaid days off' && (
                  <Input
                    placeholder='Molimo, unesite razlog odsustva'
                    onChange={(e) => setReasonOfLeave(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter reason of leave!',
                      },
                    ]}
                  />
                )}
              </Form.Item>
            )}
          <Form.Item
            label='Date of leave'
            name='dateFromTo'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(val) => setRangePickerValue(val)}
              format={'DD-MM-YYYY'}
              style={{ width: '30%' }}
            />
          </Form.Item>
          <Form.Item
            label='Number of days'
            name='numOfDays'
            style={{ marginRight: '20px' }}
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <p style={{ marginTop: '5px', marginLeft: '5px' }}>{numOfDays}</p>
          </Form.Item>
          <Form.Item
            label='First day back'
            name='firstDayBack'
            style={{ marginRight: '20px' }}
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            <p style={{ marginTop: '5px', marginLeft: '5px' }}>{firstDayBack}</p>
          </Form.Item>
          {!user.data.role.includes('employee') && (
            <Form.Item label='Date of request creation' name='dateOfCreation' labelCol={{ span: 6 }} labelAlign='left'>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button
            type='primary'
            htmlType='submit'
            style={{ float: 'right', marginTop: '10px' }}
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            Submit
          </Button>
        </>
      );
    } else if (typeOfLeave === 'Slava - Saint day') {
      return (
        <>
          {user.data.role.includes('admin') || (user.data.role.includes('SuperAdmin') && <div></div>)}
          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user?.data?.company?.typeOfHr === 'bilingual' && <div></div>}

          {(!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            user.data.role.includes('employee')) ||
            user.data.role.includes('hrOfficer') ||
            (user.data.role.includes('employee') &&
              user.data.role.includes('hrOfficer') &&
              user?.data?.employeeCompany?.typeOfHr === 'bilingual' && <div></div>)}
          <Form.Item
            label='Date of leave'
            name='dateFromTo'
            labelCol={{ span: 5 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(val) => setRangePickerValue(val)}
              format={'DD-MM-YYYY'}
            />
          </Form.Item>
          <Form.Item
            label='Number of days'
            name='numOfDays'
            style={{ marginRight: '2px' }}
            labelCol={{ span: 5 }}
            labelAlign='left'
          >
            <p style={{ marginTop: '5px', marginLeft: '2px' }}>{numOfDays}</p>
          </Form.Item>
          <Form.Item
            label='First day back'
            name='firstDayBack'
            style={{ marginRight: '20px' }}
            labelCol={{ span: 5 }}
            labelAlign='left'
          >
            <p style={{ marginTop: '5px', marginLeft: '5px' }}>{firstDayBack}</p>
          </Form.Item>
          {!user.data.role.includes('employee') && (
            <Form.Item label='Date of request creation' name='dateOfCreation' labelCol={{ span: 5 }} labelAlign='left'>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button
            type='primary'
            htmlType='submit'
            style={{ float: 'right', marginTop: '10px' }}
            labelCol={{ span: 6 }}
            labelAlign='left'
          >
            Submit
          </Button>
        </>
      );
    } else if (typeOfLeave === 'Maternity leave' || typeOfLeave === 'Pregnancy leave') {
      return (
        <>
          <Form.Item
            label='Date of leave'
            name='dateFromTo'
            labelCol={{ span: 4 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(val) => setRangePickerValue(val)}
              format={'DD-MM-YYYY'}
              style={{ marginLeft: '15px' }}
            />
          </Form.Item>

          <Form.Item
            label='First day back'
            name='firstDayBack'
            style={{ marginRight: '20px' }}
            labelCol={{ span: 4 }}
            labelAlign='left'
          >
            <p style={{ marginTop: '5px', marginLeft: '20px' }}>{firstDayBack}</p>
          </Form.Item>
          {!user.data.role.includes('employee') && (
            <Form.Item
              label='Date of request creation'
              name='dateOfCreation'
              labelCol={{ span: 4 }}
              labelAlign='left'
              style={{ marginTop: '-5px' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                  style={{ marginLeft: '15px' }}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
            Submit
          </Button>
        </>
      );
    } else if (typeOfLeave === 'Bonus') {
      return (
        <>
          <Form.Item
            label='Bonus for month'
            name='bonusMonth'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                validator: handleValidateMonthPicker,
              },
            ]}
          >
            <MonthPicker onChange={(val) => handleBonusMonthPicker(val)} format={'MM-YYYY'} />
            <Tooltip
              title='If you want to change the currency of the Bonus, please contact the administrator.'
              color='#b1b1b1'
              placement='bottom'
              autoAdjustOverflow={true}
            >
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
            </Tooltip>
          </Form.Item>
          <Form.Item
            label='Value'
            name='valueBonus'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter the value!',
              },
            ]}
          >
            <Input style={{ width: 80 }} onChange={(e) => setValueBonus(e.target.value)} />
          </Form.Item>
          <Form.Item
            label='Currency'
            name='currencyBonus'
            labelCol={{ span: 6 }}
            labelAlign='left'
            initialValue={selectedCompany?.bonusCurrency?.toUpperCase()}
          >
            <Input style={{ width: 80 }} disabled />
          </Form.Item>
          {!user.data.role.includes('employee') && (
            <Form.Item label='Date of request creation' name='dateOfCreation' labelCol={{ span: 6 }} labelAlign='left'>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
            Submit
          </Button>
        </>
      );
    } else if (typeOfLeave === 'Overtime') {
      return (
        <>
          <Form.Item
            label='Date of overtime'
            name='dateOvertime'
            labelCol={{ span: 7 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >
            <DatePicker onChange={(val) => setDateOvertime(val.format('L'))} format={'DD-MM-YYYY'} />
          </Form.Item>

          {(user.data.role.includes('admin') || user.data.role.includes('SuperAdmin')) && (
            <div>
              <Form.Item
                label='Basis for overtime - Serbian'
                name='basisOvertime'
                labelCol={{ span: 7 }}
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please enter basis for overtime in Serbian!',
                  },
                ]}
              >
                <Input style={{ width: 300 }} onChange={(e) => setBasisOvertime(e.target.value)} />
              </Form.Item>
              <Form.Item
                label='Basis for overtime - English'
                name='basisOvertimeEng'
                labelCol={{ span: 7 }}
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please enter basis for overtime in English!',
                  },
                ]}
              >
                <Input style={{ width: 300 }} onChange={(e) => setBasisOvertimeEng(e.target.value)} />
              </Form.Item>
            </div>
          )}
          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user?.data?.company?.typeOfHr === 'bilingual' && (
              <div>
                <Form.Item
                  label='Basis for overtime - Serbian'
                  name='basisOvertime'
                  labelCol={{ span: 7 }}
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter basis for overtime in Serbian!',
                    },
                  ]}
                >
                  <Input style={{ width: 300 }} onChange={(e) => setBasisOvertime(e.target.value)} />
                </Form.Item>
                <Form.Item
                  label='Basis for overtime - English'
                  name='basisOvertimeEng'
                  labelCol={{ span: 7 }}
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter basis for overtime in English!',
                    },
                  ]}
                >
                  <Input style={{ width: 300 }} onChange={(e) => setBasisOvertimeEng(e.target.value)} />
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('user') || user.data.role.includes('userOfficer')) &&
            user?.data?.company?.typeOfHr === 'serbian' && (
              <Form.Item
                label='Basis for overtime'
                name='basisOvertime'
                labelCol={{ span: 7 }}
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please enter basis for overtime!',
                  },
                ]}
              >
                <Input style={{ width: 300 }} onChange={(e) => setBasisOvertime(e.target.value)} />
              </Form.Item>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              (user.data.role.includes('hrOfficer') && data)) &&
            (user?.data?.employeeCompany?.typeOfHr === 'bilingual' ||
              companyTypeOfHR === 'bilingual' ||
              data.company.typeOfHr === 'bilingual') && (
              <div>
                <Form.Item
                  label='Basis for overtime - Serbian'
                  name='basisOvertime'
                  labelCol={{ span: 7 }}
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter basis for overtime in Serbian!',
                    },
                  ]}
                >
                  <Input style={{ width: 300 }} onChange={(e) => setBasisOvertime(e.target.value)} />
                </Form.Item>
                <Form.Item
                  label='Basis for overtime - English'
                  name='basisOvertimeEng'
                  labelCol={{ span: 7 }}
                  labelAlign='left'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter basis for overtime in English!',
                    },
                  ]}
                >
                  <Input style={{ width: 300 }} onChange={(e) => setBasisOvertimeEng(e.target.value)} />
                </Form.Item>
              </div>
            )}

          {!user.data.role.includes('admin') &&
            !user.data.role.includes('SuperAdmin') &&
            (user.data.role.includes('employee') ||
              (user.data.role.includes('hrOfficer') && user.data.role.includes('employee')) ||
              (user.data.role.includes('hrOfficer') && data)) &&
            (user?.data?.employeeCompany?.typeOfHr === 'serbian' ||
              companyTypeOfHR === 'serbian' ||
              data.company.typeOfHr === 'serbian') && (
              <Form.Item
                label='Basis for overtime'
                name='basisOvertime'
                labelCol={{ span: 7 }}
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please enter basis for overtime!',
                  },
                ]}
              >
                <Input style={{ width: 300 }} onChange={(e) => setBasisOvertime(e.target.value)} />
              </Form.Item>
            )}

          <Form.Item
            label='Number of hours worked overtime'
            name='numOfHoursOvertime'
            labelCol={{ span: 7 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter number of hours!',
              },
            ]}
          >
            <Input style={{ width: 80 }} type='number' onChange={(e) => setNumOfHoursOvertime(e.target.value)} />
          </Form.Item>
          <Form.Item
            label='Percentage of bonus for overtime'
            name='percentageBonusOvertime'
            labelCol={{ span: 7 }}
            labelAlign='left'
            required
          >
            <Input style={{ width: 80 }} type='number' disabled defaultValue={26} />
          </Form.Item>
          {!user.data.role.includes('employee') && (
            <Form.Item label='Date of request creation' name='dateOfCreation' labelCol={{ span: 7 }} labelAlign='left'>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(val) => setDateOfCreation(val)}
                  format={'DD-MM-YYYY'}
                />
                <Tooltip
                  title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                  color='#b1b1b1'
                  placement='bottom'
                  autoAdjustOverflow={true}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
                </Tooltip>
              </div>
            </Form.Item>
          )}
          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
            Submit
          </Button>
        </>
      );
    } else if (typeOfLeave === 'Suspension') {
      return (
        <>
          <Form.Item
            label='Month to suspend payment'
            name='bonusMonth'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                validator: handleValidateMonthPicker,
              },
            ]}
          >
            <MonthPicker onChange={(val) => handleBonusMonthPicker(val)} format={'MM-YYYY'} />
            <Tooltip
              title='Pick the month for which you want to generate a payment suspension.'
              color='#b1b1b1'
              placement='bottom'
              autoAdjustOverflow={true}
            >
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
            </Tooltip>
          </Form.Item>

          {/* Type of leave reason*/}
          <Form.Item
            label='Reason of payment suspension - Serbian'
            name='reasonOfLeave'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder='Molimo, unesite razlog obustava isplate'
              onChange={(e) => setReasonOfLeave(e.target.value)}
              rules={[
                {
                  required: true,
                  message: 'Please enter reason of payment suspension in Serbian!',
                },
              ]}
            />
          </Form.Item>
          {companyTypeOfHR === 'bilingual' ? (
            <Form.Item
              label='Reason of payment suspension - English'
              name='reasonOfLeaveEng'
              labelCol={{ span: 6 }}
              labelAlign='left'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                onChange={(e) => setReasonOfLeaveEng(e.target.value)}
                placeholder='Please, specify the reason of payment suspension'
                rules={[
                  {
                    required: true,
                    message: 'Please enter reason of payment suspension in English!',
                  },
                ]}
              />
            </Form.Item>
          ) : null}
          <Form.Item
            label='Suspension Amount'
            name='valueBonus'
            labelCol={{ span: 6 }}
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter the suspension value amount!',
              },
            ]}
          >
            <Input style={{ width: 80 }} onChange={(e) => setValueBonus(e.target.value)} />
          </Form.Item>
          <Form.Item
            label='Currency'
            name='currencyBonus'
            labelCol={{ span: 6 }}
            labelAlign='left'
            initialValue={selectedCompany?.suspensionCurrency?.toUpperCase()}
          >
            <Input style={{ width: 80 }} disabled />
          </Form.Item>
          <Form.Item label='Date of request creation' name='dateOfCreation' labelCol={{ span: 6 }} labelAlign='left'>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <DatePicker
                disabledDate={disabledDate}
                onChange={(val) => setDateOfCreation(val)}
                format={'DD-MM-YYYY'}
              />
              <Tooltip
                title='Select a date to manually set the request acceptance date. Otherwise, todays date will be the request acceptance date.'
                color='#b1b1b1'
                placement='bottom'
                autoAdjustOverflow={true}
              >
                <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '5px' }} />
              </Tooltip>
            </div>
          </Form.Item>

          <Button type='primary' htmlType='submit' style={{ float: 'right', marginTop: '10px' }}>
            Submit
          </Button>
        </>
      );
    }
  };

  const checkUserRoles = (checkRole, userRoles) => {
    const ALLOWED_ROLES = ['SuperAdmin', 'admin', 'user', 'userOfficer', 'hrOfficer'];
    return checkRole ? ALLOWED_ROLES.some((key) => userRoles.includes(key)) : true;
  };

  const TYPE_LEAVE_OPTIONS = [
    { key: '1', value: 'Annual leave', label: 'Annual leave - Godišnji odmor', checkRole: false },
    { key: '2', value: 'Bonus', label: 'Bonus', checkRole: true },
    { key: '3', value: 'Slava - Saint day', label: 'Saint day - Slava', checkRole: false },
    { key: '4', value: 'Paid days off', label: 'Paid days off - Plaćeno odsustvo', checkRole: false },
    { key: '5', value: 'Unpaid days off', label: 'Unpaid days off - Neplaćeno odsustvo', checkRole: false },
    { key: '6', value: 'Maternity leave', label: 'Maternity leave - Porodiljsko odsustvo', checkRole: false },
    { key: '7', value: 'Suspension', label: 'Suspension - Obustava', checkRole: true },
    { key: '8', value: 'Official travel', label: 'Official travel - Službeni put', checkRole: false },
  ];

  return (
    <>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className='form-horizontal dataForm'
        layout='horizontal'
        form={form}
        labelAlign='left'
      >
        <Form.Item label='Type of leave' labelCol={{ span: 2 }}>
          <Select
            onChange={(value) => setTypeOfLeave(value)}
            style={{ marginLeft: '15px', border: '1px solid #2db1ab', width: '100%', maxWidth: '350px' }}
          >
            {TYPE_LEAVE_OPTIONS.map(({ key, value, label, checkRole }) => {
              if (!checkUserRoles(checkRole, user.data.role)) return null;
              return (
                <Option key={key} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {user && user.data && data && displayForm(data, user)}

        {selectedCompany &&
          selectedCompany.holidayDaysTracker &&
          data &&
          data?.numberOfHolidayDaysPast >= 0 &&
          data?.numberOfHolidayDaysCurrent >= 0 &&
          data?.numberOfHolidayDaysContract >= 0 &&
          data?.numberOfHolidayDays >= 0 &&
          typeOfLeave === 'Annual leave' && (
            <div>
              <Divider style={{ backgroundColor: '#2db1ab50', margin: '20px 0' }} />
              <Form.Item
                label='Holiday days left from past year'
                labelCol={{ span: 8 }}
                name='numberOfHolidayDaysPast'
                style={{ display: form.getFieldValue('numberOfHolidayDaysPast') ? 'block' : 'none' }}
              >
                <Input type='number' style={{ width: '8%' }} disabled />
              </Form.Item>
              <Form.Item
                label={data.endDate ? 'Holiday days used from current contract' : 'Holiday days used from current year'}
                labelCol={{ span: 8 }}
                name='numberOfHolidayDaysUsed'
                style={{ display: form.getFieldValue('numberOfHolidayDaysUsed') ? 'block' : 'none' }}
              >
                <Input type='number' style={{ width: '8%' }} disabled />
              </Form.Item>
              <Form.Item
                label='Holiday days defined in contract'
                labelCol={{ span: 8 }}
                name='numberOfHolidayDaysContract'
                style={{ display: form.getFieldValue('numberOfHolidayDaysContract') ? 'block' : 'none' }}
              >
                <Input type='number' style={{ width: '8%' }} disabled />
              </Form.Item>

              <Form.Item
                label='Total holiday days left up to year end'
                labelCol={{ span: 8 }}
                name='numberOfHolidayDays'
              >
                <Input type='number' style={{ width: '8%' }} disabled />
              </Form.Item>

              {isEndDateThisYear && (
                <Form.Item
                  label='Total days left by limited contract'
                  labelCol={{ span: 8 }}
                  name='numberOfHolidayDaysByEndOfContract'
                  style={{ display: form.getFieldValue('numberOfHolidayDaysByEndOfContract') ? 'block' : 'none' }}
                >
                  <Input type='number' style={{ width: '8%' }} disabled />
                </Form.Item>
              )}
            </div>
          )}
      </Form>

      {typeOfLeave === 'Official travel' && (
        <OfficialTravel
          userFullName={userFullName}
          typeOfLeave={typeOfLeave}
          companyId={companyId}
          employeeId={employeeId}
          token={user.data.token}
          handleChangeTab={handleChangeTab}
          isEmployeeUser={user.data.role.length === 1 && user.data.role[0] === 'employee'}
          handleRefetchData={handleRefetchData}
        />
      )}
    </>
  );
};

export default HrForm;
