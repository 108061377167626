import React, { useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import { Link } from 'react-router-dom';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const ResetPassword = () => {
  const [password, setPassword] = useState('');

  const onFinish = async () => {
    const urlParts = window.location.href.split('/');
    const token = urlParts[urlParts.length - 1];
    try {
      const resetPassword = await Axios.post(
        `${SERVER_URL}/password-reset/${token}`,
        { password },
        { withCredentials: false },
      );
      if (resetPassword.status === 200) {
        notification.success({
          message: 'Password was successfully reset.',
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
      });
    }
  };
  return (
    <div className='log reset-pass'>
      <div className='card-wrapper'>
        <img style={{ marginBottom: '20px' }} className='login-logo' src='/logoHLB.png' alt='HLB Logo' />
        <Card className='login-header' bordered={false} style={{ border: 'none' }}>
          <Card title='Enter new password' />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='password' rules={[{ required: true, message: 'Please enter new password!' }]}>
              <Input.Password onChange={(e) => setPassword(e.target.value)} placeholder='New password' type='password' />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ width: '100%' }} htmlType='submit'>
                Send
              </Button>
            </Form.Item>
            <Form.Item {...tailLayout} style={{ paddingTop: '30px' }}>
              <Link to='/login' className='link-forgot-password'>
                Login
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ResetPassword;
